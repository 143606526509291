import { showErrorMsg } from "./alertMsgs";
import { createTheme } from "@mui/material/styles";
const theme = createTheme({});

export const isMobileScreen = (Query) => {
  try {
    return Query(theme.breakpoints.down("md"));
  } catch (error) {
    return error.message;
  }
};
//Capitalizes the first letter in each word
export const capitalized = (bookName) => {
  try {
    return String(bookName)
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  } catch (error) {
    showErrorMsg(error);
  }
};

// Converts all characters to toUpperCase
export const toUpper = (bookName) => {
  try {
    return String(bookName).toUpperCase();
  } catch (error) {
    showErrorMsg(error);
  }
};

//Days to expiry of subscription// Used at School.js
export const daysToExpiry = (date = new Date(), expiryDate) => {
  let difference = date.getTime() - new Date(expiryDate).getTime();
  let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return TotalDays;
};

// The back button function
// export const goBack = (navigate) => {
//   try {
//     const navigator = navigate(-1);
//     return navigator;
//   } catch (error) {
//     return error.message;
//   }
// };
