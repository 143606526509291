import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import Axios from "axios";
import { Button } from "@mui/material";
import { showErrorMsg, showSuccessMsg } from "../methods/alertMsgs";
import { TailSpin } from "react-loader-spinner";
import IssueStudent from "./crud/IssueStudent";
import { AddStudent } from "./crud/AddStudent";
import { MoveStudents } from "./MoveStudents";
import Graduate from "./Graduate";
import { baseURL } from "../methods/baseUrl";
import ReactPaginate from "react-paginate";
import { capitalized } from "../methods/eventHandlers";
import {
  BiCheckCircle,
  BiXCircle,
  BiPencil,
  BiDownload,
  BiUpload,
} from "react-icons/bi";

const Students = ({
  availableBooks,
  setAvailableBooks,
  allStudents,
  setAllBooks,
  setAllStudents,
  allBooks,
}) => {
  /**
   * This component generates details defined by the particular methods
   */
  const [clickedClass, setClickedClass] = useState(null);

  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  const { auth } = useAuth();
  const school = auth?.school_id;
  const mySchool = school;
  const sch_category = auth?.sch_category;
  const [streams, setStreams] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [showKlasses, setShowKlasses] = useState(false);
  const [loading, setLoading] = useState(false);
  const [theKlass, setStudentsKlass] = useState([]);

  const [editStudentData, setEditStudentData] = useState(null); //Holds thes students edit data

  const editMyStudent = (student) => {
    setEditStudentData(student);
  };

  // Set students
  const [klasses, setKlasses] = useState([]);
  const navigate = useNavigate();
  const [searchStudents, setSearchStudents] = useState([]);
  const [subjects, setSubjects] = useState([]);

  // Set student admission number
  const [sKey, setKey] = useState(null);
  const [foundStudent, setFoundStudent] = useState(
    "Search Results will appear here"
  );

  const handleSort = (column) => {
    if (sortColumn === column) {
      // If the same column is clicked again, reverse the sort direction
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // If a different column is clicked, set it as the new sort column in ascending order
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const getAvailableBooks = useCallback(async () => {
    try {
      const response = await Axios.get(
        baseURL + `/available/books/${mySchool}`
      );

      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setAvailableBooks(response.data);
      }
    } catch (error) {
      showErrorMsg(error);
    }
  }, [mySchool, setAvailableBooks]);

  const handleCheckboxChange = () => {
    setEditStudentData((editStudentData) => ({
      ...editStudentData,
      prefect: !editStudentData.prefect,
    }));
  };

  const editStudent = async (id) => {
    try {
      const name = editStudentData.name;
      const adm = editStudentData.student_id;
      const myKlass = editStudentData.klass_id;
      const stream = editStudentData.stream_id;
      const prefect = editStudentData.prefect;
      const body = { name, adm, myKlass, stream, prefect };
      await Axios.put(baseURL + `/update/student/` + id, body).then(
        (response) => {
          if (response.data.msg) {
            showErrorMsg(response.data.msg);
          } else {
            showSuccessMsg(response.data.success);
            getAllStudents();
            getStudentsPerKlass(editStudentData.klass_id, school);
            setEditStudentData(null);
          }
        }
      );
    } catch (error) {
      showErrorMsg(error);
      // Handle the error
    }
  };

  const [currentPage, setCurrentPage] = useState(0);

  const handleClassClick = (klass) => {
    setClickedClass(klass.id);
    setStudentsKlass(klass);
    getStudentsPerKlass(klass.id, school);
    setShowSearch(false);
    setShowKlasses(true);
    setLoading(true);
    setKey(null);
  };

  const handlePageClick = (data) => {
    const { selected } = data;
    setCurrentPage(selected);
  };

  const offset = currentPage * 20;
  const currentPageData = searchStudents.slice(offset, offset + 20);

  // Filter students belonging to the class
  const getStudentsPerKlass = useCallback(async (id, sch) => {
    await Axios.get(baseURL + `/students/${id}/${sch}`)
      .then((response) => {
        if (response.data.msg) {
          setLoading(false);
          showErrorMsg(response.data.msg);
        } else {
          if (response.data.length === 0) {
            setLoading(false);
            setFoundStudent("No students available");
            setSearchStudents([]);
          } else {
            // setShowKlasses(true);
            setSearchStudents(response.data);
            // setTimeout(() => setLoading(false), 100);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        showErrorMsg(error);
      });
  }, []);

  const getAllStudents = useCallback(async () => {
    // alert("Getting all students from Students.js");

    /**
     * Get all the details of all students in a school
     * This is to be stored and used everytime an issue is required
     */
    try {
      await Axios.get(baseURL + "/students/" + mySchool).then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setAllStudents(response.data);
          // setAllStudentsCount(response.data.length);
        }
      });
    } catch (error) {
      showErrorMsg(error);
    }
  }, [mySchool, setAllStudents]);

  // Get streams
  const getStreams = async (klassId) => {
    try {
      await Axios.get(baseURL + `/streams/${klassId}/${school}`).then(
        (response) => {
          if (response.data.msg) {
            showErrorMsg(response.data.msg);
          } else {
            setStreams(response.data);
          }
        }
      );
    } catch (err) {
      showErrorMsg(err.message);
    }
  };

  // Get klasses
  const getKlasses = useCallback(async () => {
    try {
      await Axios.get(baseURL + `/klasses/${sch_category}`).then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setKlasses(response.data);
        }
      });
    } catch (error) {
      showErrorMsg(error);
    }
  }, [sch_category]);

  const downloadStudent = async (klass) => {
    try {
      const response = await Axios.get(
        baseURL + `/students/download/${klass}/${school}`,
        {
          responseType: "blob",
        }
      );

      if (response.msg) {
        showErrorMsg(response.data.msg);
      } else {
        const downloadUrl = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `${theKlass.name} students.xlsx`);
        // link.download = "borrowed_books.xlsx";
        link.click();
        URL.revokeObjectURL(downloadUrl);
      }
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  // Search student function
  const studentSearchKey = useRef();

  const searchStudent = async () => {
    try {
      const theKey = studentSearchKey.current.value;
      setKey(theKey);

      if (theKey) {
        await Axios.get(
          baseURL + `/students/search/${school}/${encodeURIComponent(theKey)}`
        ).then((response) => {
          if (response.data.msg) {
            showErrorMsg(response.data.msg);
          } else {
            if (response.data.length < 1) {
              setFoundStudent("No results found for " + theKey);
              setSearchStudents([]);
            } else {
              setSearchStudents(response.data);
              setFoundStudent([]);
              setLoading(false);
            }
          }
        });
      } else {
        setSearchStudents([]);
        setFoundStudent("Search Results will appear here");
      }
    } catch (error) {
      showErrorMsg(error);
    }
  };

  useEffect(() => {
    if (availableBooks.length === 0) {
      console.log("No books, regenerating");
      getAvailableBooks();
    }
  }, [availableBooks.length, getAvailableBooks]);

  const getSubjects = useCallback(async () => {
    /**
     * Generate only the subjects with no_of_books > 0
     * Called when in need to issue a book
     * where the subject is first selected before
     * the books that HAVE NOT BEEN ISSUED are populated
     */
    await Axios.get(
      baseURL + `/subjects/with/books/more/${mySchool}/${sch_category}`
    ).then((response) => {
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setSubjects(response.data);
      }
    });
  }, [mySchool, sch_category]);
  // Delete a selected student

  // Runs every time the page is rendered i.e keeps the page refreshed
  useEffect(() => {
    setFoundStudent(foundStudent);
    document.title = "LLR | Students";
    getKlasses();
    getSubjects();
    if (allStudents.length < 1) {
      getAllStudents();
    } else {
      setAllStudents(allStudents);
    }
  }, [
    allStudents,
    foundStudent,
    getAllStudents,
    getKlasses,
    getSubjects,
    setAllStudents,
  ]);

  // The JSX
  return (
    <div className="container m-auto">
      {klasses.length > 0 ? (
        <div>
          <div className="text-center text-success">
            <h3 className="mt-3" style={{ marginBottom: "0rem" }}>
              {" "}
              {/* Place the first <h3> on a new line and center it */}
              STUDENTS -{" "}
              <small className="text-success">{allStudents.length}</small>
            </h3>
            <hr />
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Button
                title="Move back one step."
                variant="text"
                className="float-left"
                onClick={() => navigate(-1)} // Go back one step
              >
                Back
              </Button>
              <div id="selectables">
                <Link to={{ pathname: "uploads" }}>
                  <Button
                    title="Upload students"
                    className="ml-2"
                    variant="text"
                  >
                    <BiUpload size={18} />
                  </Button>
                </Link>
                <Link to={{ pathname: "issues/uploads" }}>
                  <Button
                    title="Upload Book Issues"
                    className="ml-2"
                    variant="text"
                  >
                    <BiUpload size={18} />
                  </Button>
                </Link>
                {auth?.is_superuser && (
                  <Button
                    title="Move students to higher classes"
                    className="ml-2"
                    variant="text"
                    data-toggle="modal"
                    data-target="#move"
                  >
                    Move
                  </Button>
                )}
                <MoveStudents getAllStudents={getAllStudents} />
                <Button
                  title="Add new student"
                  className="ml-2"
                  data-toggle="modal"
                  data-target="#add"
                  variant="text"
                >
                  Add
                </Button>
                <AddStudent getAllStudents={getAllStudents} />
              </div>
            </div>
            {/* <hr /> */}
          </div>

          <div className="d-flex">
            {klasses.slice(0, 6).map((klass, index) => {
              if (klass.name === "All Forms") {
                return null;
              }
              if (klass.name === "F1&2") {
                return null;
              }
              if (klass.name === "F3&4") {
                return null;
              }
              return (
                <div
                  key={klass.id}
                  className={`flex-grow-1 ${index !== 0 ? "ml-2" : ""} ${
                    clickedClass === klass.id ? "clicked" : ""
                  }`}
                  onClick={() => handleClassClick(klass)}
                >
                  <div className="text-center">
                    <button
                      text
                      id="submit"
                      className="btn btn-success w-100"
                      style={{
                        color: clickedClass === klass.id ? "black" : "",
                        backgroundColor:
                          clickedClass === klass.id ? "white" : "",
                      }}
                    >
                      {klass.name}
                      {" - "}
                      {
                        allStudents.filter(
                          (students) => students.klass_id === klass.id
                        ).length
                      }
                    </button>
                  </div>
                </div>
              );
            })}

            <div className="flex-grow-1 ml-2">
              <div className="text-center">
                <Link
                  title="View graduated students"
                  to={"graduates"}
                  id="submit"
                  className="btn btn-success w-100"
                >
                  Graduated
                </Link>
              </div>
            </div>
          </div>

          <br />
          <form
            id="searchStudentForm"
            className="d-flex"
            onSubmit={(e) => {
              e.preventDefault();
              searchStudent();
              setShowSearch(true);
              setShowKlasses(false);
              setStudentsKlass(null);
              setLoading(true);
            }}
          >
            <input
              name="search"
              ref={studentSearchKey}
              className=" form-control col-9"
            />
            <br />
            <button id="submit" type="submit" className="btn btn-success col-3">
              Search
            </button>
          </form>
          {searchStudents?.length === 0 ? (
            <h6 className="text-center text-success m-3">{foundStudent}</h6>
          ) : (
            <div>
              <div className="m-2">
                {showSearch && (
                  <div>
                    {" "}
                    <h6>
                      Search Results for{" "}
                      <small className="text-danger">
                        <b>{sKey}</b>
                      </small>{" "}
                      - <b>{searchStudents.length}</b>
                    </h6>
                  </div>
                )}
                {showKlasses && (
                  <h6 className="text-center">
                    <h6 className="text-center">
                      {theKlass.name === "F4" ? (
                        <span>
                          {theKlass.name} - {searchStudents.length} students
                          <Link onClick={() => downloadStudent(theKlass.id)}>
                            <BiDownload
                              title={`Download ${theKlass.name} students`}
                              className="success link ml-3 text-success"
                              size={18}
                            />
                          </Link>
                          {auth?.is_superuser && (
                            <span>
                              <Link
                                title="Move the students to graduated class"
                                className=" link"
                                data-toggle="modal"
                                data-target="#graduate"
                              >
                                {" - "}Graduate
                              </Link>
                              <Graduate
                                klass_id={theKlass.id}
                                getAllStudents={getAllStudents}
                              />
                            </span>
                          )}
                        </span>
                      ) : (
                        <span>
                          {theKlass.name} - {searchStudents.length} students
                          {
                            <Link onClick={() => downloadStudent(theKlass.id)}>
                              <BiDownload
                                title={`Download ${theKlass.name} students`}
                                className="success link ml-3 text-success"
                                size={18}
                              />
                            </Link>
                          }
                        </span>
                      )}
                    </h6>
                  </h6>
                )}
              </div>

              <div>
                {loading ? (
                  <TailSpin
                    height="120"
                    width="120"
                    color="rgb(183, 71, 221)"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{
                      top: "50%",
                      left: "50%",
                      position: "absolute",
                      transform: "translate(-50%, -50%)",
                    }}
                    wrapperClass=""
                    visible={true}
                  />
                ) : (
                  <table className="layout">
                    <thead>
                      <tr>
                        <th onClick={() => handleSort("student_id")}>
                          Adm No
                          {sortColumn === "student_id" && (
                            <span>{sortDirection === "asc" ? " " : " "}</span>
                          )}
                        </th>
                        <th onClick={() => handleSort("name")}>
                          Name
                          {sortColumn === "name" && (
                            <span>{sortDirection === "asc" ? " " : " "}</span>
                          )}
                        </th>
                        <th onClick={() => handleSort("k")}>
                          Class
                          {sortColumn === "klass_id" && (
                            <span>{sortDirection === "asc" ? " " : " "}</span>
                          )}
                        </th>
                        <th onClick={() => handleSort("stream")}>
                          Stream
                          {sortColumn === "stream" && (
                            <span>{sortDirection === "asc" ? "" : ""}</span>
                          )}
                        </th>

                        {!editStudentData && (
                          <>
                            <th onClick={() => handleSort("books")}>
                              Books
                              {sortColumn === "books" && (
                                <span>
                                  {sortDirection === "asc" ? " " : " "}
                                </span>
                              )}
                            </th>
                            <th>Issue</th>
                          </>
                        )}
                        <th>Prefect</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {currentPageData
                        .slice()
                        .sort((a, b) => {
                          // Sorting logic based on sortColumn and sortDirection
                          if (sortColumn === "student_id") {
                            if (sortDirection === "asc") {
                              return a.student_id.localeCompare(b.student_id);
                            } else {
                              return b.student_id.localeCompare(a.student_id);
                            }
                          } else if (sortColumn === "name") {
                            if (sortDirection === "asc") {
                              return a.name.localeCompare(b.name);
                            } else {
                              return b.name.localeCompare(a.name);
                            }
                          } else if (sortColumn === "books") {
                            if (sortDirection === "asc") {
                              return a.books - b.books;
                            } else {
                              return b.books - a.books;
                            }
                          } else if (sortColumn === "stream") {
                            if (sortDirection === "asc") {
                              return (a.st_name || "").localeCompare(
                                b.st_name || ""
                              );
                            } else {
                              return (b.st_name || "").localeCompare(
                                a.st_name || ""
                              );
                            }
                          } else {
                            return 0; // Default: No sorting
                          }
                        })
                        .map((student, index) => (
                          <tr key={student.s_id}>
                            {editStudentData &&
                            editStudentData.s_id === student.s_id ? (
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={editStudentData.student_id}
                                  onChange={(e) =>
                                    setEditStudentData({
                                      ...editStudentData,
                                      student_id: e.target.value,
                                    })
                                  }
                                />
                              </td>
                            ) : (
                              <td>{student.student_id}</td>
                            )}

                            {editStudentData &&
                            editStudentData.s_id === student.s_id ? (
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={editStudentData.name}
                                  onChange={(e) =>
                                    setEditStudentData({
                                      ...editStudentData,
                                      name: e.target.value,
                                    })
                                  }
                                />
                              </td>
                            ) : (
                              <td
                                style={{
                                  color: student.prefect && "blue",
                                }}
                              >
                                {capitalized(student.name)}
                              </td>
                            )}

                            {editStudentData &&
                            editStudentData.s_id === student.s_id ? (
                              <td>
                                <select
                                  className="form-select"
                                  required
                                  onChange={(e) => {
                                    setEditStudentData({
                                      ...editStudentData,
                                      klass_id: e.target.value,
                                    });
                                    getStreams(e.target.value);
                                  }}
                                >
                                  {klasses.map((klass) => {
                                    if (klass.name === "All Forms") {
                                      return null;
                                    }
                                    if (klass.name === "F1&2") {
                                      return null;
                                    }
                                    if (klass.name === "F3&4") {
                                      return null;
                                    }

                                    return (
                                      <option
                                        key={klass.id}
                                        value={klass.id}
                                        selected={
                                          editStudentData.klass_id === klass.id
                                        }
                                      >
                                        {klass.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </td>
                            ) : student.graduated ? (
                              <td>
                                {student.year_graduated}
                                {""} Grad
                              </td>
                            ) : (
                              <td>{student.k_name}</td>
                            )}

                            {editStudentData &&
                            editStudentData.s_id === student.s_id ? (
                              <td>
                                <select
                                  className="form-select"
                                  value={editStudentData.stream_id || null}
                                  onChange={(e) =>
                                    setEditStudentData({
                                      ...editStudentData,
                                      stream_id:
                                        e.target.value === ""
                                          ? null
                                          : parseInt(e.target.value),
                                    })
                                  }
                                >
                                  <option value="">- - -</option>
                                  {streams.map((stream) => (
                                    <option
                                      key={stream.id}
                                      value={stream.id}
                                      selected={
                                        stream.id === editStudentData.stream_id
                                      }
                                    >
                                      {stream.name}
                                    </option>
                                  ))}
                                </select>
                              </td>
                            ) : (
                              <td>
                                {!student.graduated && (
                                  <>{capitalized(student.st_name || "")}</>
                                )}
                              </td>
                            )}

                            {!editStudentData && (
                              <>
                                <td id="borrowed" title="View more details.">
                                  <Link
                                    // onClick={() => setTheStudent(student)}
                                    className="link"
                                    to={{
                                      pathname: `borrowed/${student.s_id}`,
                                    }}
                                    state={{ student: student }}
                                  >
                                    {student.books}
                                  </Link>
                                </td>
                                <td>
                                  <IssueStudent
                                    availableBooks={availableBooks}
                                    setAvailableBooks={setAvailableBooks}
                                    student={student}
                                    theKlass={theKlass}
                                    subjects={subjects}
                                    sKey={sKey}
                                    searchedStudents={searchStudent}
                                    getStudentsPerKlass={getStudentsPerKlass}
                                  />
                                  <Link
                                    data-toggle="modal"
                                    data-target={`#id${student.s_id}`}
                                    // onClick={() => setTheStudent(student)}
                                    className="link"
                                  >
                                    Issue
                                  </Link>
                                </td>
                              </>
                            )}

                            {editStudentData &&
                            editStudentData.s_id === student.s_id ? (
                              <td style={{ textAlign: "center" }}>
                                <input
                                  type="checkbox"
                                  id="myCheckbox"
                                  className="form-check-input"
                                  checked={editStudentData.prefect}
                                  onChange={handleCheckboxChange}
                                  style={{ transform: "scale(1.4)" }}
                                />
                              </td>
                            ) : (
                              <td>
                                <input
                                  type="checkbox"
                                  disabled
                                  id="myCheckbox"
                                  checked={student?.prefect}
                                  style={{ transform: "scale(1.4)" }}
                                />
                              </td>
                            )}

                            <td>
                              {editStudentData &&
                              editStudentData.s_id === student.s_id ? (
                                <small>
                                  <Link
                                    className="link btn btn-primry btn-sm"
                                    onClick={() => {
                                      editStudent(editStudentData.s_id);
                                      setClickedClass(editStudentData.klass_id);
                                    }}
                                  >
                                    <BiCheckCircle /> Save
                                  </Link>
                                  <Link
                                    className="link btn btn-primry btn-sm"
                                    onClick={() => setEditStudentData(null)}
                                  >
                                    <BiXCircle /> Cancel
                                  </Link>
                                </small>
                              ) : (
                                <Link
                                  className=" link "
                                  title={`Edit ADM-${student.student_id} details`}
                                  onClick={() => {
                                    editMyStudent(student);
                                    getStreams(student.klass_id);
                                  }}
                                >
                                  <BiPencil />
                                </Link>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          )}
          <br />
          {searchStudents.length > 20 && (
            <div className="pagination-container">
              <ReactPaginate
                pageCount={Math.ceil(searchStudents.length / 20)}
                onPageChange={handlePageClick}
                containerClassName={"pagination "}
                activeClassName={"activated"}
              />
            </div>
          )}
        </div>
      ) : (
        <TailSpin
          height="60"
          width="60"
          color="rgb(183, 71, 221)" //"#4fa94d"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            top: "50%",
            left: "50%",
            position: "absolute",
            transform: "translate(-50%, -50%)",
          }}
          wrapperClass=""
          visible={true}
        />
      )}
    </div>
  );
};
export default Students;
