// src/index.js
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
// import "./App.css";
import { baseURL } from "./methods/baseUrl";
import axios from "axios";

import App from "./App";
import { AuthProvider } from "./components/context/AuthProvider";
import { firebaseConfig } from "./methods/firebaseConfig";
import Metadata from "./Metadata";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

firebase.initializeApp(firebaseConfig);

const getMetadata = async () => {
  try {
    const response = await axios.get(baseURL + "/metadata");
    return response;
  } catch (error) {
    console.error("Error fetching metadata", error);
    return {};
  }
};

const Root = () => {
  const [metadata, setMetadata] = useState({});

  useEffect(() => {
    getMetadata().then((data) => {
      setMetadata(data);
    });
  }, []);

  return (
    <React.StrictMode>
      <BrowserRouter>
        <AuthProvider>
          <Metadata {...metadata} />
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(<Root />);

// import React from "react";
// import "@fontsource/roboto/300.css";
// import "@fontsource/roboto/400.css";
// import "@fontsource/roboto/500.css";
// import "@fontsource/roboto/700.css";
// import ReactDOM from "react-dom/client";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./index.css";
// import "./App.css"
// import App from "./App";
// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
// import { firebaseConfig } from "./methods/firebaseConfig";

// import { AuthProvider } from "./components/context/AuthProvider";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// firebase.initializeApp(firebaseConfig);
// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <AuthProvider>
//         <Routes>
//           <Route path="/*" element={<App />} />
//         </Routes>
//       </AuthProvider>
//     </BrowserRouter>
//   </React.StrictMode>
// );
