import Toolbar from "@mui/material/Toolbar";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
import React, { Fragment, useEffect, useState, useRef } from "react";
import useAuth from "../hooks/useAuth";
import Select, { createFilter } from "react-select";
import Axios from "axios";
import { baseURL } from "../../methods/baseUrl";
import { capitalized } from "../../methods/eventHandlers";

const IssueTeacher = ({
  teacher,
  getTeachers,
  availableBooks,
  getAvailableBooks,
}) => {
  const { auth } = useAuth();
  const [isValid, setIsValid] = useState(false);
  const [bookOptions, setBookOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const issueDateRef = useRef();
  const [myDate, setMyDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [triggerInput, setTriggerInput] = useState(false);
  const handleBookChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const formattedDate = () => {
    const today = new Date();
    const now = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(today.getDate()).padStart(2, "0")}T${String(
      today.getHours()
    ).padStart(2, "0")}:${String(today.getMinutes()).padStart(2, "0")}:${String(
      today.getSeconds()
    ).padStart(2, "0")}`;
    setMyDate(now);
  };

  const issueTeacher = async () => {
    try {
      const teacher_id = teacher.id;
      const issuer = auth?.id;
      const date = issueDateRef.current.value;

      for (let i = 0; i < selectedOption.length; i++) {
        const book = selectedOption[i].value;
        const body = { teacher_id, issuer, date, book };

        await Axios.post(baseURL + "/issue/teacher", body)
          .then((data) => {
            if (data.data.success) {
              showSuccessMsg(data.data.success);
              if (getTeachers) {
                getTeachers();
              }

              setSelectedOption([]); // Sets the selection area to none after each issue
              //getSubjectBooks(subject); // Repopulates the books removing the already issued from the list.
            } else {
              showErrorMsg(data.data.msg);
            }
          })
          .catch((error) => {
            showErrorMsg(error);
          });
      }
    } catch (error) {
      showErrorMsg(error);
    } finally {
      // setBookOptions([]);
      // setSelectedOption([]);
      getAvailableBooks();
    }
  };

  useEffect(() => {
    setIsValid(selectedOption.length > 0 ? true : false);
    formattedDate();
  }, [selectedOption]);

  // useEffect(() => {
  //   if (searchQuery && searchQuery.length > 1) {
  //     const theBooks = availableBooks?.map((book) => ({
  //       label: capitalized(book.book_name) + " - " + book.reg_no,
  //       value: book.id,
  //     }));

  //     const filteredBooks = theBooks?.filter((book) =>
  //       book.label.toLowerCase().includes(searchQuery.toLowerCase())
  //     );
  //     setBookOptions(filteredBooks);
  //   }
  // }, [availableBooks, searchQuery]);

  useEffect(() => {
    const theBooks = availableBooks?.map((book) => ({
      label: capitalized(book.book_name) + " - " + book.reg_no,
      value: book.id,
    }));

    // const filteredBooks = theBooks?.filter((book) =>
    //   book.label.toLowerCase().includes(searchQuery.toLowerCase())
    // );
    setBookOptions(theBooks);
  }, [availableBooks]);

  // JSX
  return (
    <Fragment>
      <div className="modal" id={`issueModal${teacher?.code}`}>
        <Toolbar />
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-success text-center m-3">
                {teacher?.code} - {teacher?.name}
              </h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div className="modal-body">
              {triggerInput && (
                <h6 className="mb-2 mt-2">
                  Results for {searchQuery} : <>{bookOptions.length}</>{" "}
                </h6>
              )}
              {/* <br /> */}
              <Select
                id="mySelect"
                closeMenuOnSelect={true} //Not including this option means true by default
                placeholder={availableBooks?.length + " books available"}
                noOptionsMessage={() => "Type on the cursor"}
                filter={createFilter({
                  ignoreAccents: false,
                })}
                isMulti
                options={bookOptions}
                value={selectedOption}
                onChange={handleBookChange}
                onInputChange={(inputValue) => {
                  setSearchQuery(inputValue);
                  setTriggerInput(true);
                }}
              />
              <br />
              <div className="d-flex">
                <label className="col-12" htmlFor="issueDate">
                  {" "}
                  Issue Date
                  <input
                    name="issueDate"
                    required
                    ref={issueDateRef}
                    defaultValue={myDate}
                    type="datetime-local"
                    className="form-control"
                  />
                  <br />
                </label>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger mr-auto"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <input
                onClick={() => issueTeacher()}
                data-dismiss="modal"
                type={"submit"}
                id="submit"
                value={"Issue"}
                disabled={!isValid}
                className="text-white btn btn-outline-success ml-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default IssueTeacher;
