import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import logo from "../partials/logo/logo-colored.gif";
import { TextField, Button, Box, Typography } from "@mui/material";

const PasswordReset = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [showMsg, setShowMsg] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleReset = async (e) => {
    e.preventDefault();

    setLoading(true);

    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      setShowMsg("Success! Check your email inbox.");
    } catch (error) {
      switch (error.code) {
        case "auth/user-not-found":
          setShowMsg("User not found. Please check the email address.");
          break;
        case "auth/invalid-email":
          setShowMsg("Invalid email. Please provide a valid email.");
          break;
        case "auth/user-disabled":
          setShowMsg("This user account has been disabled.");
          break;
        case "auth/requires-recent-login":
          setShowMsg("Please login again and retry");
          break;
        case "auth/network-request-failed":
          setShowMsg("Poor/No Internet Connection");
          break;
        case "auth/too-many-requests":
          setShowMsg("Please wait before retrying");
          break;
        default:
          setShowMsg(`Error: ${error.message}`);
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "LLR || Reset Password";

    if (showMsg) {
      const timeout = setTimeout(() => {
        setShowMsg(null);
      }, 10000);

      return () => clearTimeout(timeout);
    }
  }, [showMsg]);
  return (
    <div className="container mt-5">
      <div className="row justify-content-center  ">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body m-4" style={{ fontFamily: "Chakra" }}>
              <div className="d-flex justify-content-center mb-4">
                {" "}
                {/* Added Flexbox styles */}
                <img height={"75px"} width={"90px"} src={logo} alt="logo" />
              </div>
              <h2 className="card-title mb-4 text-center text-success ">
                Password Reset
              </h2>
              <hr />
              {showMsg && (
                <div
                  className={`alert ${
                    showMsg.includes("Success")
                      ? "alert-success"
                      : "alert-danger"
                  } mt-3 text-center`}
                  role="alert"
                >
                  {showMsg}
                </div>
              )}
              <form onSubmit={handleReset}>
                {/* <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    placeholder="Enter your email"
                  />
                </div> */}

                <TextField
                  fullWidth
                  type="email"
                  label="Email Address"
                  placeholder="Enter Email Address"
                  variant="standard"
                  margin="normal"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  InputLabelProps={{
                    style: { fontWeight: "bold" },
                    shrink: true,
                  }}
                />

                <br />
                <button
                  type="submit"
                  id="submit"
                  disabled={!email || loading}
                  className="btn btn-primary form-control mt-4"
                >
                  Reset Password
                </button>
              </form>

              <i>
                <Button
                  className="mt-3"
                  variant="text"
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
