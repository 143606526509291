import React, { useEffect, useState } from "react";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
import { baseURL } from "../../methods/baseUrl";
import axios from "axios";
import { Link } from "react-router-dom";

const Meta = () => {
  const [meta, setMeta] = useState(null);
  const [data, setData] = useState(null);
  const [errors, setErrors] = useState({});

  const validateInput = () => {
    const errors = {};

    if (!data.title) {
      errors.title = "Title is required";
    }

    if (!data.description) {
      errors.description = "Description is required";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const getMeta = async () => {
    try {
      const response = await axios.get(baseURL + "/metadata");
      setMeta(response.data);
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  const updateMeta = async (id) => {
    if (!validateInput()) {
      return;
    }
    //Comment
    const title = data.title;
    const description = data.description;
    const body = { title, description };

    try {
      const response = await axios.put(
        baseURL + `/update/metadata/${id}`,
        body
      );
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        showSuccessMsg(response.data.success);
      }
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  const setMyData = (data) => {
    setData(data);
    setErrors({}); // Clear previous errors when data changes
  };

  useEffect(() => {
    getMeta();
  }, []);

  // JSX
  return (
    <div className="container">
      <h6 className="text-center text-success m-4">METADATA</h6>
      <table className="layout">
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Option</th>
          </tr>
        </thead>
        <tbody>
          {meta &&
            meta.map((metaItem) => (
              <tr key={metaItem.id}>
                <td>
                  {data && data.id === metaItem.id ? (
                    <div>
                      <input
                        type="text"
                        value={data.title}
                        className="form-control"
                        onChange={(e) =>
                          setData({
                            ...data,
                            title: e.target.value,
                          })
                        }
                      />
                      {errors.title && (
                        <div className="error">{errors.title}</div>
                      )}
                    </div>
                  ) : (
                    metaItem.title
                  )}
                </td>
                <td>
                  {data && data.id === metaItem.id ? (
                    <div>
                      <input
                        type="text"
                        value={data.description}
                        className="form-control"
                        onChange={(e) =>
                          setData({
                            ...data,
                            description: e.target.value,
                          })
                        }
                      />
                      {errors.description && (
                        <div className="error">{errors.description}</div>
                      )}
                    </div>
                  ) : (
                    metaItem.description
                  )}
                </td>
                <td>
                  <Link
                    onClick={() => {
                      setMyData(metaItem);
                      updateMeta(metaItem.id);
                    }}
                    className="link"
                  >
                    Edit
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Meta;

// import React, { useEffect, useState } from "react";
// import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
// import baseURL from "../../methods/baseUrl";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import { BiPencil } from "react-icons/bi";

// const Meta = () => {
//   const [meta, setMeta] = useState(null);
//   const [data, setData] = useState(null);

//   const getMeta = () => {
//     /**
//      * Retrieve the metadata
//      */
//     try {
//       const metaData = axios.get(baseURL + "/metadata");
//       if (metaData.data.msg) {
//         showErrorMsg(metaData.data.msg);
//       } else {
//         setMeta(metaData.data);
//       }
//     } catch (error) {
//       showErrorMsg(error.message);
//     }
//   };

//   const updateMeta = async (id) => {
//     const title = data.title;
//     const description = data.description;
//     const body = { title, description };
//     await axios
//       .put(baseURL + `/update/metadata/${id}`, body)
//       .then((response) => {
//         if (response.data.msg) {
//           showErrorMsg(response.data.msg);
//         } else {
//           showSuccessMsg(response.data.success);
//         }
//       })
//       .catch((error) => {
//         showErrorMsg(error.message);
//       });
//   };

//   const setMyData = (data) => {
//     setData(data);
//   };

//   useEffect(() => {
//     getMeta();
//   }, []);

//   //JSX
//   return (
//     <div className="container">
//       <h5>METADATA</h5>
//       <table className="layout">
//         <thead>
//           <th>Title</th>
//           <th>Description</th>
//           <th>Option</th>
//         </thead>
//         <tbody>
//           {meta.map((meta) => {
//             return (
//               <tr key={meta.id}>
//                 {data && data.id === meta.id ? (
//                   <input
//                     type="text"
//                     value={data.title}
//                     className="form-control"
//                     onChange={(e) =>
//                       setData({
//                         ...data,
//                         title: e.target.value,
//                       })
//                     }
//                   />
//                 ) : (
//                   <td>{meta.title}</td>
//                 )}
//                 {data && data.id === meta.id ? (
//                   <input
//                     type="text"
//                     className="form-control"
//                     value={data.description}
//                     onChange={(e) =>
//                       setData({
//                         ...data,
//                         description: e.target.value,
//                       })
//                     }
//                   />
//                 ) : (
//                   <td>{meta.description}</td>
//                 )}
//                 <td>
//                   <Link
//                     onClick={() => {
//                       setMyData(meta);
//                       updateMeta(meta.id);
//                     }}
//                     className="link"
//                   >
//                     <BiPencil size={18} />
//                   </Link>
//                 </td>
//               </tr>
//             );
//           })}
//         </tbody>
//       </table>
//     </div>
//   );
// };
// export default Meta;
