import Axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { baseURL } from "../../methods/baseUrl";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
import { Button } from "@mui/material";
import { BiXCircle, BiPencil, BiCheckCircle } from "react-icons/bi";
import AddStream from "../crud/AddStream";

const SchoolsDetails = () => {
  const { id } = useParams();
  const [school, setSchool] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [streams, setStreams] = useState([]);
  const [viewStreams, setViewStreams] = useState(false);
  const [viewTeachers, setViewTeachers] = useState(false);
  const [streamData, setStreamData] = useState(null);
  const navigate = useNavigate();

  const getSchool = useCallback(async () => {
    try {
      await Axios.get(baseURL + `/school/${id}`).then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setSchool(response.data);
        }
      });
    } catch (error) {
      showErrorMsg(error.message);
    }
  }, [id]);

  const getStreams = useCallback(async () => {
    try {
      await Axios.get(baseURL + `/streams/${id}`).then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setStreams(response.data);
        }
      });
    } catch (error) {
      showErrorMsg(error.message);
    }
  }, [id]);

  const getTeachers = useCallback(async () => {
    /**
     * Extract all teachers details per school
     */
    try {
      await Axios.get(baseURL + `/teachers/${id}`).then((response) => {
        if (response?.data?.results) {
          setTeachers(response.data.results);
        } else {
          showErrorMsg(response.data.msg);
        }
      });
    } catch (error) {
      showErrorMsg(error);
    }
  }, [id]);

  const setMyStreamData = (data) => {
    setStreamData(data);
  };

  const updateStream = async (id) => {
    try {
      const name = streamData.stream;
      const body = { name };
      await Axios.put(baseURL + `/update/stream/${id}`, body).then(
        (response) => {
          if (response.data.msg) {
            showErrorMsg(response.data.msg);
          } else {
            showSuccessMsg(response.data.success);
            getStreams();
            setStreamData(null);
          }
        }
      );
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  const uniqueKlasses = [...new Set(streams.map((stream) => stream.klass))];

  const streamsCounts = {};
  streams.forEach((stream) => {
    streamsCounts[stream.klass] = (streamsCounts[stream.klass] || 0) + 1;
  });

  const toggleStreamsView = () => {
    try {
      setViewStreams(!viewStreams);
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  const toggleTeachersView = () => {
    try {
      setViewTeachers(!viewTeachers);
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  useEffect(() => {document.title = "LLR | School Details"
    getSchool();
    getTeachers();
    getStreams();
  }, [getSchool, getStreams, getTeachers]);

  return (
    <div className="container">
      <h4 className="text-center text-success mt-2">
        {school.id}
        {" - "}
        {school.name}
      </h4>
      <AddStream school={school} />
      <div>
        {" "}
        <h6>
          <Button
            className="float-left"
            variant="text"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          {school.category}
          <>Invoice Date {new Date(school.invoice_date).toLocaleDateString()}</>
        </h6>
      </div>
      <hr />

      {/* Classes and Streams */}

      {streams.length > 0 && (
        <div className="p-2 alert alert-success bx-border-radius">
          <h5 className="text-center text-success">
            CLASSES & STREAMS
            <Button
              className="float-right"
              variant="text"
              onClick={toggleStreamsView}
            >
              View
            </Button>
          </h5>
          <hr />
          {viewStreams && (
            <div>
              {uniqueKlasses.map((klass) => (
                <div>
                  <h4 className="text-center text-success m-2">
                    {klass} ({streamsCounts[klass]})
                  </h4>
                  <table className="layout">
                    <thead>
                      <tr>
                        <th>ID</th>

                        <th>Stream</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {streams
                        .filter((stream) => stream.klass === klass)
                        .map((stream) => (
                          <tr key={stream.id}>
                            <td>{stream.id}</td>
                            {streamData && streamData.id === stream.id ? (
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={streamData.stream}
                                  onChange={(e) =>
                                    setStreamData({
                                      ...streamData,
                                      stream: e.target.value,
                                    })
                                  }
                                />
                              </td>
                            ) : (
                              <td>{stream.stream}</td>
                            )}
                            <td>
                              {streamData && streamData.id === stream.id ? (
                                <small>
                                  <Link
                                    className="link btn btn-primry btn-sm"
                                    onClick={() => {
                                      updateStream(stream.id);
                                    }}
                                  >
                                    <BiCheckCircle /> Save
                                  </Link>
                                  <Link
                                    className="link btn btn-primry btn-sm"
                                    onClick={() => setMyStreamData(null)}
                                  >
                                    <BiXCircle /> Cancel
                                  </Link>
                                </small>
                              ) : (
                                <Link
                                  className=" link "
                                  title={`Edit ${stream.stream}`}
                                  onClick={() => {
                                    setMyStreamData(stream);
                                  }}
                                >
                                  <BiPencil />
                                </Link>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {/* teachers */}
      <div className="p-2 alert alert-success bx-border-radius mt-4">
        <h5 className="text-center text-success m-2">
          TEACHERS ({teachers.length}){" "}
          <Button
            className="float-right"
            variant="text"
            onClick={toggleTeachersView}
          >
            View
          </Button>
        </h5>
        <hr />
        {viewTeachers && (
          <div>
            <table className="layout">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>CODE</th>
                  <th>NAME</th>
                  <th>PHONE</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {teachers.map((teacher) => (
                  <tr key={teacher.id}>
                    <td>{teacher.id}</td>
                    <td>{teacher.code}</td>
                    <td>{teacher.name}</td>
                    <td>{teacher.phone}</td>
                    <td>
                      <Link className="link">
                        <BiPencil />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};
export default SchoolsDetails;
