import React, { useState, useEffect, Fragment, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import { showErrorMsg, showSuccessMsg } from "../methods/alertMsgs";
import Axios from "axios";
import { Button } from "@mui/material";
import { TailSpin } from "react-loader-spinner";
import { baseURL } from "../methods/baseUrl";
import IssueTeacher from "./crud/IssueTeacher";
import useDocumentTitle from "./hooks/useDocumentTitle";
import { capitalized } from "../methods/eventHandlers";
import { BiDownload } from "react-icons/bi";
import swal from "sweetalert";

const TBorrowed = ({
  availableBooks,
  setAvailableBooks,
  allTeachers,
  setAllTeachers,
}) => {
  const { auth } = useAuth();
  const mySchool = auth?.school_id;
  const sch_category = auth?.sch_category;
  const [returning, setReturning] = useState(false);
  const [teacher, setTeacher] = useState({});
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const goBack = () => navigate(-1);
  const [issues, setIssues] = useState([]);
  const [issueHistory, setIssueHistory] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [history, setHistory] = useState(null);

  const setMyIssueHistory = (history) => {
    setHistory(history);
  };

  const getTeacher = useCallback(() => {
    setLoading(true);
    /**
     * Regenerate teacher details incase of refresh
     */
    try {
      Axios.get(baseURL + `/get/teacher/${id}/${mySchool}`).then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setTeacher(response.data);
        }
      });
    } catch (error) {
      showErrorMsg(error);
    } finally {
      setLoading(false);
    }
  }, [id, mySchool]);

  const getTeachers = useCallback(async () => {
    // alert("getTeachers called from Teachers.js");
    /**
     * Extract all teachers details present in a school
     * This function is invoked only incase the page is
     * refreshed, teacher details are searched.teacher detdails edited,
     * teacher deleted or when a new teacher is added to the database
     */
    try {
      await Axios.get(baseURL + `/teachers/${mySchool}`).then((response) => {
        if (response?.data?.results) {
          // setTeachers(response.data.results);
          setAllTeachers(response.data.results);
          // setLoading(false);
        } else {
          showErrorMsg(response.data.msg);
        }
      });
    } catch (error) {
      showErrorMsg(error);
    }
  }, [mySchool, setAllTeachers]);

  const getIssues = useCallback(async () => {
    setLoading(true);
    try {
      await Axios.get(baseURL + `/teachers/borrowed/${id}`)
        .then((response) => {
          if (response.data.msg) {
            showErrorMsg(response.data.msg);
          } else {
            if (response.data.length === 0) {
              setMsg("No active records");
            } else {
              setIssues(response.data);
            }
            setLoading(false);
          }
        })
        .catch((error) => {
          showErrorMsg(error);
        });
    } catch (error) {
      showErrorMsg(error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const getIssueHistory = useCallback(async () => {
    setLoading(true);
    try {
      await Axios.get(baseURL + `/teacher/issue/history/${id}`).then(
        (response) => {
          if (response.data.msg) {
            showErrorMsg(response.data.msg);
          } else {
            setIssueHistory(response.data);
          }
        }
      );
    } catch (error) {
      showErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const handleDownload = async () => {
    try {
      await Axios.get(baseURL + `/teacher/download/borrowed/${id}`, {
        responseType: "blob",
      }).then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          const downloadUrl = URL.createObjectURL(response.data);
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", `${teacher.name}.xlsx`);

          // link.download = "borrowed_books.xlsx";
          link.click();
          URL.revokeObjectURL(downloadUrl);
        }
      });
    } catch (error) {
      console.error("Error downloading the file:", error.message);
    }
  };

  const getSubjects = useCallback(async () => {
    /**
     * Generate only the subjects with no_of_books > 0
     * Called when in need to issue a book
     * where the subject is first selected before
     * the books that HAVE NOT BEEN ISSUED are populated
     */
    await Axios.get(
      baseURL + `/subjects/with/books/more/${mySchool}/${sch_category}`
    ).then((response) => {
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setSubjects(response.data);
      }
    });
  }, [mySchool, sch_category]);

  function deleteTeacher(id, teacherName) {
    try {
      swal({
        title: `Press OK to delete ${teacherName}`,
        icon: "info",
        buttons: true,
      }).then(async (confirmTrue) => {
        if (confirmTrue) {
          await Axios.delete(baseURL + `/teachers/delete/${id}`).then(
            (response) => {
              if (response.data.success) {
                showSuccessMsg(response.data.success);
                navigate("/teachers");
                if (getTeachers) {
                  getTeachers();
                }
              } else {
                showErrorMsg(response.data.msg);
              }
            }
          );
        }
      });
    } catch (error) {
      showErrorMsg(error);
    }
  }

  // Update the click handler to set the issue history
  const handleReturnClick = (issue) => {
    setMyIssueHistory(issue);
  };

  const getAvailableBooks = useCallback(async () => {
    try {
      const response = await Axios.get(
        baseURL + `/available/books/${mySchool}`
      );

      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setAvailableBooks(response.data);
      }
    } catch (error) {
      showErrorMsg(error);
    }
  }, [mySchool, setAvailableBooks]);

  // Return borrowed books/Teacher
  const returnBooks = useCallback(
    async (issue, book_id) => {
      try {
        setReturning(true);
        const receiver = auth.username;
        const return_date = new Date();
        const issue_date = history.issue_date;
        const klass = history.klass_name;
        const book_name = history.b_name;
        const book_reg = history.b_reg;
        const issuer = history.issuer;
        const book_id = history.b_id;

        const body = {
          issuer,
          return_date,
          issue_date,
          receiver,
          klass,
          book_name,
          book_reg,
          id,
          book_id,
        };
        await Axios.post(baseURL + `/return/teacher/${book_id}`, body)
          .then((data) => {
            if (data.data.success) {
              getTeachers();
              getIssueHistory();
              getAvailableBooks();
              showSuccessMsg(data.data.success);
            } else {
              showErrorMsg(data.data.msg);
            }
          })
          .catch((error) => {
            showErrorMsg(error);
          });
      } catch (error) {
        showErrorMsg(error);
      } finally {
        setReturning(false);
      }
    },
    [
      auth.username,
      getAvailableBooks,
      getIssueHistory,
      getTeachers,
      history,
      id,
    ]
  );

  useEffect(() => {
    if (availableBooks.length === 0) {
      console.log("No books, regenerating");
      getAvailableBooks();
    }
  }, [availableBooks, getAvailableBooks]);

  useDocumentTitle("TBorrowed");

  // Add a useEffect to listen for changes in history
  useEffect(() => {
    if (history !== null) {
      // Call returnBooks when history is not null
      returnBooks(history.id, history.b_id);
    }
  }, [history, returnBooks]);

  // Rerender when issues change
  useEffect(() => {
    getSubjects();
    getIssues();
    getIssueHistory();
    getTeacher();
  }, [allTeachers, getIssueHistory, getSubjects, getTeacher, getIssues]);

  //The JSX
  return (
    <div className="container">
      <>
        {loading ? (
          <TailSpin
            height="60"
            width="60"
            color="rgb(183, 71, 221)" //"#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{
              top: "50%",
              left: "50%",
              position: "absolute",
              transform: "translate(-50%, -50%)",
            }}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <>
            <div className="mt-3">
              <h6 className="text-center text-success m-2">
                {teacher.code} {" - "}
                {teacher.name}
                <Button variant="text" className="float-left" onClick={goBack}>
                  Back
                </Button>
                <Button
                  disabled={loading}
                  className="text-capitalize float-right text-left"
                >
                  <IssueTeacher
                    getAvailableBooks={getAvailableBooks}
                    availableBooks={availableBooks}
                    setAvailableBooks={setAvailableBooks}
                    teacher={teacher}
                    subjects={subjects}
                    getTeachers={getTeachers}
                    className="float-left"
                  />
                  <Link
                    title={`Issue books to ${teacher.name}`}
                    data-toggle="modal"
                    data-target={`#issueModal${teacher.code}`}
                    className="link"
                  >
                    Issue
                  </Link>
                </Button>
              </h6>
              <h5 className="text-center text-success">
                <small>
                  {" "}
                  Books {" - "} {issues.length}
                  {issues.length > 0 ||
                    (issueHistory.length > 0 && (
                      <Link
                        title={"Download books issued"}
                        className={"mr-4 link"}
                        onClick={handleDownload}
                      >
                        <BiDownload className="ml-4 text-danger" size={16} />
                      </Link>
                    ))}{" "}
                  {issues.length < 1 && (
                    <Button
                      disabled={loading}
                      className="float-right text-left link text-capitalize"
                      onClick={() => deleteTeacher(teacher.id, teacher.name)}
                    >
                      Delete
                    </Button>
                  )}
                </small>
              </h5>
              <hr />
            </div>

            {issues.length === 0 ? (
              <h5 className="text-center text-success m-4 p-4">{msg}</h5>
            ) : (
              <div>
                <table className="layout">
                  <thead>
                    <tr>
                      <th>Book Name</th>
                      <th>Book Reg</th>
                      <th>Class</th>
                      <th>Issue Date</th>
                      <th>Issuer</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {issues.map((issue) => (
                      <tr key={issue.id}>
                        <td id="borrowed" title="Click to see book details">
                          <Link
                            className="link"
                            to={`/book/bookId/${issue.b_id}`}
                          >
                            {capitalized(issue.b_name)}
                          </Link>
                        </td>
                        <td>{issue.b_reg}</td>
                        <td>{issue.klass_name}</td>
                        {/* <td>{new Date(issue.issue_date).toDateString()}</td> */}
                        <td>{new Date(issue.issue_date).toLocaleString()}</td>
                        {/* <td>{formatDate(issue.issue_date)}</td> */}
                        <td>{issue.issuer || "admin"}</td>
                        <td>
                          <Button
                            className="link text-capitalize"
                            disabled={returning}
                            onClick={() => {
                              handleReturnClick(issue);
                            }}
                          >
                            Return
                          </Button>
                          {/* <Link
                            className="link"
                            disabled={!returning}
                            onClick={() => {
                              handleReturnClick(issue);
                            }}
                          >
                            Return
                          </Link>{" "} */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {issueHistory.length > 0 && (
              <div>
                <hr />
                <h5 className="m-2 text-center text-success">
                  Borrowing History
                </h5>
                <table className="layout">
                  <thead>
                    <tr>
                      <th>Book Name</th>
                      <th>Book Reg</th>
                      <th>Class</th>
                      <th>Issue Date</th>
                      <th>Return Date</th>
                      <th>Issuer</th>
                      <th>Receiver</th>
                    </tr>
                  </thead>
                  <tbody>
                    {issueHistory.map((issue) => (
                      <tr key={issue.id}>
                        <td id="borrowed" title="Click to see book details">
                          <Link
                            className="link"
                            to={`/book/bookId/${issue.book_id}`}
                          >
                            {capitalized(issue.book_name)}
                          </Link>
                        </td>
                        <td>{issue.book_reg}</td>
                        <td>{issue.klass}</td>
                        {/* <td>{new Date(issue.issue_date).toDateString()}</td> */}
                        <td>{new Date(issue.issue_date).toLocaleString()}</td>
                        {/* <td>{formatDate(issue.issue_date)}</td> */}
                        <td>{new Date(issue.return_date).toLocaleString()}</td>
                        <td>{issue.issuer || "admin"}</td>
                        <td>{issue.receiver}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
};
export default TBorrowed;
