// export const baseURL = "http://127.0.0.1:8080"; //"https://llr-back.cleverapps.io"; //

let baseURL;

if (process.env.NODE_ENV === "production") {
  baseURL = "https://backend-llr.cleverapps.io"
} else {
  baseURL = "http://127.0.0.1:8080";
}

export { baseURL };

// let baseURL;

// if (process.env.NODE_ENV === "production") {
//   baseURL = "https://llr-backs.cleverapps.io";
// } else {
//   baseURL = "http://127.0.0.1:8080";
// }

// export { baseURL };
