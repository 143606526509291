import React, { useState, useEffect } from "react";
import { showErrorMsg, showSuccessMsg } from "../methods/alertMsgs";
import useAuth from "./hooks/useAuth";
import Axios from "axios";
import { TailSpin } from "react-loader-spinner";
import { Toolbar } from "@mui/material";
import { baseURL } from "../methods/baseUrl";

const Graduate = ({ klass_id,getAllStudents }) => {
  const { auth } = useAuth();
  const school_id = auth?.school_id;
  const [loading, setLoading] = useState(false);
  const [graduationYear, setGraduationYear] = useState(null);
  const [gradYears, setGradYears] = useState([]);

  // Functions
  const graduateYears = async () => {
    /**
     * Pulls data from the database on already created
     * graduate years
     */
    await Axios.get(baseURL + `/graduate/years`)
      .then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setGradYears(response.data);
        }
      })
      .catch((error) => {
        showErrorMsg(error.message);
      });
  };

  const graduateStudents = async () => {
    /**
     * Graduate students to the next class
     */
    const body = { graduationYear, klass_id, school_id };

    await Axios.put(baseURL + "/students/graduate", body)
      .then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setLoading(false);
          showSuccessMsg(response.data.success);
          getAllStudents();
        }
      })
      .catch((error) => {
        showErrorMsg(error.message);
      });
  };

  useEffect(() => {
    graduateYears();
  }, []);

  return (
    <div className="container modal" id="graduate">
      {/* <div className="modal" id="graduate"> */}
      <Toolbar />
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header text-center text-success m-3">
            <h4 className="modal-title">MAKE GRADUATES</h4>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          {/* The form */}
          <div className=" modal-body text-center m-4" id="mySelect">
            <div className="d-flex">
              <select
                className="w-40 m-1 form-control"
                disabled={loading}
                onChange={(e) => {
                  setGraduationYear(e.target.value);
                }}
              >
                <option value="" hidden defaultValue>
                  --Select Year of Graduation--
                </option>
                {gradYears.map((year) => (
                  <option key={year.id} value={year.id}>
                    {year.year_graduated}
                  </option>
                ))}
              </select>
              <br />
            </div>
            <br />
            <br />
            <br />
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger mr-auto"
                data-dismiss="modal"
              >
                Close
              </button>
              <input
                onClick={() => {
                  setLoading(true);
                  graduateStudents();
                }}
                disabled={loading || !graduationYear}
                type="submit"
                value="Process"
                id="submit"
                className="text-white btn btn-outline-success ml-auto"
              />
            </div>
          </div>
          {loading && (
            <TailSpin
              height="50"
              width="50"
              color="rgb(183, 71, 221)" //"#4fa94d"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{
                top: "50%",
                left: "50%",
                position: "relative",
              }}
              wrapperClass=""
              visible={true}
            />
          )}
          <br />
        </div>
      </div>
    </div>
  );
};
export default Graduate;
