import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef,
} from "react";
import { useMediaQuery, createTheme } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Axios from "axios";
import logo from "./logo/logo-colored.gif";
import { baseURL } from "../../methods/baseUrl";
import { showErrorMsg } from "../../methods/alertMsgs";
import Button from "@mui/material/Button";

const Invoice = () => {
  const { auth } = useAuth();
  const schoolId = auth.school_id;
  const navigate = useNavigate();
  const theme = createTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [school, setSchool] = useState([]);
  const [students, setStudents] = useState([]);

  const getSchool = async (id) => {
    try {
      const response = await Axios.get(baseURL + `/school/${id}`);
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setSchool(response.data);
      }
    } catch (err) {
      showErrorMsg(err.message);
    }
  };

  const getStudents = async (school) => {
    try {
      const response = await Axios.get(baseURL + `/students/${school}`);
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setStudents(response.data);
      }
    } catch (err) {
      showErrorMsg(err.message);
    }
  };

  const calculateSubscriptions = useCallback(
    (count) => {
      let subscriptionPrice;

      if (count < 301) {
        subscriptionPrice = school.installed ? "5,000" : "10,000";
      } else if (count < 601) {
        subscriptionPrice = school.installed ? "10,000" : "20,000";
      } else if (count < 901) {
        subscriptionPrice = school.installed ? "15,000" : "30,000";
      } else if (count < 1201) {
        subscriptionPrice = school.installed ? "20,000" : "40,000";
      } else if (count < 1501) {
        subscriptionPrice = school.installed ? "25,000" : "50,000";
      } else if (count < 1801) {
        subscriptionPrice = school.installed ? "30,000" : "60,000";
      } else if (count < 2101) {
        subscriptionPrice = school.installed ? "35,000" : "70,000";
      } else {
        subscriptionPrice = school.installed ? "40,000" : "80,000";
      }

      return subscriptionPrice;
    },
    [school]
  );

  useEffect(() => {
    document.title = "LLR || Invoice";
    getSchool(schoolId);
    getStudents(schoolId);
  }, [schoolId]);

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const subscriptions = useMemo(
    () => calculateSubscriptions(students.length),
    [calculateSubscriptions, students.length]
  );

  const subscriptionAmount = useMemo(() => {
    return parseInt(subscriptions.replace(/,/g, ""));
  }, [subscriptions]);

  const taxAmount = useMemo(() => {
    return (subscriptionAmount * 0.08).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }, [subscriptionAmount]);

  const totalAmount = useMemo(() => {
    return (
      subscriptionAmount
        // subscriptionAmount + parseFloat(taxAmount.replace(/,/g, ""))
        .toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
    );
  }, [subscriptionAmount]);

  const style = {
    width: isMobileScreen ? "98%" : "50%",
    margin: "auto",
    padding: "20px",
    borderTopRightRadius: "40px",
    borderBottomLeftRadius: "40px",
    border: "2px solid grey",
    fontFamily: "Exo",
  };
  const topStyle = {
    width: isMobileScreen ? "98%" : "50%",
    margin: "auto",
  };
  const invoiceDate = new Date(school.invoice_date);

  const date = invoiceDate.toLocaleDateString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  return (
    <div className="container mt-4">
      <div className="text-center pb-3" style={topStyle}>
        {/* <img
          style={{ width: "110px", height: "90px" }}
          src={logo}
          alt="LLR Logo"
        /> */}
        {/* <hr /> */}
        <div>
          <Button className="float-left" onClick={() => navigate(-1)}>
            Back
          </Button>

          <Button
            title="Save/Print Invoice"
            className="float-right"
            onClick={handlePrint}
          >
            Print
          </Button>
        </div>
      </div>
      <br />
      <hr style={topStyle} />
      <div className="mt-4 pt-4" id="toPrint" style={style} ref={componentRef}>
        <div className="text-center">
          <img
            style={{ width: "140px", height: "120px" }}
            src={logo}
            alt="logo"
          />
        </div>
        <hr />
        <div className="float-right">
          <h1 className="text-center">INVOICE</h1>
          <table className="layout">
            <thead>
              <tr>
                <th>INVOICE NO</th>
                <th>DUE DATE</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {school.id}/{new Date().getFullYear().toString().slice(-2)}
                </td>
                <td>{date}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <h5>LINKITS DIGITAL</h5>
          P.O BOX 143
          <br />
          Sawagongo
          <br /> linkitslibrary@gmail.com
          <br />
          <u>0725109389</u>
          <br />
          <br />
          <hr />
          <h4>BILL TO</h4>
          {school.name}
          <br />
          P.O BOX {school.box}
          <br />
          {school.town}
        </div>
        <hr />
        <div>
          <table className="layout m-auto mt-4 mb-4">
            <thead>
              <tr>
                <th>ITEM DESCRIPTION</th>
                <th>QUANTITY</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>No of Students</th>
                <td>{students.length}</td>
              </tr>
              <tr>
                {school.installed ? (
                  <th>{new Date().getFullYear().toString()} Subscription</th>
                ) : (
                  <th>Installation & 1st Year Subscription</th>
                )}
                <td>{subscriptions}</td>
              </tr>
              {/* <tr>
                <th>Tax(8%)</th>
                <td> {taxAmount}</td>
              </tr> */}
              <tr>
                <th> </th>
                <td> </td>
              </tr>

              <tr className={"mt-4 mb-4"}>
                <th>TOTAL</th>
                <td>{totalAmount}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <div className="text-center mt-4">
          If you have any questions about this invoice, please call 0725109389
          <hr />
          www.linkitslibrary.com
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Invoice;
