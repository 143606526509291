import React, { useState, useEffect } from "react";
import AddKlass from "../crud/AddKlass";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
import Axios from "axios";
import { baseURL } from "../../methods/baseUrl";
import { useCallback } from "react";
import { BiPencil, BiXCircle, BiCheckCircle, BiTrash } from "react-icons/bi";
import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const Klasses = () => {
  const { auth } = useAuth();
  const [klasses, setKlasses] = useState([]);
  const [klassData, setKlassData] = useState(null);

  const setMyKlassData = (data) => {
    setKlassData(data);
  };

  const getKlasses = useCallback(async () => {
    try {
      await Axios.get(baseURL + "/allKlasses").then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setKlasses(response.data);
        }
      });
    } catch (error) {
      showErrorMsg(error.message);
    }
  }, [setKlasses]);
  
  const uniqueCategories = [
    ...new Set(klasses.map((subject) => subject.category)),
  ];

  const categoryCounts = {};
  klasses.forEach((klass) => {
    categoryCounts[klass.category] = (categoryCounts[klass.category] || 0) + 1;
  });

  const updateKlass = async (id) => {
    try {
      const name = klassData.name;
      const body = { name };
      await Axios.put(baseURL + `/update/klass/${id}`, body).then(
        (response) => {
          if (response.data.msg) {
            showErrorMsg(response.data.msg);
          } else {
            showSuccessMsg(response.data.success);
            getKlasses();
          }
        }
      );
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  const deleteKlass = (id) => {
    alert("Cannot delete this class");
  };

  useEffect(() => {
    document.title="LLR | Classes"
    getKlasses();
  }, [getKlasses]);

  return (
    <div className="container">
      {auth.is_superuser ? (
        <>
          <div className="d-flex align-items-center">
            <h4 className="text-center text-success m-4">CLASSES</h4>
            <AddKlass />
          </div>

          <div>
            {uniqueCategories.map((category) => (
              <div key={category} className="category-table">
                <h5 className="category-title">
                  {category} ({categoryCounts[category]})
                </h5>
                <table className="layout">
                  <thead>
                    <tr>
                      <th>Class ID</th>
                      <th>Class Name</th>
                      <th>No. of Books</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {klasses
                      .filter((klass) => klass.category === category)
                      .map((klass) => (
                        <tr key={klass.id}>
                          <td>{klass.id}</td>
                          {klassData && klassData.id === klass.id ? (
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                value={klassData.name}
                                onChange={(e) =>
                                  setKlassData({
                                    ...klassData,
                                    name: e.target.value,
                                  })
                                }
                              />
                            </td>
                          ) : (
                            <td>{klass.name}</td>
                          )}
                          <td>{klass.count}</td>{" "}
                          <td>
                            {klassData && klassData.id === klass.id ? (
                              <small>
                                <Link
                                  className="link btn btn-primry btn-sm"
                                  onClick={() => {
                                    updateKlass(klass.id);
                                  }}
                                >
                                  <BiCheckCircle /> Save
                                </Link>
                                <Link
                                  className="link btn btn-primry btn-sm"
                                  onClick={() => setMyKlassData(null)}
                                >
                                  <BiXCircle /> Cancel
                                </Link>
                              </small>
                            ) : (
                              <Link
                                className=" link "
                                title={`Edit ${klass.name}`}
                                onClick={() => {
                                  setMyKlassData(klass);
                                }}
                              >
                                <BiPencil />
                              </Link>
                            )}
                          </td>
                          <td>
                            {klass.count > 0 ? (
                              <small>-</small>
                            ) : (
                              <BiTrash
                                onClick={() => deleteKlass(klass.id)}
                                className="link"
                                size={18}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div>Unauthorized Access</div>
      )}
    </div>
  );
};
export default Klasses;
