import React, { useEffect, useState, useRef } from "react";
import Axios from "axios";
import { baseURL } from "../../methods/baseUrl";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
import { useReactToPrint } from "react-to-print";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { BiPencil, BiXCircle, BiCheckCircle } from "react-icons/bi";
import logo from "./logo/logo-colored.gif";
import { useMediaQuery, createTheme } from "@mui/material";

const Pricing = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [pricing, setPricing] = useState([]);
  const componentRef = useRef(null);
  const [editable, setEditable] = useState(false);
  const [priceData, setPriceData] = useState(null);

  const theme = createTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleEditClick = () => {
    setEditable(true);
  };

  const handleInvoiceClick = () => {
    if (auth.subscribed) {
      navigate("/invoice1");
    } else {
      navigate("/invoice");
    }
  };
  const editMyPrice = (price) => {
    setPriceData(price);
  };
  const getPricing = async () => {
    try {
      const response = await Axios.get(baseURL + "/pricing");
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setPricing(response.data);
      }
    } catch (err) {
      showErrorMsg(err.message);
    }
  };

  const editPrice = async (id) => {
    /**
     * Edit the prices per category of students
     */
    const students = priceData.students;
    const installation = priceData.installation;
    const subscription = priceData.subscription;

    const body = { students, installation, subscription };
    await Axios.put(baseURL + `/update/pricing/${id}`, body).then(
      (response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          getPricing();
          setPriceData(null);
          showSuccessMsg(response.data.success);
        }
      }
    );
  };

  document.title = "LLR | Pricing";

  useEffect(() => {
    getPricing();
  }, []);

  const styling = {
    height: "50px",
    fontSize: isMobileScreen ? "13px" : "18px",
  };
  const tdStyle = {
    height: "50px",
    fontSize: isMobileScreen ? "13px" : "18px",
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="container mt-2 pt-4">
      <div className="container" style={{ width: "90%", margin: "0 auto" }}>
        <div className="text-center">
          <img
            style={{ width: "110px", height: "90px" }}
            src={logo}
            alt="LLR Logo"
          />
        </div>
        <div className="">
          <h4
            className="text-center text-success mt-2 mb-4"
            style={{ fontFamily: "Exo" }}
          >
            PRICE BANDS{" "}
            {auth.is_superuser && (
              <>
                [
                {!editable ? (
                  <Link className="link" onClick={handleEditClick}>
                    <BiPencil size={18} />
                  </Link>
                ) : (
                  <Link className="link" onClick={() => setEditable(false)}>
                    <BiXCircle />
                  </Link>
                )}
                ]
              </>
            )}
          </h4>
          <hr />

          <div className="">
            <Button
              variant="text"
              className="float-left"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <div className="float-right">
              <Button
                title="Save/Print PDF"
                variant="text"
                className="ml-2"
                onClick={handlePrint}
              >
                PDF
              </Button>
              <Button
                title="View Invoice"
                variant="text"
                className="ml-2"
                onClick={handleInvoiceClick}
              >
                Invoice
              </Button>
            </div>
          </div>
        </div>
        <br />
        <br />
        <table className="layout">
          <thead>
            <tr>
              <th style={styling}>No. of Students</th>
              <th style={styling}>Installation & First Year</th>
              <th style={styling}>Yearly Subscription</th>
              {editable && <th style={styling}>Edit</th>}
            </tr>
          </thead>
          <tbody>
            {pricing.map((price) => (
              <tr key={price.id}>
                <td style={tdStyle}>
                  {priceData && priceData.id === price.id ? (
                    <input
                      type="text"
                      className="form-control"
                      value={priceData.students}
                      onChange={(e) =>
                        setPriceData({
                          ...priceData,
                          students: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <>{price.students}</>
                  )}
                </td>
                <td style={tdStyle}>
                  {priceData && priceData.id === price.id ? (
                    <input
                      type="text"
                      className="form-control"
                      value={priceData.installation}
                      onChange={(e) =>
                        setPriceData({
                          ...priceData,
                          installation: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <>{price.installation}</>
                  )}
                </td>
                <td style={tdStyle}>
                  {priceData && priceData.id === price.id ? (
                    <input
                      type="text"
                      className="form-control"
                      value={priceData.subscription}
                      onChange={(e) =>
                        setPriceData({
                          ...priceData,
                          subscription: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <>{price.subscription}</>
                  )}
                </td>
                {editable && (
                  <td>
                    {priceData && priceData.id === price.id ? (
                      <small>
                        <Link
                          title="Save"
                          className="link btn btn-primry btn-sm"
                          onClick={() => {
                            editPrice(price.id);
                          }}
                        >
                          <BiCheckCircle className="text-success" size={24} />
                        </Link>
                        <Link
                          title="Cancel"
                          className="link btn btn-primry btn-sm"
                          onClick={() => setPriceData(null)}
                        >
                          <BiXCircle className="text-danger" size={24} />
                        </Link>
                      </small>
                    ) : (
                      <Link
                        className=" link "
                        title={`Edit ${price.students} details`}
                        onClick={() => {
                          editMyPrice(price);
                        }}
                      >
                        <BiPencil size={20} />
                      </Link>
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div style={{ display: "none" }}>
        <ComponentToPrint ref={componentRef} pricing={pricing} />
      </div>
    </div>
  );
};

const ComponentToPrint = React.forwardRef(({ pricing }, ref) => (
  <div
    ref={ref}
    className="container"
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "80vh",
      width: "100%",
    }}
  >
    <div
      className="inner-container"
      style={{
        width: "80%",
        position: "relative",
      }}
    >
      <div className="text-center m-auto">
        <img
          style={{ width: "120px", height: "80px" }}
          src={logo}
          alt="LLR Logo"
        />
      </div>
      <h4 className="text-center text-success mb-4">
        LLR PRICING
        <div className="text-center mb-4">Help Line: 0725109389</div>
      </h4>
      <hr />
      <table className="layout">
        <thead>
          <tr>
            <th style={{ height: "60px", fontSize: "20px" }}>
              No. of Students
            </th>
            <th style={{ height: "60px", fontSize: "20px" }}>
              Installation & First Year
            </th>
            <th style={{ height: "60px", fontSize: "20px" }}>
              Yearly Subscription
            </th>
          </tr>
        </thead>
        <tbody>
          {pricing.map((price) => (
            <tr key={price.id}>
              <td style={{ height: "50px", fontSize: "20px" }}>
                {price.students}
              </td>
              <td style={{ height: "50px", fontSize: "20px" }}>
                {price.installation}
              </td>
              <td style={{ height: "50px", fontSize: "20px" }}>
                {price.subscription}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
));

export default Pricing;
