import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import { showErrorMsg } from "../methods/alertMsgs";
import Axios from "axios";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import PeopleIcon from "@mui/icons-material/People";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { baseURL } from "../methods/baseUrl";
import useDocumentTitle from "./hooks/useDocumentTitle";
import { Rings } from "react-loader-spinner";

const Home = ({
  allTeachers,
  setAllTeachers,
  allBooks,
  setAllBooks,
  setAllStudents,
  allStudents,
  allStudentIssuesCount,
  setAllStudentIssuesCount,
  setAllTeacherIssuesCount,
  allTeacherIssuesCount,
  setAvailableBooks,
}) => {
  const { auth } = useAuth();

  const school = auth?.school_id;
  const mySchool = auth?.school_id;
  const [countSchBooks, setCountSchBooks] = useState();
  const [countGovtBooks, setCountGovtBooks] = useState();
  const [theSchool, setSchool] = useState([]);
  const [expiryDays, setExpiryDays] = useState("");
  const [loading, setLoading] = useState(false);

  const getAllBooks = useCallback(async () => {
    /**
     * Generate all the books in a school, borrowed and not borrowed
     * Here, it is called to help generate the details of all books upon page load
     */
    await Axios.get(baseURL + `/books/${mySchool}`).then((response) => {
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        const availableBooks = response.data.filter((book) => {
          return book.no_of_books === 1; // Return a boolean value here
        });
        setAllBooks(response.data);
        setAvailableBooks(availableBooks); // Set the filtered books here
      }
    });
  }, [mySchool, setAllBooks, setAvailableBooks]);

  //Get Expiration days
  const getDays = useCallback(() => {
    const invoiceDate = new Date(theSchool.invoice_date);
    const todaysDate = new Date();
    const daysInMilliseconds = Math.abs(invoiceDate - todaysDate);
    setExpiryDays(Math.floor(daysInMilliseconds / (1000 * 60 * 60 * 24)));
  }, [theSchool]);

  const getAllStudents = useCallback(async () => {
    setLoading(true);
    /**
     * Get all the details of all students in a school
     * This is to be stored and used everytime an issue is required
     */
    try {
      await Axios.get(baseURL + "/students/" + school).then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setAllStudents(response.data);
        }
      });
    } catch (error) {
      showErrorMsg(error);
    } finally {
      setLoading(false);
    }
  }, [school, setAllStudents]);

  const getTeachers = useCallback(async () => {
    // alert("getTeachers called from Home.js");

    /**
     * Get all the details of all teachers in a school
     * */
    try {
      await Axios.get(baseURL + `/teachers/${school}`).then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setAllTeachers(response.data.results);
        }
      });
    } catch (error) {
      console.log(error);

      showErrorMsg(error);
    }
  }, [school, setAllTeachers]);

  const getStudentIssues = useCallback(async () => {
    /**
     * Gets the count of all books issued to students
     */
    await Axios.get(baseURL + "/students/issues/count/" + mySchool)
      .then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setAllStudentIssuesCount(response.data.count);
        }
      })
      .catch((error) => {
        showErrorMsg(error);
      });
  }, [mySchool, setAllStudentIssuesCount]);

  const getTeacherIssues = useCallback(async () => {
    /**
     * Gets the count of all books issued to the teachers
     */
    await Axios.get(baseURL + "/teachers/issues/count/" + mySchool)
      .then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setAllTeacherIssuesCount(response.data.count);
        }
      })
      .catch((error) => {
        showErrorMsg(error);
      });
  }, [mySchool, setAllTeacherIssuesCount]);

  const getSchool = async (id) => {
    try {
      const response = await Axios.get(baseURL + `/school/${id}`);
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setSchool(response.data);
      }
    } catch (err) {
      console.log(err);
      showErrorMsg(err.message);
    }
  };

  const invoiceDate = new Date(theSchool.invoice_date);
  const date = invoiceDate.toLocaleDateString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  // Always runs on rerender
  useDocumentTitle("Home");

  useEffect(() => {
    getDays();
  }, [getDays]);

  useEffect(() => {
    try {
      getTeacherIssues();
      getSchool(school);
      getStudentIssues();
      if (allBooks.length < 1) {
        getAllBooks(); // Sets all books propeties and re-used in other components
      } else {
        setCountSchBooks(
          allBooks.filter((book) => book.name === "sch copy").length
        );
        setCountGovtBooks(
          allBooks.filter((book) => book.name === "gov copy").length
        );
      }
      if (allStudents.length < 1) {
        getAllStudents(); // Sets all books propeties and re-used in other components
      }
      if (!allTeachers) {
        getTeachers();
      }
    } catch (error) {
      showErrorMsg(error);
    }
  }, [
    allBooks,
    allStudents,
    allTeachers,
    getAllBooks,
    getAllStudents,
    getStudentIssues,
    getTeacherIssues,
    getTeachers,
    school,
  ]);

  // The HTML Section
  return (
    <div className="container mt-4 pt-4">
      <div id="llr">
        <h3 id="title" style={{ fontFamily: "exo" }} className="mt-3 link">
          LINKITS LIBRARY RECORDS [LLR]
        </h3>

        {expiryDays && expiryDays < 61 && (
          //The alert is seen 60 days to expiry of the subscription
          <div>
            <h6 id="expiry">
              Subscription Expires in <b>{expiryDays} Days</b> [{date}]
            </h6>
            <Link className="link" to={"/invoice"}>
              View Invoice
            </Link>
          </div>
        )}
      </div>
      <div id="home">
        <Link
          to={"/books"}
          className="home-contents link"
          id="card1"
          style={{ position: "relative" }}
        >
          <div>
            {" "}
            ALL BOOKS {allBooks.length}
          </div>
          <LibraryBooksIcon className="text-success float-right" />
          <hr className="mb" />
          <div>
            <div>Gov Books - {countGovtBooks}</div>
            <div>Sch Books - {countSchBooks}</div>
          </div>
        </Link>
        <Link to={"/students"} className="home-contents link" id="card2">
          <div>STUDENTS {allStudents.length}</div>
          <PeopleIcon className="text-success float-right" /> <hr />
          <div>Books Issued- {allStudentIssuesCount}</div>
        </Link>
        <Link
          to={{ pathname: "/teachers" }}
          className="home-contents link"
          id="card3"
        >
          <div>TEACHERS {allTeachers?.length}</div>
          <PeopleOutlineIcon className="text-success float-right" />
          <hr />
          <div>Books Issued - {allTeacherIssuesCount}</div>
        </Link>
      </div>
      {/* Update Password When logged In */}
      <br />
      <hr className="mt-4" />
    </div>
  );
};
export default Home;
