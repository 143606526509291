import React, { useState } from "react";
import useAuth from "../../src/components/hooks/useAuth";
import { Link } from "react-router-dom";

import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({});

const Unsubscribed = () => {
  const { auth } = useAuth();
  const [displayMessage, setDisplayMessage] = useState([]);

  return (
    <ThemeProvider theme={theme}>
      <div className="container m-4 p-4 text-center ">
        <br />
        <p style={{ fontFamily: "Chakra", fontSize: "24px" }}>
          Hello, {auth.last_name}
        </p>
        <Link className="link" to={"/invoice"}>
          View Invoice
        </Link>
        <br />
        <div
          className="text-success"
          style={{ fontFamily: "NovaOval", fontSize: "22px" }}
        >
          <p>
            You are seeing this screen because your school has not subscribed
            for the current year
          </p>
          <p>Please contact the provider on 0725109389 to get reconnected</p>
          <p>
            {" "}
            Your records are intact and safe. Once you're reconnected, you'll be
            able to enjoy our services
          </p>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Unsubscribed;
