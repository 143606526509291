import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { baseURL } from "../../methods/baseUrl";
import { showErrorMsg } from "../../methods/alertMsgs";
import Axios from "axios";

const AddSchool = () => {
  const [categories, setCategories] = useState([]);
  const [school, setSchool] = useState([]);

  const addSchool = async (e) => {
    e.preventDefault();
    try {
      const name = school.name;
      const box = school.box;
      const town = school.town;
      const category_id = school.category_id;
      const body = { name, category_id, town, box };
      await fetch(baseURL + "/school/add", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
    } catch (error) {
      alert(error.message);
    }
  };

  const getCategories = async () => {
    try {
      await Axios.get(baseURL + "/sch-categories").then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setCategories(response.data);
        }
      });
    } catch (err) {
      showErrorMsg(err.message);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  // JSX
  return (
    <div>
      <Link className="link" data-toggle="modal" data-target={`#addSq`}>
        + School
      </Link>

      <div className="modal" id={`addSq`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-success">Add School</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div className="modal-body">
              <select
                className="form-control"
                onChange={(e) =>
                  setSchool({
                    ...school,
                    category_id: e.target.value,
                  })
                }
              >
                <option value={null} selected hidden>
                  --Select Category--
                </option>
                {categories.map((category) => (
                  <option value={category.id} key={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
              <br />
              <label htmlFor="theName">School Name</label>
              <input
                name="theName"
                onChange={(e) =>
                  setSchool({
                    ...school,
                    name: e.target.value,
                  })
                }
                placeholder="Enter School Name"
                className="form-control mb-1"
              />
              <label htmlFor="box">Box</label>
              <input
                name="box"
                onChange={(e) =>
                  setSchool({
                    ...school,
                    box: e.target.value,
                  })
                }
                placeholder="Box No."
                className="form-control mb-1"
              />
              <label htmlFor="town">Town</label>
              <input
                name="town"
                placeholder="Enter Town"
                className="form-control mb-1"
                onChange={(e) =>
                  setSchool({
                    ...school,
                    town: e.target.value,
                  })
                }
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger mr-auto"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={addSchool}
                id="submit"
                className="text-white btn btn-outline-success ml-auto"
                data-dismiss="modal"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddSchool;
