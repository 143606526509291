import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { baseURL } from "../../methods/baseUrl";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
import Axios from "axios";

const AddKlass = ({}) => {
  const [className, setClassName] = useState("");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(null);

  const addKlasses = async () => {
    try {
      const name = className;
      const sch_category = category;
      if (!name) {
        showErrorMsg("Name field is blank");
        return;
      }
      if (!sch_category) {
        showErrorMsg("School Category is blank");
        return;
      }

      const body = { name, sch_category };
      await Axios.post(baseURL + "/add/klass", body).then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          showSuccessMsg(response.data.success);
        }
      });
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  const getCategories = async () => {
    try {
      await Axios.get(baseURL + "/sch-categories").then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setCategories(response.data);
        }
      });
    } catch (err) {
      showErrorMsg(err.message);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  // JSX
  return (
    <div>
      <Link className="link" data-toggle="modal" data-target={`#id1`}>
        + Class
      </Link>

      <div className="modal" id={`id1`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-success">ADD CLASS</h4>
              <h6 className="text-danger text-center">
              </h6>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div className="modal-body">
              <label htmlFor="name">Subject Category</label>
              <select
                className="form-control"
                onChange={(event) => {
                  setCategory(event.target.value);
                }}
              >
                <option selected hidden value={null}>
                  --Select Category--
                </option>
                {categories.map((category) => (
                  <option value={category.id} key={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
              <br />
              <label htmlFor="name">Class Name</label>
              <input
                // ref={className}
                onChange={(event) => {
                  setClassName(event.target.value);
                }}
                name="name"
                placeholder="Class Name"
                className="form-control mb-1"
                // type="number"
              />
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger mr-auto"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                id="submit"
                onClick={addKlasses}
                className="text-white btn btn-outline-success ml-auto"
                data-dismiss="modal"
                disabled={!className || !category}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddKlass;
