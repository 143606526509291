import React, { useState, useCallback } from "react";
import Axios from "axios";
import { baseURL } from "../../methods/baseUrl";
import useAuth from "../hooks/useAuth";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import useDocumentTitle from "../hooks/useDocumentTitle";
import * as XLSX from "xlsx"; // Import xlsx library

function IssueUploads({ setAllStudents }) {
  const { auth, setAuth } = useAuth();
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState();
  const [previewData, setPreviewData] = useState(null);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    setPreviewData(null); // Clear previous preview data when a new file is selected
  };

  const handlePreview = async () => {
    if (!selectedFile) {
      showErrorMsg("Please select a file");
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      setPreviewData(jsonData);
    };
    reader.readAsArrayBuffer(selectedFile);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      showErrorMsg("Please select a file");
      return;
    }

    const formData = new FormData();

    formData.append("file", selectedFile);
    formData.append("issuer_id", auth?.id);
    formData.append("school_id", auth?.school_id);

    try {
      const response = await Axios.post(baseURL + "/upload/issues", formData);

      getAllStudents();

      if (response.data.msg) {
        const errorMessages = response.data.msg.split(",");
        setError(
          errorMessages.map((message) => (
            <h6 key={message}>{message.trim()}</h6>
          ))
        );
      } else {
        showSuccessMsg(response.data.success);
        // Add code to preview the uploaded file here
        handlePreview();
      }
    } catch (error) {
      showErrorMsg(error);
    }
  };

  const handleDownload = () => {
    const url = "upload_issues_template.xlsx";
    const fileName = "upload_issues_template";
    saveAs(url, fileName + ".xlsx");
  };

  // Basic definition for getAllStudents, modify based on actual implementation
  const getAllStudents = useCallback(async () => {
    try {
      const response = await Axios.get(
        baseURL + "/students/" + auth?.school_id
      );
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setAllStudents(response.data);
      }
    } catch (error) {
      showErrorMsg(error);
    }
  }, [auth?.school_id, setAllStudents]);

  useDocumentTitle("Issue Uploads");

  return (
    <div className="container m-4 ">
      <h5 className="text-center text-success m-4 p-4">
        IMPORT/UPLOAD BOOK ISSUES
      </h5>
      <div>
        {previewData && (
          <div>
            <h6>Preview:</h6>
            <pre>{JSON.stringify(previewData, null, 2)}</pre>
          </div>
        )}

        <p>
          Download{" "}
          <Link className="link" onClick={() => handleDownload()}>
            this file
          </Link>{" "}
          , fill it and upload back.
        </p>
        <h6 className="text-danger">DO NOT CHANGE THE COLUMN HEADERS</h6>
        <small>book_reg, book_name, student_id, student_name</small>
      </div>
      <hr />
      <input type="file" onChange={handleFileSelect} />
      <Button variant={"outlined"} onClick={handlePreview}>
        Preview
      </Button>
      <Button
        className="float-right"
        variant="contained"
        onClick={handleFileUpload}
        disabled={!selectedFile}
      >
        Upload
      </Button>
      <h6>
        <hr />
        {error}
      </h6>
    </div>
  );
}

export default IssueUploads;

// import React, { useState, useCallback } from "react";
// import Axios from "axios";
// import { baseURL } from "../../methods/baseUrl";
// import useAuth from "../hooks/useAuth";
// import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
// import { saveAs } from "file-saver";
// import { Link } from "react-router-dom";
// import { Button } from "@mui/material";
// import useDocumentTitle from "../hooks/useDocumentTitle";

// function IssueUploads({ setAllStudents }) {
//   const { auth, setAuth } = useAuth();
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [error, setError] = useState();

//   const handleFileSelect = (event) => {
//     setSelectedFile(event.target.files[0]);
//   };

//   const mySchool = auth?.school_id;

//   const getAllStudents = useCallback(async () => {
//     /**
//      * Get all the details of all students in a school
//      * This is to be stored and used everytime an issue is required
//      */
//     try {
//       await Axios.get(baseURL + "/students/" + mySchool).then((response) => {
//         // console.log(response.data);
//         if (response.data.msg) {
//           showErrorMsg(response.data.msg);
//         } else {
//           setAllStudents(response.data);
//           // setAllStudentsCount(response.data.length);
//         }
//       });
//     } catch (error) {
//       showErrorMsg(error);
//     }
//   }, [mySchool, setAllStudents]);

//   const handleFileUpload = async () => {
//     const formData = new FormData();

//     formData.append("file", selectedFile);
//     formData.append("issuer_id", auth?.id);
//     formData.append("school_id", auth?.school_id);

//     await Axios.post(baseURL + "/upload/issues", formData).then((response) => {
//       getAllStudents();

//       if (response.data.msg) {
//         // console.log(response.data.msg);
//         // showErrorMsg(response.data.msg);
//         const errorMessages = response.data.msg.split(",");
//         setError(
//           errorMessages.map((message) => (
//             <h6 key={message}>{message.trim()}</h6>
//           ))
//         );
//       } else {
//         showSuccessMsg(response.data.success);
//       }
//     });
//   };

//   const handleDownload = () => {
//     const url = "upload_issues_template.xlsx";
//     const fileName = "upload_issues_template";
//     saveAs(url, fileName + ".xlsx");
//   };

//   useDocumentTitle("Issue Uploads");

//   return (
//     <div className="container m-4 ">
//       <h5 className="text-center text-success m-4 p-4">
//         IMPORT/UPLOAD BOOK ISSUES
//       </h5>
//       <div>
//         <p>
//           Download{" "}
//           <Link className="link" onClick={() => handleDownload()}>
//             this file
//           </Link>{" "}
//           , fill it and upload back.
//         </p>
//         <h6 className="text-danger">DO NOT CHANGE THE COLUMN HEADERS</h6>
//         <small>book_reg_no, borrower_id, due_days</small>
//       </div>
//       <hr />
//       <Button variant={"outlined"}>
//         <input type="file" onChange={handleFileSelect} />
//       </Button>
//       <Button
//         className="float-right"
//         variant="contained"
//         onClick={handleFileUpload}
//         disabled={!selectedFile}
//       >
//         Upload
//       </Button>
//       <h6>
//         <hr />
//         {error}
//       </h6>
//     </div>
//   );
// }

// export default IssueUploads;
