import React, { useState } from "react";
import { Link } from "react-router-dom";
import { baseURL } from "../../methods/baseUrl";
import Axios from "axios";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
import { BiPencil, BiXCircle, BiCheckCircle, BiTrash } from "react-icons/bi";

const EditSchool = ({ school, getSchools }) => {
  const [name, setName] = useState(school.name);
  const [installed, setInstalled] = useState(school.installed);
  const [subscribed, setSubscribed] = useState(school.subscribed);
  const [box, setBox] = useState(school.box);
  const [town, setTown] = useState(school.town);

  const [invoice_date, setInvoiceDate] = useState(
    new Date(school.invoice_date).toISOString().slice(0, 10)
  );

  const editSchool = async (id) => {
    try {
      const body = {
        name,
        installed,
        subscribed,
        invoice_date,
        box,
        town,
      };
      await Axios.put(baseURL + `/update/school/${id}`, body).then(
        (response) => {
          if (response.data.msg) {
            showErrorMsg(response.data.msg);
          } else {
            getSchools(); //Re-populate the schools list upon successful edit
            showSuccessMsg(response.data.success);
          }
        }
      );
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  //JSX
  return (
    <div>
      <Link
        className="link"
        data-toggle="modal"
        data-target={`#id${school.id}`}
      >
        <BiPencil size={16} />
      </Link>

      <div className="mt-4 pt-4 modal" id={`id${school.id}`}>
        {" "}
        <div className="modal-dialog">
          <div className="modal-content">
            {" "}
            <div className="modal-header">
              <h4 className="modal-title text-success">{school.name}</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <label htmlFor="name">School Name</label>
              <input
                value={name}
                name="name"
                className="form-control mb-1"
                onChange={(e) => setName(e.target.value)}
              />
              <label htmlFor="installed"> Installed</label>
              <select
                value={installed}
                name="installed"
                className="form-control"
                onChange={(e) => setInstalled(e.target.value)}
              >
                <option value={true}>Installed</option>
                <option value={false}>Not Installed</option>
              </select>
              <label htmlFor="subscribed"> Installed</label>
              <select
                value={subscribed}
                name="subscribed"
                className="form-control"
                onChange={(e) => setSubscribed(e.target.value)}
              >
                <option value={true}>Subscribed</option>
                <option value={false}>Not Subscribed</option>
              </select>
              <label htmlFor="box">Box</label>
              <input
                className="form-control"
                name="box"
                value={box}
                type={"text"}
                onChange={(e) => setBox(e.target.value)}
              />
              <label htmlFor="town">Town</label>
              <input
                className="form-control"
                name="town"
                value={town}
                type={"text"}
                onChange={(e) => setTown(e.target.value)}
              />
              {/* <label htmlFor="invoice_date">Invoice Date</label>
              <input
                className="form-control"
                name="invoice_date"
                value={invoice_date}
                type={"date"}
                onChange={(e) => setInvoiceDate(e.target.value)}
              /> */}
              <label htmlFor="invoice_date">Invoice Date</label>
              <input
                className="form-control"
                name="invoice_date"
                value={invoice_date}
                type={"date"}
                onChange={(e) => setInvoiceDate(e.target.value)}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger mr-auto"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  editSchool(school.id);
                }}
                id="submit"
                className="text-white btn btn-outline-success ml-auto"
                data-dismiss="modal"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditSchool;
