import React, { useState, useEffect, useCallback } from "react";
import { showErrorMsg, showSuccessMsg } from "../methods/alertMsgs";
import useAuth from "./hooks/useAuth";
import Axios from "axios";
import { TailSpin } from "react-loader-spinner";
import { Toolbar } from "@mui/material";
import { baseURL } from "../methods/baseUrl";

export const MoveStudents = ({ getAllStudents }) => {
  const { auth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [klasses, setKlasses] = useState([]);
  const sch_category = auth?.sch_category;
  const [originKlass, setOriginKlass] = useState(null);
  const [destinationKlass, setDestinationKlass] = useState(null);

  // Functions
  const filterKlasses = (klasses, originKlass) => {
    return klasses.filter((klass) => klass.id !== parseInt(originKlass));
  };

  const moveStudents = async () => {
    /**
     * Move students to the next class
     */
    try {
      const body = { originKlass, destinationKlass };

      await Axios.post(baseURL + "/students/move", body)
        .then((response) => {
          if (response.data.msg) {
            showErrorMsg(response.data.msg);
          } else {
            setTimeout(() => showSuccessMsg(response.data.success), 2000);
            setTimeout(() => setLoading(false), 2000);
            getAllStudents();
          }
        })
        .catch((error) => {
          showErrorMsg(error.message);
        });
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  const getKlasses = useCallback(async () => {
    try {
      await Axios.get(baseURL + `/klasses/${sch_category}`).then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setKlasses(response.data);
        }
      });
    } catch (error) {
      showErrorMsg(error);
    }
  }, [sch_category]);

  useEffect(() => {
    getKlasses();
  }, [getKlasses]);

  return (
    <div className="container modal" id="move">
      <Toolbar />
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header text-center text-success m-3">
            <h4 className="modal-title">MOVE STUDENTS</h4>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          {/* The form */}
          <div className=" modal-body text-center m-4" id="mySelect">
            <div className="d-flex">
              <select
                className="w-40 m-1 form-control"
                disabled={loading}
                onChange={(e) => {
                  setOriginKlass(e.target.value);
                }}
              >
                <option value="" hidden defaultValue>
                  --Origin Class--
                </option>

                {klasses.map((klass) => {
                  if (klass.name === "All Forms") {
                    return null;
                  }
                  if (klass.name === "F1&2") {
                    return null;
                  }
                  if (klass.name === "F3&4") {
                    return null;
                  }
                  return (
                    <option key={klass.id} value={klass.id}>
                      {klass.name}
                    </option>
                  );
                })}
              </select>
              <br />
              <select
                disabled={!originKlass}
                className="w-40 m-1 form-control"
                onChange={(e) => {
                  setDestinationKlass(e.target.value);
                }}
              >
                <option value="" hidden defaultValue>
                  --Destination Class--
                </option>

                {filterKlasses(klasses, originKlass).map((klass) => {
                  if (klass.name === "All Forms") {
                    return null;
                  }
                  if (klass.name === "F1&2") {
                    return null;
                  }
                  if (klass.name === "F3&4") {
                    return null;
                  }
                  return (
                    <option key={klass.id} value={klass.id}>
                      {klass.name}
                    </option>
                  );
                  // return null; // Skip the "All Forms" class
                })}
              </select>
            </div>
            <br />
            <br />
            <br />
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger mr-auto"
                data-dismiss="modal"
              >
                Close
              </button>
              <input
                onClick={() => {
                  moveStudents();
                  setLoading(true);
                }}
                disabled={loading || !originKlass || !destinationKlass}
                type="submit"
                value="Move"
                id="submit"
                className="text-white btn btn-outline-success ml-auto"
              />
            </div>
          </div>

          {loading && (
            <TailSpin
              height="50"
              width="50"
              color="rgb(183, 71, 221)" //"#4fa94d"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{
                top: "50%",
                left: "50%",
                position: "relative",
              }}
              wrapperClass=""
              visible={true}
            />
          )}
          <br />
        </div>
      </div>
    </div>
  );
};
