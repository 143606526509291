import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { baseURL } from "../../methods/baseUrl";
import { LinearProgress } from "@mui/material";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
import useAuth from "../hooks/useAuth";
import { BiPencil, BiXCircle, BiCheckCircle, BiTrash } from "react-icons/bi";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { Typography } from "@mui/material";
import swal from "sweetalert";
import useDocumentTitle from "../hooks/useDocumentTitle";

const About = () => {
  const [about, setAbout] = useState([]);
  const [loading, setLoading] = useState(true);
  const [aboutData, setAboutData] = useState(null);
  const { auth } = useAuth();

  const getAbout = async () => {
    try {
      const response = await Axios.get(baseURL + "/about");
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setLoading(false);
        setAbout(response.data);
      }
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  const editAbout = async (id, values) => {
    const { title, body } = values;
    try {
      const response = await Axios.put(baseURL + `/update/about/${id}`, {
        title,
        body,
      });
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setAboutData(null);
        getAbout();
        showSuccessMsg(response.data.success);
      }
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  const setMyAboutData = (data) => {
    setAboutData(data);
  };

  const deleteAbout = async (id) => {
    const confirmDelete = await swal({
      title: "Confirm to delete",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (confirmDelete) {
      try {
        const response = await Axios.delete(baseURL + `/delete/about/${id}`);
        if (response.data.msg) showErrorMsg(response.data.msg);
        else {
          getAbout();
          showSuccessMsg(response.data.success);
        }
      } catch (error) {
        showErrorMsg(error.message);
      }
    }
  };

  const addAbout = async () => {
    try {
      const title = "title";
      const body = "body";
      await Axios.post(baseURL + "/add/about", { title, body }).then(
        (response) => {
          if (response.data.msg) {
            showErrorMsg(response.data.msg);
          } else {
            getAbout();
            showSuccessMsg(response.data.success);
          }
        }
      );
    } catch (error) {
      showErrorMsg(error.message);
    }
  };
  useDocumentTitle("About");

  useEffect(() => {
    getAbout();
  }, []);

  return (
    <div className="container bg-white  p-4">
      {auth?.is_superuser ? (
        <>
          <h4
            style={{ fontFamily: "NovaOval" }}
            className="text-success text-center m-3"
          >
            ABOUT LLR{" - "}
            <Link className="link" onClick={addAbout}>
              Add
            </Link>
          </h4>
          <hr />
          {loading ? (
            <LinearProgress className="link" />
          ) : (
            <Formik
              enableReinitialize
              initialValues={{
                title: aboutData ? aboutData.title : "",
                body: aboutData ? aboutData.body : "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                editAbout(aboutData.id, values)
                  .then(() => setSubmitting(false))
                  .catch(() => setSubmitting(false));
              }}
            >
              {({ values, isSubmitting }) => (
                <Form>
                  {about.map((aboutItem) => (
                    <div key={aboutItem.id}>
                      {!aboutData ||
                      (aboutData && aboutData.id !== aboutItem.id) ? (
                        <>
                          <h4 style={{ fontFamily: "Chakra" }}>
                            {aboutItem.title} [
                            <Link
                              className="link"
                              onClick={() => {
                                setMyAboutData(aboutItem);
                              }}
                            >
                              <BiPencil size={18} />
                            </Link>
                            {aboutItem.id !== 1 ? (
                              <>
                                {" | "}{" "}
                                <Link
                                  className="link"
                                  onClick={() => {
                                    deleteAbout(aboutItem?.id);
                                  }}
                                >
                                  <BiTrash size={20} className="text" />
                                </Link>
                              </>
                            ) : (
                              ""
                            )}
                            ]
                          </h4>
                          <Typography
                            dangerouslySetInnerHTML={{ __html: aboutItem.body }}
                            variant="body1"
                            fontFamily="Play"
                          ></Typography>
                        </>
                      ) : (
                        <>
                          <h4 style={{ fontFamily: "Chakra" }}>
                            <small>
                              <Link
                                className="link float-right"
                                onClick={() => setAboutData(null)}
                              >
                                <BiXCircle className="text-danger" size={24} />
                              </Link>
                              <button
                                type="submit"
                                className=" mr-3 float-right"
                                disabled={isSubmitting}
                              >
                                <BiCheckCircle
                                  className="text-success"
                                  size={24}
                                />
                              </button>
                              <br />
                              <Field
                                component={TextField}
                                name="title"
                                label="Title"
                                fullWidth
                              />
                              <Field
                                className="mt-3"
                                component={TextField}
                                name="body"
                                label="Body"
                                multiline
                                rows={4}
                                fullWidth
                              />
                            </small>
                          </h4>
                        </>
                      )}{" "}
                      <hr />
                    </div>
                  ))}
                </Form>
              )}
            </Formik>
          )}
        </>
      ) : (
        <h3 className="text-center text-success m-4 p-4">
          Unauthorised Access
        </h3>
      )}
      {/* comment */}
    </div>
  );
};

export default About;
