import React, { useState, useEffect } from "react";
import { baseURL } from "../../methods/baseUrl";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
import Axios from "axios";
import { Link } from "react-router-dom";

const Subject = () => {
  const [subjectName, setSubjectName] = useState("");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(null);

  const addSubject = async () => {
    try {
      const subject = subjectName;
      const sch_category = category;

      if (!subject) {
        throw new Error("Name missing");
      }
      if (!sch_category) {
        throw new Error("No category is selected");
      }
      const body = { subject, sch_category };
      await Axios.post(baseURL + "/add/subject", body).then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          //   getAllSubjects();
          showSuccessMsg(response.data.success);
        }
      });
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  const getCategories = async () => {
    await Axios.get(baseURL + "/sch-categories").then((response) => {
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setCategories(response.data);
      }
    });
  };
  useEffect(() => {
    getCategories();
  }, []);
  //JSX
  return (
    <div>
      <Link className="link" data-toggle="modal" data-target={"#addSubject"}>
        + Subject
      </Link>

      <div className="modal" id={"addSubject"}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-success">ADD SUBJECT</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div className="modal-body">
              <label htmlFor="name">Subject Category</label>
              <select
                className="form-control"
                onChange={(event) => {
                  setCategory(event.target.value);
                }}
              >
                <option selected hidden value={null}>
                  --Select Category--
                </option>
                {categories.map((category) => (
                  <option value={category.id} key={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
              <br />
              <label htmlFor="name">Subject Name</label>
              <input
                onChange={(event) => {
                  setSubjectName(event.target.value);
                }}
                name="name"
                placeholder=" Name"
                className="form-control mb-1"
                // type="number"
              />
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger mr-auto"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                id="submit"
                onClick={() => addSubject()}
                className="text-white btn btn-outline-success ml-auto"
                data-dismiss="modal"
                disabled={!subjectName || !category}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Subject;
