import React, { useEffect, useState } from "react";
import AddSubject from "../crud/AddSubject";
import { baseURL } from "../../methods/baseUrl";
import Axios from "axios";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
import { Link } from "react-router-dom";
import { BiPencil, BiXCircle, BiCheckCircle, BiTrash } from "react-icons/bi";
import swal from "sweetalert";

const Subjects = () => {
  const [subjects, setSubjects] = useState([]);
  const [subjectData, setSubjectData] = useState(null);

  const setMySubjectData = (data) => {
    setSubjectData(data);
  };
  const getSubjects = async () => {
    try {
      const response = await Axios.get(baseURL + "/subjects");
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setSubjects(response.data);
      }
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  const updateSubject = async (id) => {
    try {
      const name = subjectData.name;
      const body = { name };
      const response = await Axios.put(baseURL + `/update/subject/${id}`, body);
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        showSuccessMsg(response.data.success);
        setMySubjectData(null);
        getSubjects();
      }
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  const deleteSubject = async (id) => {
    const confirmDelete = await swal({
      title: "Confirm to delete",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (confirmDelete) {
      try {
        const response = await Axios.delete(baseURL + `/delete/subject/${id}`);
        if (response.data.msg) showErrorMsg(response.data.msg);
        else {
          getSubjects();
          showSuccessMsg(response.data.success);
        }
      } catch (error) {
        showErrorMsg(error.message);
      }
    }
  };

  useEffect(() => {
    document.title = "LLR | Subjects";
    getSubjects();
  }, []);

  const uniqueCategories = [
    ...new Set(subjects.map((subject) => subject.category)),
  ];
  const categoryCounts = {};
  subjects.forEach((subject) => {
    categoryCounts[subject.category] =
      (categoryCounts[subject.category] || 0) + 1;
  });

  return (
    <div className="container">
      <div className="row align-items-center justify-content-between mb-4 mt-4 category-table">
        <div className="col">
          {" "}
          <h4 className="text-success text-center">ALL SUBJECTS</h4>
        </div>
        <div className="col-auto text-center">
          <AddSubject />
        </div>
      </div>
      {uniqueCategories.map((category) => (
        <div key={category} className="category-table">
          <h5 className="category-title">
            {category} ({categoryCounts[category]})
          </h5>
          <table className="layout">
            <thead>
              <tr>
                <th>Subject ID</th>
                <th>Subject Name</th>
                <th>No of Books</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {subjects
                .filter((subject) => subject.category === category)
                .map((subject) => (
                  <tr key={subject.id}>
                    <td>{subject.id}</td>
                    {subjectData && subjectData.id === subject.id ? (
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={subjectData.name}
                          onChange={(e) =>
                            setSubjectData({
                              ...subjectData,
                              name: e.target.value,
                            })
                          }
                        />
                      </td>
                    ) : (
                      <td>{subject.name}</td>
                    )}
                    <td>{subject.count}</td>{" "}
                    <td>
                      {subjectData && subjectData.id === subject.id ? (
                        <small>
                          <Link
                            className="link btn btn-primry btn-sm"
                            onClick={() => {
                              updateSubject(subject.id);
                            }}
                          >
                            <BiCheckCircle /> Save
                          </Link>
                          <Link
                            className="link btn btn-primry btn-sm"
                            onClick={() => setMySubjectData(null)}
                          >
                            <BiXCircle /> Cancel
                          </Link>
                        </small>
                      ) : (
                        <Link
                          className=" link "
                          title={`Edit ${subject.name}`}
                          onClick={() => {
                            setMySubjectData(subject);
                          }}
                        >
                          <BiPencil />
                        </Link>
                      )}
                    </td>
                    <td>
                      {subject.count > 0 ? (
                        <small>-</small>
                      ) : (
                        <BiTrash
                          onClick={() => deleteSubject(subject.id)}
                          className="link"
                          size={18}
                        />
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default Subjects;
