import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
import baseURL from "../../methods/baseUrl";

const Mpesa = () => {
  const [phone, setPhone] = useState("");
  const [amount, setAmount] = useState("10"); 

  const getPayDetails = async () => {
    const body = { phone, amount };
    await axios
      .post("http://127.0.0.1:8080/mpesa/pay/till", body)
      .then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          showSuccessMsg(response.data.success);
        }
      })
      .catch((err) => {
        showErrorMsg(err.message);
      });
  };

  return (
    <div className="container m-auto w-50">
      <h3 className="text-success mt-4 text-center">THANK YOU</h3>
      <div>
        Phone Number:{" "}
        <input
          onChange={(e) => setPhone(e.target.value)}
          className="form-control"
          type="text"
        />
        <br />
        Amount :{" "}
        <input
          onChange={(e) => setAmount(e.target.value)}
          className="form-control"
          type="text"
        />
        <br />
        <Link className="link btn btn-primary" to={"/"}>
          Back
        </Link>
        <button onClick={getPayDetails} className="float-right btn btn-success">
          Donate
        </button>
        <br />
      </div>
    </div>
  );
};

export default Mpesa;
