import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import Axios from "axios";
import { initializeApp } from "firebase/app";
import { baseURL } from "../methods/baseUrl";
import axios from "axios";
import useAuth from "./hooks/useAuth";
import { showErrorMsg, showSuccessMsg } from "../methods/alertMsgs";
import logo from "../components/partials/logo/logo-colored.gif";
import { Formik, Field, Form } from "formik";
import { useInView } from "react-intersection-observer";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { firebaseConfig } from "../methods/firebaseConfig";
import useDocumentTitle from "./hooks/useDocumentTitle";
import {
  TextField,
  Button,
  LinearProgress,
  Typography,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import {
  FaPhone,
  FaEnvelope,
  FaWhatsapp,
  FaTwitter,
  FaLinkedin,
  FaTelegram,
  FaGithub,
} from "react-icons/fa";
const theme = createTheme({});
initializeApp(firebaseConfig);

const Landing = () => {
  const navigate = useNavigate();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const loc = location?.state?.from?.pathname;
  const [isInView, setIsInView] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const { auth, setAuth } = useAuth();
  const [about, setAbout] = useState([]);
  const [contactInfo, setContactInfo] = useState([]);
  const [appInfo, setAppInfo] = useState([]);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [password, setPassword] = useState(null);
  const [clicked, setClicked] = useState(false);
  const app = initializeApp(firebaseConfig);
  const auth0 = getAuth(app);
  const [username, setUsername] = useState(null);
  const [userEmail, setUserEmail] = useState(null);

  const getAbout = async () => {
    try {
      setLoading(true);
      const response = await Axios.get(baseURL + "/about");
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setAbout(response.data);
      }
    } catch (error) {
      showErrorMsg(error.message);
    } finally {
      setLoading(false);
      // setPageLoading(false);
    }
  };

  const getContactInfo = async () => {
    try {
      setLoading(true);
      const response = await Axios.get(baseURL + "/contactinfo");
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setContactInfo(response.data);
      }
    } catch (error) {
      showErrorMsg(error.message);
    } finally {
      setLoading(false);
      // setPageLoading(false);
    }
  };

  const getAppInfo = async () => {
    try {
      setLoading(true);
      const response = await Axios.get(baseURL + "/appinfo");
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        // setLoading(false);
        setAppInfo(response.data);
      }
    } catch (error) {
      showErrorMsg(error.message);
    } finally {
      setLoading(false);
      setPageLoading(false);
    }
  };

  const logUser = async () => {
    try {
      setLoading(true);
      setClicked(true);
      const userCredential = await signInWithEmailAndPassword(
        auth0,
        userEmail,
        password
      );
      const idToken = await userCredential.user.getIdToken();
      const response = await axios.post(
        baseURL + "/login/user",
        {
          // Objects to be passed along
        },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
      // console.log("response");
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
        setClicked(false);
      } else {
        setAuth(response.data);
        if (response.data.is_superuser) {
          navigate("/admin/users", { replace: true });
        } else {
          if (response.data.subscribed) {
            navigate("/home", { replace: true });
          } else {
            navigate("/unsubscribed");
          }
        }
      }
    } catch (error) {
      if (error.code === "auth/network-request-failed") {
        showErrorMsg("Connection Error");
      } else if (error.code === "auth/invalid-login-credentials") {
        showErrorMsg("Invalid Credentials");
      } else {
        showErrorMsg(error.message);
      }
    } finally {
      setLoading(false);
      setClicked(false);
    }
  };

  const headingStyle = { fontFamily: "Chakra" };

  const itemStyle = {
    borderTopRightRadius: "50px",
    borderBottomLeftRadius: "50px",
    width: "100%",
    fontFamily: "Exo",
  };

  const bgImage = {
    backgroundPosition: isMobileScreen ? "30% 0%" : "center",
  };
  const style = {
    width: isMobileScreen ? "98%" : "40%",
    borderTopRightRadius: "50px",
    borderBottomLeftRadius: "50px",
    backgroundColor: "white",
  };

  useDocumentTitle("Welcome");

  const initialValues = {
    name: "",
    message: "",
  };

  const submitUserResponse = async (values, { resetForm }) => {
    try {
      setLoading(true);
      const response = await Axios.post(baseURL + "/add/feedback", values);
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        showSuccessMsg("Response Submitted");
        resetForm();
      }
    } catch (error) {
      showErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  };

  const setLoginPanelView = () => {
    if (auth?.id) {
      navigate("/home", { replace: true });
    } else {
      setShowLoginForm(!showLoginForm);
    }
  };

  const handleUserEmailRetrieval = async () => {
    try {
      setLoading(true);
      setClicked(true);
      const getEmail = await axios.get(baseURL + `/user/data/${username}`);
      if (getEmail.data.msg) {
        showErrorMsg(getEmail.data.msg);
      } else {
        setUserEmail(getEmail.data);
      }
    } catch (error) {
      showErrorMsg(error.message);
    } finally {
      setLoading(false);
      setClicked(false);
    }
  };

  const goToReset = () => {
    /**
     * For the button that leads to user Reset Password
     */
    try {
      navigate("/reset-password");
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  useEffect(() => {
    getAbout();
    getContactInfo();
    getAppInfo();
  }, []);

  useEffect(() => {
    if (inView) {
      setIsInView(true);
    }
  }, [inView]);

  return (
    <div style={{ minHeight: "100vh" }}>
      {pageLoading ? (
        <>
          <LinearProgress className="link" />
          <LinearProgress className="link" />
        </>
      ) : (
        <div>
          <div style={bgImage} id="main-landing-page">
            <div className="container link">
              <br />
              <br />
              {isMobileScreen ? (
                <div className="ml-auto text-dark ">
                  <h5
                    style={{ fontFamily: "Chakra" }}
                    id="llr-text"
                    className="ml-auto mt-4 pt-4"
                  >
                    <div>{appInfo[0]?.title}</div>
                  </h5>
                  <h6
                    style={{ fontFamily: "Lobster" }}
                    className="ml-auto text-dark pt-4 pb-4"
                  >
                    <div>{appInfo[0]?.motto}</div>
                  </h6>
                  {/* <Link
                      style={{ fontSize: "17px", fontFamily: "Chakra" }}
                      className="ml-auto link"
                      variant="text"
                      to="/donate"
                      // onClick={setLoginPanelView}
                    >
                      donate
                    </Link> */}
                  <br />
                </div>
              ) : (
                <>
                  <h2
                    style={{ fontFamily: "Chakra" }}
                    id="llr-text"
                    className="ml-auto mt-4 pt-4"
                  >
                    <div>{appInfo[0]?.title}</div>
                  </h2>
                  <h4
                    style={{ fontFamily: "Lobster" }}
                    className="ml-auto text-dark pt-4 pb-4"
                  >
                    <div>{appInfo[0]?.motto}</div>
                  </h4>
                  {/* <Link
                    style={{ fontSize: "17px", fontFamily: "Chakra" }}
                    className="ml-auto link"
                    variant="text"
                    to="/donate"
                    // onClick={setLoginPanelView}
                  >
                    DONATE
                  </Link> */}
                  {/* <br /> */}
                </>
              )}
              <br />
              {showLoginForm ? (
                <div
                  // className="bg-white p-4"
                  style={style}
                  className={`p-4 m-${isMobileScreen ? "auto" : "ml-2"} `}
                >
                  {/* Get the user details attached to a username */}
                  <TextField
                    fullWidth
                    type="username"
                    label="Username"
                    placeholder="Enter Your Username"
                    variant="standard"
                    margin="normal"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    disabled={userEmail}
                    InputLabelProps={{
                      style: { fontWeight: "bold" },
                      shrink: true,
                    }}
                  />
                  {!userEmail && (
                    <>
                      {" "}
                      <Button
                        onClick={handleUserEmailRetrieval}
                        // id="submit"
                        id={`${username && "submit"}`}
                        className={
                          "text-white form-control mt-2 text-capitalize"
                        }
                        // type="submit"
                        disabled={!username || clicked || loading}
                      >
                        {clicked ? (
                          <strong>
                            <i>Please Wait...</i>
                          </strong>
                        ) : (
                          <>Verify</>
                        )}
                      </Button>
                    </>
                  )}
                  {userEmail && (
                    <>
                      <TextField
                        fullWidth
                        label="Password"
                        // className="mb-3"
                        placeholder="Enter Your Password"
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        variant="standard"
                        margin="normal"
                        InputLabelProps={{
                          style: { fontWeight: "bold" },
                          shrink: true,
                        }}
                      />
                      <Button
                        onClick={() => {
                          logUser();
                        }}
                        id={`${password && "submit"}`}
                        className={
                          " text-capitalize text-white form-control mt-2"
                        }
                        // type="submit"
                        disabled={!userEmail || !password || clicked}
                      >
                        {clicked ? (
                          <strong>
                            <i>Please Wait...</i>
                          </strong>
                        ) : (
                          <>Login</>
                        )}
                      </Button>
                      <br />
                      <div className="mt-4">
                        {/* <hr /> */}
                        <Button
                          disabled={loading}
                          style={{ color: "rgb(173, 83, 202)" }}
                          variant="text"
                          title={"Reset your password through this link"}
                          onClick={goToReset}
                          className="text-capitalize text-center"
                        >
                          {isMobileScreen ? "Reset" : "Reset Password"}
                        </Button>
                        <Button
                          disabled={loading}
                          style={{ color: "rgb(173, 83, 202)" }}
                          onClick={() => {
                            setUserEmail(null);
                          }}
                          className="text-capitalize  text-center float-right"
                        >
                          {isMobileScreen ? "Change" : "Change User"}
                        </Button>
                      </div>
                    </>
                  )}

                  {/* End of username check */}
                </div>
              ) : (
                <Link
                  style={{ fontSize: "17px", fontFamily: "Chakra" }}
                  className="ml-auto link"
                  variant="text"
                  onClick={setLoginPanelView}
                >
                  LOGIN
                </Link>
              )}
            </div>
          </div>

          <div
            className={"p-2 m-2"}
            style={{
              border: isMobileScreen ? "none" : "2px solid grey",
              borderTopRightRadius: "50px",
              borderBottomLeftRadius: "50px",
            }}
          >
            <div
              id="about"
              className="container bg-white m-auto"
              style={{
                borderTopRightRadius: "50px",
                borderBottomLeftRadius: "50px",
                width: "100%",
                fontFamily: "Exo",
              }}
            >
              <Toolbar />
              <h4 style={{ fontFamily: "Chakra" }} className="text-center m-2">
                <img
                  id="landed"
                  className={`${isInView ? "fadeIn" : ""}`}
                  ref={ref}
                  src={logo}
                  height={"180px"}
                  width={"230px"}
                  alt="logo"
                />
              </h4>
              <hr />
              <div className="p-3 m-1" style={{ fontFamily: "Exo" }}>
                {about.map((aboutItem) => {
                  if (aboutItem.id === 1) {
                    return (
                      <Typography
                        key={aboutItem.id}
                        style={{ fontFamily: "Exo" }}
                        dangerouslySetInnerHTML={{ __html: aboutItem.body }}
                        variant="body1"
                      ></Typography>
                    );
                  }
                  return null;
                })}
              </div>
              {about.map((aboutItem) => {
                if (aboutItem.id === 1) {
                  return null;
                }
                return (
                  <div key={aboutItem.id} className="p-3 m-1">
                    <hr />
                    <h4
                      style={{ fontFamily: "Play" }}
                      className="text-success  pb-2"
                    >
                      {aboutItem.title}
                    </h4>
                    <Typography
                      style={{ fontFamily: "Exo" }}
                      dangerouslySetInnerHTML={{ __html: aboutItem.body }}
                      variant="body1"
                    ></Typography>
                  </div>
                );
              })}
            </div>
            <div
              id="contact"
              className="container bg-white m-auto mt-3"
              style={itemStyle}
            >
              <Toolbar />
              <h4 style={headingStyle} className="text-center m-2">
                CONTACT US
              </h4>
              <hr />
              <div>
                {contactInfo.map((info) => {
                  return (
                    <div key={info.id} className="p-3 link text-center mt-4">
                      {info.phone && (
                        <Link
                          title={info.phone}
                          to={info.phone}
                          className="link mr-4"
                        >
                          <FaPhone size={24} />
                        </Link>
                      )}
                      {info.email && (
                        <Link
                          title={info.email}
                          to={info.email}
                          className="link mr-4"
                        >
                          <FaEnvelope size={24} />
                        </Link>
                      )}
                      {info.whatsapp && (
                        <Link
                          title={info.whatsapp}
                          to={info.whatsapp}
                          className="link mr-4"
                        >
                          <FaWhatsapp size={24} />
                        </Link>
                      )}
                      {info.twitter && (
                        <Link
                          title={info.twitter}
                          to={info.twitter}
                          className="link mr-4"
                        >
                          <FaTwitter size={24} />
                        </Link>
                      )}
                      {info.linkedin && (
                        <Link
                          title={info.linkedin}
                          to={info.linkedin}
                          className="link mr-4"
                        >
                          <FaLinkedin size={24} />
                        </Link>
                      )}
                      {info.telegram && (
                        <Link
                          title={info.telegram}
                          to={info.telegram}
                          className="link mr-4"
                        >
                          <FaTelegram size={24} />
                        </Link>
                      )}
                      {info.github && (
                        <Link
                          title={info.github}
                          to={info.github}
                          className="link mr-4"
                        >
                          <FaGithub size={24} />
                        </Link>
                      )}
                    </div>
                  );
                })}
                <br />
                <br />
                <div>
                  <Formik
                    initialValues={initialValues}
                    onSubmit={submitUserResponse}
                  >
                    {({ isValid, handleChange }) => (
                      <Form>
                        <div>
                          <Field
                            component={TextField}
                            className="mb-3"
                            type="text"
                            name="name"
                            label="Name"
                            onChange={handleChange}
                            placeholder="Anonymous"
                            fullWidth
                            required
                          />
                        </div>
                        <br />

                        <div>
                          <Field
                            component={TextField}
                            className="mb-3"
                            type="text"
                            name="message"
                            label="Message"
                            multiline
                            placeholder="Type your message here"
                            rows={6}
                            fullWidth
                            required
                            onChange={handleChange}
                          />
                        </div>

                        <Button
                          className="form-control link"
                          type="submit"
                          variant="contained"
                          disabled={!isValid || loading}
                        >
                          Submit
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </div>
                <br />
              </div>
              <br />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Landing;
