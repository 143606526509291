import React, { useEffect, useState, memo } from "react";
import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { firebaseConfig } from "../methods/firebaseConfig";
import useAuth from "./hooks/useAuth";
import { baseURL } from "../methods/baseUrl";
import { showErrorMsg } from "../methods/alertMsgs";
import { initializeApp } from "firebase/app";
import { TailSpin } from "react-loader-spinner";
import { checkUserAuthState } from "../methods/firebaseHandlers";

const RequireAuth = memo(() => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const location = useLocation();
  const previous = location?.state?.from?.pathname;

  const app = initializeApp(firebaseConfig);
  const auth0 = getAuth(app);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("Refreshing");
    document.title = loading && "LLR | Please Wait";
    const checkUserAuthentication = async () => {
      const unsubscribe = onAuthStateChanged(auth0, async (user) => {
        if (user) {
          try {
            const idToken = await user.getIdToken();
            unsubscribe();

            const response = await axios.get(baseURL + "/refresh", {
              headers: { Authorization: `Bearer ${idToken}` },
            });

            if (response.data.msg) {
              showErrorMsg(response.data.msg);
              navigate("/");
            } else {
              setAuth(response.data);
              navigate(previous, { replace: true });
            }
          } catch (error) {
            showErrorMsg(error.message);
          } finally {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      });
    };

    checkUserAuthentication();
  }, [auth0, previous, setAuth]);

  if (loading) {
    return (
      <div>
        <TailSpin
          height="80"
          width="80"
          color="rgb(183, 71, 221)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            top: "50%",
            left: "50%",
            position: "absolute",
            transform: "translate(-50%, -50%)",
          }}
          wrapperClass=""
          strokeWidth={1}
          visible={true}
        />
      </div>
    );
  }

  return auth?.id ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
});

export default RequireAuth;
