import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { baseURL } from "../../methods/baseUrl";
import { Button, LinearProgress } from "@mui/material";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
import useAuth from "../hooks/useAuth";
import { BiPencil, BiXCircle, BiCheckCircle, BiTrash } from "react-icons/bi";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { Typography } from "@mui/material";
import swal from "sweetalert";

const Feedback = () => {
  const [feedback, setFeedback] = useState([]);
  const [loading, setLoading] = useState(true);
  const [feedbackData, setFeedbackData] = useState(null);
  const { auth } = useAuth();

  const getFeedback = async () => {
    try {
      const response = await Axios.get(baseURL + "/feedback");
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setLoading(false);
        setFeedback(response.data);
      }
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  const editFeedback = async (id, values) => {
    const { name, message } = values;
    try {
      const response = await Axios.put(baseURL + `/update/feedback/${id}`, {
        name,
        message,
      });
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setFeedbackData(null);
        getFeedback();
        showSuccessMsg(response.data.success);
      }
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  const setMyFeedbackData = (data) => {
    setFeedbackData(data);
  };

  // const deleteFeedback = async (id) => {
  //   try {
  //     await Axios.delete(baseURL + `/delete/feedback/${id}`).then(
  //       (response) => {
  //         if (response.data.msg) {
  //           showErrorMsg(response.data.msg);
  //         } else {
  //           getFeedback();
  //           showSuccessMsg(response.data.success);
  //         }
  //       }
  //     );
  //   } catch (error) {
  //     showErrorMsg(error.message);
  //   }
  // };

  const deleteFeedback = async (id) => {
    const confirmDelete = await swal({
      title: "Confirm to delete",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (confirmDelete) {
      try {
        const response = await Axios.delete(baseURL + `/delete/feedback/${id}`);
        if (response.data.msg) showErrorMsg(response.data.msg);
        else {
          getFeedback();
          showSuccessMsg(response.data.success);
        }
      } catch (error) {
        showErrorMsg(error.message);
      }
    }
  };

  useEffect(() => {
    document.title = "LLR | Feedback";
    getFeedback();
  }, []);

  return (
    <div className="container bg-white  p-4">
      {auth?.is_superuser ? (
        <>
          <h4
            style={{ fontFamily: "NovaOval" }}
            className="text-success text-center m-3"
          >
            FEEDBACK
          </h4>
          <hr />
          {loading ? (
            <LinearProgress className="link" />
          ) : (
            <Formik
              enableReinitialize
              initialValues={{
                name: feedbackData ? feedbackData.name : "",
                message: feedbackData ? feedbackData.message : "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                editFeedback(feedbackData.id, values)
                  .then(() => setSubmitting(false))
                  .catch(() => setSubmitting(false));
              }}
            >
              {({ values, isSubmitting }) => (
                <Form>
                  {feedback.map((info) => (
                    <div key={info.id}>
                      {!feedbackData ||
                      (feedbackData && feedbackData.id !== info.id) ? (
                        <>
                          <h4 style={{ fontFamily: "NovaOval" }}>
                            {info.name}
                            {" - "}
                            <small style={{ fontFamily: "NovaOval" }}>
                              {new Date(info.created_at).toLocaleString()}
                            </small>
                            <Link
                              title="Delete Feedback"
                              className="link float-right"
                              onClick={() => {
                                deleteFeedback(info.id);
                              }}
                            >
                              <BiTrash size={18} />
                            </Link>

                            <Link
                              TITLE="Edit Feedback"
                              className="link float-right mr-2"
                              onClick={() => {
                                setMyFeedbackData(info);
                              }}
                            >
                              <BiPencil size={18} />
                            </Link>
                          </h4>
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: info.message,
                            }}
                            variant="h4"
                            fontFamily="Play"
                          ></Typography>
                        </>
                      ) : (
                        <>
                          <h4 style={{ fontFamily: "Chakra" }}>
                            <small>
                              <Link
                                className="link float-right"
                                onClick={() => setFeedbackData(null)}
                              >
                                <BiXCircle className="text-danger" size={24} />
                              </Link>
                              <button
                                type="submit"
                                className=" mr-3 float-right"
                                disabled={isSubmitting}
                              >
                                <BiCheckCircle
                                  className="text-success"
                                  size={24}
                                />
                              </button>
                              <br />
                              <br />
                              <Field
                                component={TextField}
                                name="name"
                                label="Name"
                                fullWidth
                              />
                              <Field
                                className="mt-3"
                                component={TextField}
                                name="message"
                                label="Message"
                                fullWidth
                              />
                            </small>
                          </h4>
                        </>
                      )}{" "}
                      <hr />
                    </div>
                  ))}
                </Form>
              )}
            </Formik>
          )}
        </>
      ) : (
        <h3 className="text-center text-success m-4 p-4">
          Unauthorised Access
        </h3>
      )}
    </div>
  );
};

export default Feedback;
