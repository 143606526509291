import Toolbar from "@mui/material/Toolbar";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
import React, { useEffect, useState, useRef, useCallback } from "react";
import useAuth from "../hooks/useAuth";
import Select, { createFilter } from "react-select";
import Axios from "axios";
import { baseURL } from "../../methods/baseUrl";
import { capitalized } from "../../methods/eventHandlers";
import _ from "lodash";

const IssueStudent = ({
  availableBooks,
  setAvailableBooks,
  student,
  getStudentsPerKlass,
  gradYear,
  gradStudents,
  stdId,
  getIssues,
  theKlass,
  searchKey,
  searchedGraduates,
  sKey,
  searchedStudents,
}) => {
  const { auth } = useAuth();
  const mySchool = auth?.school_id;
  const [bookOptions, setBookOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [myDate, setMyDate] = useState(null);
  const [triggerInput, setTriggerInput] = useState(false);
  const formattedDate = () => {
    const today = new Date();
    const now = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(today.getDate()).padStart(2, "0")}T${String(
      today.getHours()
    ).padStart(2, "0")}:${String(today.getMinutes()).padStart(2, "0")}:${String(
      today.getSeconds()
    ).padStart(2, "0")}`;
    setMyDate(now);
  };

  // Regenerates the available books
  const getAvailableBooks = useCallback(async () => {
    try {
      const response = await Axios.get(
        baseURL + `/available/books/${mySchool}`
      );

      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setAvailableBooks(response.data);
        setBookOptions(response.data);
      }
    } catch (error) {
      showErrorMsg(error);
    }
  }, [mySchool, setAvailableBooks]);

  const handleBookChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const issueDateRef = useRef();
  const dueDaysRef = useRef();

  const issueBook = async () => {
    try {
      const issuer = auth?.id;
      const issueDate = issueDateRef.current.value;
      const dueDays = dueDaysRef.current.value;
      const student_id = student.s_id;

      const returnDate = new Date(issueDate);
      returnDate.setDate(returnDate.getDate() + parseInt(dueDays));

      for (let i = 0; i < selectedOption.length; i++) {
        const bookId = selectedOption[i].value;
        const body = {
          student_id,
          returnDate,
          issuer,
          issueDate,
          dueDays,
          bookId,
        };

        await Axios.post(baseURL + "/issue/student", body).then((response) => {
          if (response.data.success) {
            showSuccessMsg(response.data.success);

            if (theKlass) {
              getStudentsPerKlass(theKlass.id, mySchool);
            }
            if (gradYear) {
              gradStudents(gradYear);
            }
            if (stdId) {
              getIssues(stdId);
            }
            if (searchKey) {
              searchedGraduates();
            }
            if (sKey) {
              searchedStudents();
            }
          } else {
            if (
              response.data.msg.includes(
                "duplicate key value violates unique constraint"
              )
            ) {
              showErrorMsg("Book already Issued to that student");
            } else {
              showErrorMsg(response.data.msg);
            }
          }
        });
      }
    } catch (error) {
      showErrorMsg(error.message);
    } finally {
      setSelectedOption([]);
      // setBookOptions([]);
      getAvailableBooks();
    }
  };

  useEffect(() => {
    setIsValid(selectedOption.length > 0 ? true : false);
    formattedDate();
  }, [selectedOption]);

  // useEffect(() => {
  //   if (searchQuery && searchQuery.length > 1) {
  //     const theBooks = availableBooks.map((book) => ({
  //       label: capitalized(book.book_name) + " - " + book.reg_no,
  //       value: book.id,
  //     }));
  //     console.log(theBooks);

  //     const filteredBooks = theBooks.filter((book) =>
  //       book.label.toLowerCase().includes(searchQuery.toLowerCase())
  //     );
  //     setBookOptions(filteredBooks);
  //     console.log(filteredBooks);
  //   }
  // }, [availableBooks, searchQuery]);

  useEffect(() => {
    const theBooks = availableBooks.map((book) => ({
      label: capitalized(book.book_name) + " - " + book.reg_no,
      value: book.id,
    }));
    console.log(theBooks);

    // const filteredBooks = theBooks.filter((book) =>
    //   book.label.toLowerCase().includes(searchQuery.toLowerCase())
    // );
    setBookOptions(theBooks);
  }, [availableBooks, searchQuery]);

  return (
    <div className="modal" id={`id${student?.s_id}`}>
      <Toolbar />
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-success text-center m-3">
              {student?.student_id} - {student?.name}
            </h4>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>

          <div className="modal-body">
            {/* <br /> */}
            {triggerInput && (
              <small className="mb-2 mt-2">
                Results for {searchQuery} :{" "}
                <small className="text-danger">{bookOptions.length}</small>{" "}
              </small>
            )}
            <Select
              id="mySelect"
              closeMenuOnSelect={true}
              placeholder={availableBooks.length + " books available"}
              noOptionsMessage={() => "Type on the cursor"}
              filter={createFilter({
                ignoreAccents: false,
              })}
              isMulti
              options={bookOptions}
              value={selectedOption}
              onChange={handleBookChange}
              onInputChange={(inputValue) => {
                setSearchQuery(inputValue);
                setTriggerInput(true);
              }}
            />

            <br />
            <div className="d-flex">
              <label className="col-6" htmlFor="issueDate">
                Issue Date
                <input
                  name="issueDate"
                  required
                  ref={issueDateRef}
                  defaultValue={myDate}
                  type="datetime-local"
                  className="form-control"
                />
                <br />
              </label>
              <label htmlFor="dueDate" className="col-6">
                Due Days
                <input
                  name="dueDate"
                  defaultValue={7}
                  required
                  ref={dueDaysRef}
                  type="number"
                  min={1}
                  className="form-control"
                />
                <br />
              </label>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger mr-auto"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <input
              onClick={() => issueBook()}
              data-dismiss="modal"
              type={"submit"}
              id="submit"
              value={"Issue"}
              disabled={!isValid}
              className="text-white btn btn-outline-success ml-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IssueStudent;
