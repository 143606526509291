import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import { Button } from "@mui/material";
import IssueStudent from "./crud/IssueStudent";
import { showErrorMsg } from "../methods/alertMsgs";
import Axios from "axios";
import { baseURL } from "../methods/baseUrl";
import { capitalized } from "../methods/eventHandlers";

const Graduates = ({ availableBooks, setAvailableBooks, allBooks }) => {
  const { auth } = useAuth();
  const mySchool = auth?.school_id;
  const sch_category = auth?.sch_category;
  const [years, setYears] = useState([]);
  const [students, setStudents] = useState([]);
  const [gradYear, setGradYear] = useState(0);
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = useState(null);
  const [subjects, setSubjects] = useState([]);

  // Generate students graduated for the specified year===========
  const gradStudents = async (theYear) => {
    try {
      await Axios.get(baseURL + `/graduates/${mySchool}/${theYear}`).then(
        (response) => {
          if (response.data.msg) {
            showErrorMsg(response.data.msg);
          } else {
            setStudents(response.data);
          }
        }
      );
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  const handleYearClick = (year) => {
    gradStudents(year.id);
    setGradYear(year.id);
    setSearchKey(null);
  };
  // Generate searched students graduated for the specified year
  const keyRef = useRef();

  const gradSearchStudents = async () => {
    /**
     * Searches the students whose details are
     * passed thro' id
     */
    const id = keyRef.current.value;
    setSearchKey(id);

    await Axios.get(baseURL + `/search/graduated/${mySchool}/${id}`)
      .then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setStudents(response.data);
        }
      })
      .catch((error) => {
        showErrorMsg(error.message);
      });
  };

  const getSubjects = useCallback(async () => {
    /**
     * Generate only the subjects with no_of_books > 0
     * Called when in need to issue a book
     * where the subject is first selected before
     * the books that HAVE NOT BEEN ISSUED are populated
     */
    await Axios.get(
      baseURL + `/subjects/with/books/more/${mySchool}/${sch_category}`
    ).then((response) => {
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setSubjects(response.data);
      }
    });
  }, [mySchool, sch_category]);

  // Generate graduate years
  const graduateClasses = useCallback(async () => {
    try {
      await Axios.get(baseURL + "/graduate_years/graduated")
        .then((response) => {
          if (response.data.msg) {
            showErrorMsg(response.data.msg);
          } else {
            setYears(response.data);
          }
        })
        .catch((err) => {
          showErrorMsg(err.message);
        });
    } catch (err) {
      showErrorMsg(err.message);
    }
  }, []);

  const getAvailableBooks = useCallback(async () => {
    try {
      const response = await Axios.get(
        baseURL + `/available/books/${mySchool}`
      );

      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setAvailableBooks(response.data);
      }
    } catch (error) {
      showErrorMsg(error);
    }
  }, [mySchool, setAvailableBooks]);
  
  useEffect(() => {
    if (availableBooks.length === 0) {
      console.log("No books, regenerating");
      getAvailableBooks();
    }
  }, [availableBooks.length, getAvailableBooks]);

  useEffect(() => {
    getSubjects();
    graduateClasses();
  }, [getSubjects, graduateClasses]);
  return (
    <div className="container">
      <h5 className="text-success text-center m-3">
        GRADUATED CLASSES{" "}
        <Button
          variant="text"
          className="float-left "
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </h5>

      <form
        className="d-flex mb-4"
        onSubmit={(e) => {
          e.preventDefault();
          gradSearchStudents();
          setGradYear(null);
          /**
           * Prevent generating the list of students per year after doing a search
           */
        }}
      >
        <input
          required
          ref={keyRef}
          type={"text"}
          placeholder="Search a graduate"
          className="form-control"
        />
        <input className="btn btn-success" type={"submit"} id="submit" />
      </form>

      <div className="d-flex">
        {years.map((year) => (
          <div key={year.id} className="col-2">
            <div>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  handleYearClick(year);
                }}
                className="btn btn-success col-12"
                id="submit"
              >
                {year.year_graduated}{" "}
              </Link>
            </div>
          </div>
        ))}
      </div>

      {students.length > 0 && (
        <table className="layout">
          <thead>
            <tr>
              <th>#</th>
              <th>Adm No</th>
              <th>Name</th>
              <th>Class</th>
              <th>Books</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {students.map((student, index) => (
              <tr key={student.s_id}>
                <td>{index + 1}</td>
                <td title="Edit Student">
                  <Link className="link" to="/students">
                    {" "}
                    {student.student_id}
                  </Link>
                </td>
                <td>{capitalized(student.name)}</td>
                {student.graduated ? (
                  <td>{student.year_graduated} Grad</td>
                ) : (
                  <td>
                    {student.k_name} {student.st_name}
                  </td>
                )}
                <td id="borrowed">
                  <Link
                    className="link"
                    to={{
                      pathname: `/students/borrowed/${student.s_id}`,
                    }}
                    // state={{ student: student }}
                  >
                    {student.books}
                  </Link>
                </td>

                <td>
                  <IssueStudent
                    student={student}
                    availableBooks={availableBooks}
                    setAvailableBooks={setAvailableBooks}
                    gradStudents={gradStudents}
                    subjects={subjects}
                    allBooks={allBooks}
                    gradYear={gradYear}
                    searchedGraduates={gradSearchStudents}
                    searchKey={searchKey}
                  />
                  <Link
                    className="link"
                    data-toggle="modal"
                    data-target={`#id${student.s_id}`}
                  >
                    Issue
                  </Link>{" "}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
export default Graduates;
