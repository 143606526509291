import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { baseURL } from "../../methods/baseUrl";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
import Axios from "axios";
import {
  Drawer,
  IconButton,
  List,
  AppBar,
  Toolbar,
  Typography,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";

const AddStream = ({ school }) => {
  const [klasses, setKlasses] = useState([]);
  const [streams, setStreams] = useState([]);
  const [streamName, setStreamName] = useState();
  const [className, setClassName] = useState();
  const [error, setError] = useState("");
  const theSchool = school.id;
  // Runs on every render
  const addStreams = async () => {
    const name = streamName;
    const klass_id = className;
    const body = { name, klass_id, theSchool };

    await Axios.post(baseURL + "/add/stream", body).then((response) => {
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        getStreams(klass_id);
        showSuccessMsg(response.data.success);
      }
    });
  };

  // Get streams
  const getStreams = async (klassId) => {
    /**
     * Get the stream names already in the database
     */
    try {
      await Axios.get(baseURL + `/streams/${klassId}/${theSchool}`).then(
        (response) => {
          if (response.data.msg) {
            showErrorMsg(response.data.msg);
          } else {
            if (response.data.length === 0) {
              setStreams([{ name: "No streams" }]);
            } else {
              setStreams(response.data);
            }
          }
        }
      );
    } catch (err) {
      showErrorMsg(err.message);
    }
  };

  const showKlasses = useCallback(async () => {
    /**
     * Retrieves klasses from backend
     * Sets klasses for the use in setting stream names
     */
    await Axios.get(baseURL + `/klasses/${school.category_id}`).then(
      (response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setKlasses(response.data);
        }
      }
    );
  }, [school.category_id]);

  useEffect(() => {
    showKlasses();
  }, [showKlasses]);

  useEffect(() => {
    /**
     * Checks and returns an error incase
     * a streamname is entered before choosing a class
     */
    streamName && !className ? setError("Choose class first") : setError("");
  }, [className, streamName]);

  //The JSX
  return (
    <div>
      <Link
        className="link"
        data-toggle="modal"
        data-target={`#id${school.invoice_date}`}
      >
        + Stream
      </Link>

      <div className="mt-4 pt-4 modal" id={`id${school.invoice_date}`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              {" "}
              <h4 className="modal-title text-success">{school.name}</h4>
              <button
                title="Close this window"
                type="button"
                className="close"
                data-dismiss="modal"
              >
                &times;
              </button>
            </div>

            <div className="modal-body">
              <select
                onChange={(e) => {
                  setClassName(e.target.value);
                  getStreams(e.target.value);
                }}
                className="form-control"
              >
                <option hidden>Select class first</option>
                {klasses.map((klass) => {
                  if (klass.name === "All Forms") {
                    return null;
                  }
                  if (klass.name === "F1&2") {
                    return null;
                  }
                  if (klass.name === "F3&4") {
                    return null;
                  }
                  return (
                    <option key={klass.id} value={klass.id}>
                      {klass.name}
                    </option>
                  );
                })}
                {/* {klasses.map((klass) => (
                  <option value={klass.id}>{klass.name}</option>
                ))} */}
              </select>
              <br />
              <h6>{error}</h6>
              <div className="row">
                {streams.map((stream) => (
                  <div className="col-md-3" key={stream.id}>
                    <h6>{stream.name}</h6>
                  </div>
                ))}
              </div>
              <label htmlFor="name"></label>
              <input
                onChange={(e) => {
                  setStreamName(e.target.value);
                }}
                // ref={streamRef}
                name="name"
                placeholder="Stream Name"
                className="form-control mb-1"
                type="text"
              />
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger mr-auto"
                data-dismiss="modal"
                title="Close this window"
              >
                Cancel
              </button>
              <button
                type="button"
                id="submit"
                onClick={addStreams}
                className="text-white btn btn-outline-success ml-auto"
                data-dismiss="modal"
                disabled={!streamName || !className}
              >
                Add Stream
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddStream;
