import React, { useState } from "react";
import { Outlet, Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import swal from "sweetalert";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import SchoolIcon from "@mui/icons-material/School";
import SubjectIcon from "@mui/icons-material/Subject";
import ClassIcon from "@mui/icons-material/Class";
import InfoIcon from "@mui/icons-material/Info";
import AppsIcon from "@mui/icons-material/Apps";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import FeedbackIcon from "@mui/icons-material/Feedback";
import LogOutIcon from "@mui/icons-material/Logout";
import { getAuth, signOut } from "firebase/auth";
import { isMobileScreen } from "../../methods/eventHandlers";

import {
  Drawer,
  IconButton,
  List,
  AppBar,
  Toolbar,
  Typography,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
// import { createTheme } from "@mui/material/styles";
import useDocumentTitle from "../hooks/useDocumentTitle";

// const theme = createTheme({});

const AdminDashboard = () => {
  const { auth, setAuth } = useAuth();
  // const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(true);

  const logOut = async () => {
    try {
      const confirmedLogOut = await swal({
        title: "Confirm to log out",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (confirmedLogOut) {
        const auth0 = getAuth();
        console.log(auth0);
        try {
          await signOut(auth0);
          window.location.href = "/";
          setAuth(null);

          showSuccessMsg("Successfully logged out");
        } catch (error) {
          showErrorMsg(error.message);
        }
      }
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  // const logOut = async () => {
  //   try {
  //     const confirmedLogOut = await swal({
  //       title: "Confirm to log out",
  //       icon: "warning",
  //       buttons: true,
  //       dangerMode: true,
  //     });

  //     if (confirmedLogOut) {
  //       try {
  //         const response = await axios.delete(baseURL + "/logout", {
  //           withCredentials: true,
  //         });
  //         if (response.data.msg) {
  //           showErrorMsg(response.data.msg);
  //         } else {
  //           Cookies.remove("connect.sid");
  //           // showSuccessMsg(response.data.success);
  //           // navigate("/home");

  //           setAuth(null);
  //           window.location.href = "/";
  //         }

  //         // setAuth(null);
  //       } catch (error) {
  //         showErrorMsg(error.message);
  //       }
  //     }
  //   } catch (error) {
  //     showErrorMsg(error.message);
  //   }
  // };

  const handleDrawerToggle = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  const toggleMenuView = () => {
    setShowMenu((prevState) => !prevState);
  };
  useDocumentTitle("Admin");
  //JSX
  return (
    <div>
      {auth.is_superuser ? (
        <>
          <AppBar
            className="mb-1 pr-3 pl-3"
            position="sticky"
            sx={{ backgroundColor: "white" }}
          >
            <Toolbar color="inherit">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2 }}
              >
                <MenuIcon className="link" onClick={toggleMenuView} />
              </IconButton>
              {isMobileScreen(useMediaQuery) ? (
                <>
                  <Typography
                    variant="h6"
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      justifyContent: "center",
                      color: "text.primary",
                    }}
                  >
                    SUPER
                  </Typography>
                  <Link
                    onClick={logOut}
                    title={"Logout User"}
                    className="link float-right"
                  >
                    <LogOutIcon />
                  </Link>
                </>
              ) : (
                <>
                  <Typography
                    variant="h6"
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      justifyContent: "center",
                      color: "text.primary",
                    }}
                  >
                    SUPERUSER PANEL
                  </Typography>
                  <Link onClick={logOut} className="link float-right ">
                    <LogOutIcon /> {auth.username}
                  </Link>
                </>
              )}
            </Toolbar>
          </AppBar>
          <div style={{ width: "98%", margin: "auto" }}>
            {isMobileScreen(useMediaQuery) ? (
              <>
                <Drawer
                  anchor="bottom"
                  variant="temporary"
                  open={drawerOpen}
                  onClose={handleDrawerToggle}
                >
                  <List>
                    {" "}
                    <ListItemButton
                      title="Close"
                      onClick={() => setDrawerOpen(false)}
                    >
                      <ListItemText className="float-right link" primary="X" />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => setDrawerOpen(false)}
                      component={Link}
                      to={"/home"}
                    >
                      <ListItemIcon>
                        <HomeIcon />
                      </ListItemIcon>
                      <ListItemText primary="Home" />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => setDrawerOpen(false)}
                      component={Link}
                      to={"/admin/users"}
                    >
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText primary="Users" />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => setDrawerOpen(false)}
                      component={Link}
                      to={"/admin/meta"}
                    >
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText primary="MetaData" />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => setDrawerOpen(false)}
                      component={Link}
                      to={"/admin/schools"}
                    >
                      <ListItemIcon>
                        <SchoolIcon />
                      </ListItemIcon>
                      <ListItemText primary="Schools" />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => setDrawerOpen(false)}
                      component={Link}
                      to={"/admin/subjects"}
                    >
                      <ListItemIcon>
                        <SubjectIcon />
                      </ListItemIcon>
                      <ListItemText primary="Subjects" />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => setDrawerOpen(false)}
                      component={Link}
                      to={"/admin/klasses"}
                    >
                      <ListItemIcon>
                        <ClassIcon />
                      </ListItemIcon>
                      <ListItemText primary="Klasses" />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => setDrawerOpen(false)}
                      component={Link}
                      to={"/admin/about"}
                    >
                      <ListItemIcon>
                        <InfoIcon />
                      </ListItemIcon>
                      <ListItemText primary="About" />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => setDrawerOpen(false)}
                      component={Link}
                      to={"/admin/appinfo"}
                    >
                      <ListItemIcon>
                        <AppsIcon />
                      </ListItemIcon>
                      <ListItemText primary="AppInfo" />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => setDrawerOpen(false)}
                      component={Link}
                      to={"/admin/contactinfo"}
                    >
                      <ListItemIcon>
                        <ContactMailIcon />
                      </ListItemIcon>

                      <ListItemText primary="ContactInfo" />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => setDrawerOpen(false)}
                      component={Link}
                      to={"/admin/feedback"}
                    >
                      <ListItemIcon>
                        <FeedbackIcon />
                      </ListItemIcon>
                      <ListItemText primary="Feedback" />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => setDrawerOpen(false)}
                      component={Link}
                      to={"/admin/register"}
                    >
                      <ListItemIcon>
                        <FeedbackIcon />
                      </ListItemIcon>
                      <ListItemText primary="Register" />
                    </ListItemButton>
                    <hr />
                    <ListItemButton onClick={logOut}>
                      <ListItemIcon>
                        <LogOutIcon />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItemButton>
                  </List>
                </Drawer>
                <div className="row" id="item2">
                  <div className="col-12">
                    <Outlet />
                  </div>
                </div>
              </>
            ) : (
              <div className="row" id="item1" style={{ height: "100vh" }}>
                {showMenu && (
                  <div style={{ width: "18%" }} className="bg-white">
                    <h5 className="text-center text-success m-2">LLR</h5>
                    <hr />
                    <ListItemButton component={Link} to={"/home"}>
                      <ListItemIcon>
                        <HomeIcon />
                      </ListItemIcon>
                      <ListItemText primary="Home" />
                    </ListItemButton>
                    <ListItemButton component={Link} to={"/admin/users"}>
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText primary="Users" />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => setDrawerOpen(false)}
                      component={Link}
                      to={"/admin/meta"}
                    >
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText primary="MetaData" />
                    </ListItemButton>
                    <ListItemButton component={Link} to={"/admin/schools"}>
                      <ListItemIcon>
                        <SchoolIcon />
                      </ListItemIcon>
                      <ListItemText primary="Schools" />
                    </ListItemButton>
                    <ListItemButton component={Link} to={"/admin/subjects"}>
                      <ListItemIcon>
                        <SubjectIcon />
                      </ListItemIcon>
                      <ListItemText primary="Subjects" />
                    </ListItemButton>
                    <ListItemButton component={Link} to={"/admin/klasses"}>
                      <ListItemIcon>
                        <ClassIcon />
                      </ListItemIcon>
                      <ListItemText primary="Klasses" />
                    </ListItemButton>
                    <ListItemButton component={Link} to={"/admin/about"}>
                      <ListItemIcon>
                        <InfoIcon />
                      </ListItemIcon>
                      <ListItemText primary="About" />
                    </ListItemButton>
                    <ListItemButton component={Link} to={"/admin/appinfo"}>
                      <ListItemIcon>
                        <AppsIcon />
                      </ListItemIcon>

                      <ListItemText primary="AppInfo" />
                    </ListItemButton>
                    <ListItemButton component={Link} to={"/admin/contactinfo"}>
                      <ListItemIcon>
                        <ContactMailIcon />
                      </ListItemIcon>
                      <ListItemText primary="Contact" />
                    </ListItemButton>
                    <ListItemButton component={Link} to={"/admin/feedback"}>
                      <ListItemIcon>
                        <FeedbackIcon />
                      </ListItemIcon>
                      <ListItemText primary="Feedback" />
                    </ListItemButton>
                    <ListItemButton component={Link} to={"/admin/register"}>
                      <ListItemIcon>
                        <FeedbackIcon />
                      </ListItemIcon>
                      <ListItemText primary="Register" />
                    </ListItemButton>
                    <hr />
                    <ListItemButton onClick={logOut}>
                      <ListItemIcon>
                        <LogOutIcon />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItemButton>
                  </div>
                )}

                <div
                  className="color-success mt-2 m-auto"
                  style={showMenu ? { width: "82%" } : { width: "98%" }}
                  id="item2"
                >
                  <Outlet />
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="container m-4 p-4 text-success text-center border-danger ">
          {" "}
          <h5 className="mb-2 mt-4 ">Unauthorized Access</h5>
          <h5 className="mb-2 ">Login with administrative details</h5>
          <Link className="link" to={"/"}>
            Login
          </Link>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;
