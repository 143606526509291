import Toolbar from "@mui/material/Toolbar";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
import React, { useEffect, useState, useRef, useCallback } from "react";
import useAuth from "../hooks/useAuth";
import Select, { createFilter } from "react-select";
import Axios from "axios";
import { baseURL } from "../../methods/baseUrl";
import { capitalized } from "../../methods/eventHandlers";


const IssueBook = ({ book, getBooksPerSubject,getBook }) => {
  /**
   *  Issue student with an already selected book.
   */
  const { auth } = useAuth();
  const sch_category = auth?.sch_category;
  const [selectedOption, setSelectedOption] = useState([]);
  const issueDateRef = useRef();
  const dueDaysRef = useRef();
  const studentRef = useRef();
  const [students, setStudents] = useState([]);
  const school = auth?.school_id;
  const [isValid, setIsValid] = useState(false);
  const [klasses, setKlasses] = useState([]);
  const [studentOptions, setStudentOptions] = useState([]);

  const issueBook = async () => {
    try {
      const issuer = auth?.id;
      const issueDate = issueDateRef.current.value;
      const dueDays = dueDaysRef.current.value;
      const bookId = book.id;

      // Calculates the return date based on the due days
      const returnDate = new Date(issueDate);
      returnDate.setDate(returnDate.getDate() + parseInt(dueDays));

      for (let i = 0; i < selectedOption.length; i++) {
        const student_id = selectedOption[i].value;
        // The below lines will run only if the lines above are correct
        const body = {
          student_id,
          returnDate,
          issuer,
          issueDate,
          dueDays,
          bookId,
        };

        await Axios.post(baseURL + "/issue/student", body).then((response) => {
          if (response.data.success) {
            showSuccessMsg(response.data.success);
            if (getBooksPerSubject) {
              getBooksPerSubject(book.subject_id);
            }
            setSelectedOption([]);
            if (getBook) {
              getBook();
            }
            //Called to show the RedEye icon in Books.js to mean that the book is borrowed
          } else {
            showErrorMsg(response.data.msg);
          }
        });
      }
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  const handleStudentChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  // Get klasses
  const getKlasses = useCallback(async () => {
    try {
      await Axios.get(baseURL + `/klasses/${sch_category}`).then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setKlasses(response.data);
        }
      });
    } catch (error) {
      showErrorMsg(error);
    }
  }, [sch_category]);

  const getKlassStudents = async (klass_id) => {
    /**
     * Get all the details of all students in a selected class
     * This is to be stored and used everytime a book issue is required
     */
    try {
      await Axios.get(baseURL + `/students/${klass_id}/${school}`).then(
        (response) => {
          if (response.data.msg) {
            showErrorMsg(response.data.msg);
          } else {
            setStudents(response.data);
            const theStudents = response.data.map((student) => ({
              label: student.student_id + " - " + capitalized(student.name),
              value: student.s_id,
            }));
            setStudentOptions(theStudents);
          }
        }
      );
    } catch (error) {
      showErrorMsg(error);
    }
  };
  useEffect(() => {
    getKlasses();
  }, [getKlasses]);

  useEffect(() => {
    setIsValid(selectedOption.length > 0 ? true : false);
  }, [selectedOption]);

  return (
    <div className="modal" id={`id${book.id}`}>
      <Toolbar />
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title text-success text-center m-3">
              {capitalized(book.book_name)} {" - "}
              {book.reg_no}
            </h6>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body">
            <select
              required
              className="form-control"
              onChange={(e) => getKlassStudents(e.target.value)}
            >
              <option hidden value={""} defaultValue>
                --Select Class--
              </option>

              {klasses.map((klass) => {
                if (klass.name === "All Forms") {
                  return null;
                }
                if (klass.name === "F1&2") {
                  return null;
                }
                if (klass.name === "F3&4") {
                  return null;
                }

                return (
                  <option key={klass.id} value={klass.id}>
                    {" "}
                    {klass.name}
                  </option>
                );
              })}
            </select>
            <br />
            <Select
              ref={studentRef}
              closeMenuOnSelect={true} //Not including this option means true by default
              placeholder={students.length + " records"}
              noOptionsMessage={() => "No options available"}
              filter={createFilter({
                ignoreAccents: false,
              })}
              isMulti
              options={studentOptions}
              value={selectedOption}
              onChange={handleStudentChange}
            />
            <br />

            <div className="d-flex">
              <label className="col-6" htmlFor="issueDate">
                {" "}
                Issue Date
                <input
                  name="issueDate"
                  required
                  ref={issueDateRef}
                  defaultValue={new Date().toISOString().slice(0, 10)}
                  type="date"
                  className="form-control"
                />
                <br />
              </label>
              <label htmlFor="dueDate" className="col-6">
                Due Days
                <input
                  name="dueDate"
                  defaultValue={7}
                  required
                  ref={dueDaysRef}
                  type="number"
                  min={1}
                  className="form-control"
                />
                <br />
              </label>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger mr-auto"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <input
              onClick={() => issueBook(book.id)}
              type={"submit"}
              data-dismiss="modal"
              id="submit"
              defaultValue={"Issue"}
              className="text-white btn btn-outline-success ml-auto"
              disabled={!isValid}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default IssueBook;
