import React, { Fragment, useEffect, useState } from "react";
import { BiCheckCircle, BiXCircle, BiPencil } from "react-icons/bi";
import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { baseURL } from "../../methods/baseUrl";
import { Button } from "@mui/material";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Axios from "axios";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null); //Holds thes tudents edit data

  const editMyUser = (user) => {
    setUserData(user);
  };
  // Retrieves all users
  const getUsers = async () => {
    try {
      await Axios.get(baseURL + "/users").then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setUsers(response.data);
          setTimeout(() => setLoading(false), 400);
        }
      });
    } catch (err) {
      showErrorMsg(err.message);
    }
  };

  const handleSuperuserChange = () => {
    setUserData((data) => ({
      ...data,
      is_superuser: !data.is_superuser,
    }));
  };

  const handleAdminChange = () => {
    setUserData((data) => ({
      ...data,
      is_admin: !data.is_admin,
    }));
  };

  const handleLibrarianChange = () => {
    setUserData((data) => ({
      ...data,
      is_librarian: !data.is_librarian,
    }));
  };

  const handleActiveChange = () => {
    setUserData((data) => ({
      ...data,
      is_active: !data.is_active,
    }));
  };

  const editUser = async (id) => {
    try {
      const body = {
        first_name: userData.first_name,
        last_name: userData.last_name,
        is_superuser: userData.is_superuser,
        is_active: userData.is_active,
        is_admin: userData.is_admin,
        is_librarian: userData.is_librarian,
        email: userData.email,
        phone: userData.phone,
      };
      const response = await Axios.put(baseURL + `/update/user/${id}`, body);
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        getUsers();
        showSuccessMsg(response.data.success);
        setUserData(null);
      }
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  useDocumentTitle("Users");
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="container">
      <div>
        <h4 className="text-success text-center m-4">
          {" "}
          ALL USERS{" - "} {users.length}
        </h4>
        <table className="layout  category-table">
          <thead>
            <tr>
              {!userData && (
                <>
                  <th>User ID</th>
                  <th>School ID</th>
                </>
              )}
              <th>School</th>
              {!userData && <th>Name</th>}
              {userData && (
                <>
                  <th>First Name</th>
                  <th>last Name</th>
                </>
              )}
              <th>Email</th>
              {!userData && <th>UserName</th>}
              <th>Phone</th>
              {!userData && <th>Last Login</th>}
              <th>Superuser</th>
              <th>Admin</th>
              <th>Librarian</th>
              <th>Active</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, key) => (
              <tr key={user.id}>
                {!userData && (
                  <>
                    <td>{user.id}</td>
                    <td>{user.school_id}</td>
                  </>
                )}

                <td>{user.school_name}</td>

                {userData && userData.id === user.id ? (
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={userData.first_name}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          first_name: e.target.value,
                        })
                      }
                    />
                  </td>
                ) : (
                  <td>
                    {user.first_name} {user.last_name}
                  </td>
                )}
                {userData && userData.id === user.id && (
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={userData.last_name}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          last_name: e.target.value,
                        })
                      }
                    />
                  </td>
                )}
                {userData && userData.id === user.id ? (
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={userData.email}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          email: e.target.value,
                        })
                      }
                    />
                  </td>
                ) : (
                  <td>{user.email}</td>
                )}
                {!userData && <td>{user.username}</td>}
                {userData && userData.id === user.id ? (
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={userData.phone}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          phone: e.target.value,
                        })
                      }
                    />
                  </td>
                ) : (
                  <td>{user.phone}</td>
                )}

                {!userData && (
                  <td>{new Date(user.last_login).toLocaleString()}</td>
                )}

                {userData && userData.id === user.id ? (
                  <td style={{ textAlign: "center" }}>
                    <input
                      type="checkbox"
                      id="myCheckbox"
                      className="form-check-input"
                      checked={userData.is_superuser}
                      onChange={handleSuperuserChange}
                      style={{ transform: "scale(1.4)" }}
                    />
                  </td>
                ) : (
                  <td>
                    <input
                      type="checkbox"
                      disabled
                      id="myCheckbox"
                      checked={user?.is_superuser}
                      style={{ transform: "scale(1.4)" }}
                    />
                  </td>
                )}
                {userData && userData.id === user.id ? (
                  <td style={{ textAlign: "center" }}>
                    <input
                      type="checkbox"
                      id="myCheckbox"
                      className="form-check-input"
                      checked={userData.is_admin}
                      onChange={handleAdminChange}
                      style={{ transform: "scale(1.4)" }}
                    />
                  </td>
                ) : (
                  <td>
                    <input
                      type="checkbox"
                      disabled
                      id="myCheckbox"
                      checked={user?.is_admin}
                      style={{ transform: "scale(1.4)" }}
                    />
                  </td>
                )}
                {userData && userData.id === user.id ? (
                  <td style={{ textAlign: "center" }}>
                    <input
                      type="checkbox"
                      id="myCheckbox"
                      className="form-check-input"
                      checked={userData.is_librarian}
                      onChange={handleLibrarianChange}
                      style={{ transform: "scale(1.4)" }}
                    />
                  </td>
                ) : (
                  <td>
                    <input
                      type="checkbox"
                      disabled
                      id="myCheckbox"
                      checked={user?.is_librarian}
                      style={{ transform: "scale(1.4)" }}
                    />
                  </td>
                )}
                {userData && userData.id === user.id ? (
                  <td style={{ textAlign: "center" }}>
                    <input
                      type="checkbox"
                      id="myCheckbox"
                      className="form-check-input"
                      checked={userData.is_active}
                      onChange={handleActiveChange}
                      style={{ transform: "scale(1.4)" }}
                    />
                  </td>
                ) : (
                  <td>
                    <input
                      type="checkbox"
                      disabled
                      id="myCheckbox"
                      checked={user?.is_active}
                      style={{ transform: "scale(1.4)" }}
                    />
                  </td>
                )}
                <td>
                  {userData && userData.id === user.id ? (
                    <small>
                      <Link
                        className="link btn btn-primry btn-sm"
                        onClick={() => {
                          editUser(user.id);
                        }}
                      >
                        <BiCheckCircle size={18} className="text-success" />
                      </Link>
                      <Link
                        className="link btn btn-primry btn-sm"
                        onClick={() => setUserData(null)}
                      >
                        <BiXCircle className="text-danger" size={18} />
                      </Link>
                    </small>
                  ) : (
                    <Link
                      title={`Edit ${user.first_name}`}
                      onClick={() => {
                        editMyUser(user);
                      }}
                    >
                      <BiPencil size={17} className="link" />
                    </Link>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Users;
