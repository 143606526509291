import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "../../methods/baseUrl";
import { showErrorMsg } from "../../methods/alertMsgs";
import Axios from "axios";
import { Button, TextField } from "@mui/material";
import useDocumentTitle from "../hooks/useDocumentTitle";
import AddSchool from "../crud/AddSchool";
import axios from "axios";
import { firebaseRegisterUser } from "../../methods/firebaseHandlers";
const Register = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [schools, setSchools] = useState([]);
  const [loading, setLoading] = useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [fNameValue, setFNameValue] = useState("");
  const [lNameValue, setLNameValue] = useState("");
  const [pWord1Value, setPWord1Value] = useState("");
  const [pWord2Value, setPWord2Value] = useState("");
  const [pNumValue, setPNumValue] = useState("");
  const navigate = useNavigate();
  const [mySchool, setMySchool] = useState("");
  const [showMsg, setShowMsg] = useState("");
  const [proceed, setProceed] = useState(false);
  const [password1Valid, setPassword1Valid] = useState(true);
  const [password2Valid, setPassword2Valid] = useState(true);

  const handleRegistrationSuccess = (response) => {
    /**
     * Reset the input fields upon sucessful registration
     */
    try {
      setProceed(false);
      setEmail("");
      setUsername("");
      setFNameValue("");
      setLNameValue("");
      setPWord1Value("");
      setPWord2Value("");
      setShowMsg(response.data.success);
    } catch (error) {
      setShowMsg(error.message);
    }
  };
  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
  };

  const verifyUsernameEmail = async () => {
    setLoading(true);
    try {
      const usernameExists = await axios.get(
        baseURL + `/user/data/username/${username}`
      );
      if (usernameExists.data.msg) {
        setShowMsg(usernameExists.data.msg);
      } else {
        const emailExists = await axios.get(
          baseURL + `/user/data/email/${email}`
        );
        if (emailExists.data.msg) {
          setShowMsg(emailExists.data.msg);
        } else {
          // Both username and password are usable, proceed
          setProceed(true);
        }
      }
    } catch (error) {
      showErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  };
  const addUser = async () => {
    setRegisterLoading(true);
    try {
      const phone = pNumValue;
      const fname = fNameValue;
      const lname = lNameValue;
      const uname = username;
      const pword1 = pWord1Value;
      const pword2 = pWord2Value;
      const school_id = mySchool;
      const password = pWord1Value;

      const firebaseResponse = await firebaseRegisterUser(email, password);
      if (firebaseResponse.status === "success") {
        const uid = firebaseResponse.uid;
        const body = {
          uname,
          fname,
          lname,
          pword1,
          pword2,
          email,
          phone,
          school_id,
          uid,
        };

        const response = await Axios.post(baseURL + "/user/add", body);
        if (response.data.msg) {
          setShowMsg(response.data.msg);
        } else {
          handleRegistrationSuccess(response);
        }
      } else {
        setShowMsg(firebaseResponse);
      }
    } catch (error) {
      setShowMsg(error.message);
    } finally {
      setRegisterLoading(false);
    }
  };

  // const addUser = async () => {
  //   setRegisterLoading(true);
  //   try {
  //     const password = pWord1Value;
  //     const confirmPassword = pWord2Value;

  //     if (password !== confirmPassword) {
  //       setShowMsg("Passwords do not match");
  //       return;
  //     }
  //     if (password.length < 8) {
  //       setShowMsg("Password must be 8 or more characters");
  //       return;
  //     }

  //     const userCredential = await firebase
  //       .auth()
  //       .createUserWithEmailAndPassword(email, password);
  //     const uid = userCredential.user.uid;

  //     const phone = pNumValue;
  //     const fname = fNameValue;
  //     const lname = lNameValue;
  //     const uname = username;
  //     const pword1 = pWord1Value;
  //     const pword2 = pWord2Value;
  //     const school_id = mySchool;

  //     const body = {
  //       uname,
  //       fname,
  //       lname,
  //       pword1,
  //       pword2,
  //       email,
  //       phone,
  //       school_id,
  //       uid,
  //     };

  //     const response = await Axios.post(baseURL + "/user/add", body);

  //     if (response.data.msg) {
  //       setShowMsg(response.data.msg);
  //     } else {
  //       setShowMsg(response.data.success);
  //     }
  //   } catch (error) {
  //     if (error.code === "auth/network-request-failed") {
  //       setShowMsg("Poor/No Internet Connection");
  //     } else if (error.code === "auth/email-already-in-use") {
  //       setShowMsg("Email already registered");
  //     } else if (error.code === "auth/invalid-email") {
  //       setShowMsg("Email provided is invalid");
  //     } else {
  //       setShowMsg(error.message);
  //     }
  //   } finally {
  //     setRegisterLoading(false);
  //   }
  // };

  const getSchools = useCallback(async () => {
    try {
      await Axios.get(baseURL + "/schools").then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setSchools(response.data);
        }
      });
    } catch (error) {
      showErrorMsg(error.message);
    }
  }, []);

  useDocumentTitle("Register");

  useEffect(() => {
    getSchools();
  }, [getSchools]);

  useEffect(() => {
    if (showMsg) {
      const timeout = setTimeout(() => {
        setShowMsg(null);
      }, 10000);

      return () => clearTimeout(timeout);
    }
  }, [showMsg]);

  return (
    <div className="container" id="register-main">
      <div className="container" id="register-form">
        <h5 className="text-center text-success mt-4 p-2">
          THANK YOU FOR CHOOSING LLR
          <Button onClick={() => navigate(-1)} className="float-left">
            Back
          </Button>
        </h5>
        {showMsg && (
          <>
            <h5 className="text-center text-success alert alert-danger">
              {showMsg}
            </h5>
          </>
        )}

        <>
          <TextField
            fullWidth
            type="text"
            label="Username"
            variant="standard"
            margin="normal"
            disabled={proceed}
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            InputLabelProps={{ style: { fontWeight: "bold" }, shrink: true }}
          />
          <TextField
            fullWidth
            type="email"
            label="Email"
            variant="standard"
            margin="normal"
            placeholder="Email Address"
            value={email}
            onChange={handleEmailChange}
            required
            disabled={proceed}
            InputLabelProps={{ style: { fontWeight: "bold" }, shrink: true }}
          />
          <Button
            id={"submit"}
            disabled={!email || !username || loading || proceed}
            variant="contained"
            onClick={verifyUsernameEmail}
            className={`btn btn-primary form-control mt-4 mb-4 text-white`}
          >
            {loading ? "Please Wait..." : "Next"}
          </Button>
        </>
        {proceed && (
          <>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                addUser();
              }}
              className="w-4"
            >
              <div className="row mt-3">
                <label className="col-6">
                  First Name
                  <input
                    className="form-control mb-2"
                    value={fNameValue}
                    onChange={(e) => setFNameValue(e.target.value)}
                    type={"text"}
                    required
                    placeholder="First Name"
                  />
                </label>
                <label className="col-6">
                  Last Name
                  <input
                    className="form-control mb-2"
                    value={lNameValue}
                    onChange={(e) => setLNameValue(e.target.value)}
                    type={"text"}
                    required
                    placeholder="Last Name"
                  />
                </label>
              </div>
              <div className="row mt-3">
                <label className="col-6">
                  Phone
                  <input
                    className="form-control mb-2"
                    value={pNumValue}
                    onChange={(e) => setPNumValue(e.target.value)}
                    type={"phone"}
                    defaultValue="07"
                    placeholder="Phone Number"
                  />
                </label>
                <label className="col-6">
                  <Link className="link" title={"Add a Class"}>
                    <AddSchool />{" "}
                  </Link>
                  <select
                    onChange={(e) => setMySchool(e.target.value)}
                    name="school"
                    className="form-control"
                    value={mySchool}
                  >
                    <option value={""}>--Select a school--</option>
                    {schools.map((school, key) => (
                      <option key={school.id} value={school.id}>
                        {school.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <br />
              <div className="row mt-3">
                <label className="col-6">
                  <input
                    className={`form-control mb-2 ${
                      password1Valid ? "" : "border-danger"
                    }`}
                    value={pWord1Value}
                    onChange={(e) => {
                      setPWord1Value(e.target.value);
                      setPassword1Valid(e.target.value.length >= 8);
                    }}
                    type="password"
                    placeholder="Password"
                    required
                  />
                  {!password1Valid && (
                    <p className="text-danger">
                      Password must be at least 8 characters.
                    </p>
                  )}
                </label>
                <label className="col-6">
                  <input
                    className={`form-control mb-2 ${
                      password2Valid ? "" : "border-danger"
                    }`}
                    value={pWord2Value}
                    onChange={(e) => {
                      setPWord2Value(e.target.value);
                      setPassword2Valid(e.target.value === pWord1Value);
                    }}
                    type="password"
                    placeholder="Confirm Password"
                    required
                  />
                  {!password2Valid && (
                    <p className="text-danger">Passwords must match.</p>
                  )}
                </label>
              </div>

              <input
                className="mt-4 btn btn-success form-control"
                id="submit"
                type={"submit"}
                value={registerLoading ? "Please Wait..." : "Register"}
                // value="Register"
                disabled={
                  !mySchool ||
                  registerLoading ||
                  !pWord1Value ||
                  !pWord2Value ||
                  !fNameValue ||
                  !pNumValue ||
                  !lNameValue ||
                  !password1Valid || // Check if password1 is valid
                  !password2Valid // Check if password2 is valid
                }
              />
            </form>
          </>
        )}

        <br />
      </div>
    </div>
  );
};

export default Register;
