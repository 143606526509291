import React, { useEffect, useState, useRef, useCallback } from "react";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate, useParams, redirect } from "react-router-dom";
import { Button } from "@mui/material";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
import Axios from "axios";
import { TailSpin, ThreeDots } from "react-loader-spinner";

import { baseURL } from "../../methods/baseUrl";

const UpdatePassword = () => {
    const [occupied, setOccupied] = useState(false);
    const [pwd1, setPwd1] = useState("");
    const [pwd2, setPwd2] = useState("");
    const [msg, setMsg] = useState(null);
    const navigate = useNavigate();
    const { auth, setAuth } = useAuth();

    const id = auth?.id;

    const updatePassword = async () => {
        // const password = pwordRef.current.vale;
        const body = { id, pwd1 };

        await Axios.put(baseURL + `/update/password/${id}`, body).then(
            (response) => {
                if (response.data.msg) {
                    showErrorMsg(response.data.msg);
                } else {
                    showSuccessMsg(response.data.success);
                    // redirect("/home");
                    // setAuth(null);
                    // window.location("/home");
                    // navigate("/home");
                    //Set a state
                }
            }
        );
    };

    //Authentication for password reset
    const passConfirm = useCallback(() => {
        /**
         * Authemnticate password before setting it up
         * The set password should be 8 or more characters
         */
        if (pwd1.length > 7 && pwd2.length > 7) {
            if (pwd1 === pwd2) {
                setOccupied(true);
                setMsg(null);
            } else {
                setMsg("Password Mismatch");
                setOccupied(false);
            }
        } else {
            setMsg(null);
        }
    }, [pwd1, pwd2]);

    useEffect(() => {
        passConfirm();
    }, [passConfirm]);
    return (
        <div className="container text-center m-3 p-3">
            <h4 className="text-center text-success m-2">
                UPDATE PASSWORD
                <Link className="float-left m-4 link" to={"/"}>
                    <h6>Back</h6>
                </Link>
            </h4>
            <form
                className="m-2 p-3"
                onSubmit={(e) => {
                    e.preventDefault();
                    updatePassword();
                }}
            >
                <input
                    title="8 or more characters"
                    onChange={(e) => setPwd1(e.target.value)}
                    className="form-control mb-3"
                    placeholder="Enter New Password"
                    type={"password"}
                />

                <input
                    title="8 or more characters"
                    onChange={(e) => setPwd2(e.target.value)}
                    className="form-control mb-3 "
                    placeholder="Re-Enter New Password"
                    type={"password"}
                />

                {msg ? (
                    <h6 className="text-danger text-center">{msg}</h6>
                ) : (
                    <h6>
                        <br />{" "}
                    </h6>
                )}
                <Button
                    variant="contained"
                    className="form-control mb-3"
                    disabled={!occupied}
                    type={"submit"}
                >
                    UPDATE
                </Button>
            </form>
        </div>
    );
};
export default UpdatePassword;
