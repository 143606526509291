// import React from "react";

// const Footer = () => {
//   return (
//     <footer style={styles.footer}>
//       <div style={styles.content}>
//         <h5 style={styles.text}>
//           <small className="footer-text" style={styles.mobileText}>&copy; 2022 Linkits Digital</small>
//           <small className="footer-text">0725109389</small>
//           <div className="clearfix"></div>
//         </h5>
//       </div>
//     </footer>
//   );
// };

// const styles = {
//   footer: {
//     backgroundColor: "grey",
//     padding: "20px 0",
//     position: "static",
//     left: 0,
//     bottom: 0,
//     width: "100%",
//     height: "70px",
//   },
//   content: {
//     margin: "0 60px", // Add margin on the left and right
//   },
//   text: {
//     margin: 0,
//     padding: 0,
//     textAlign: "center",
//     color: "white",
//     fontWeight: "bold",
//   },
//   mobileText: {
//     display: "block",
//     textAlign: "center",
//     marginBottom: "10px",
//   },
// };

// export default Footer;

import React from "react";
import { useMediaQuery } from "@mui/material";

import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({});
const Footer = () => {
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <footer style={styles.footer}>
      <div style={styles.content}>
        <h5 style={styles.text}>
          {isMobileScreen ? (
            <>
              <h6 className="text-center">&copy; 2022 Linkits Digital</h6>
              <h6 className="text-center">0725109389</h6>
              <div className="clearfix"></div>
            </>
          ) : (
            <>
              <small className="float-left">&copy; 2022 Linkits Digital</small>
              <small className="float-right">0725109389</small>
              <div className="clearfix"></div>
            </>
          )}
        </h5>
      </div>
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: "grey",
    padding: "20px 0",
    position: "static",
    left: 0,
    bottom: 0,
    width: "100%",
    height: "70px",
  },
  content: {
    margin: "0 60px", // Add margin on the left and right
  },
  text: {
    margin: 0,
    padding: 0,
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
  },
};

export default Footer;
