import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItemButton,
  Hidden,
  useMediaQuery,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { Outlet, Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Footer from "./Footer";
import logo from "./logo/logo-white.gif";
import { Link as ScrollLink } from "react-scroll";

const Navbar = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const navbarStyle = {
    backgroundColor: "rgb(173, 83, 202)",
    position: "sticky",
    top: 0,
    zIndex: 1000,
  };

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const menuItemStyle = {
    marginLeft: "1rem",
    fontFamily: "Roboto", // Example font: Roboto
    fontSize: "1rem", // Example font size: 1rem
  };

  const theme = createTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" style={navbarStyle}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img
              className="mr-4 pr-2 logo-swing"
              style={{ width: "70px", height: "50px" }}
              src={logo}
              alt=""
            />
          </Typography>
          {isMobileScreen ? (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
              sx={{ marginLeft: "auto" }}
            >
              {/* Replace the default menu icon with a custom icon */}
              <MenuIcon />
            </IconButton>
          ) : (
            <>
              <Hidden smDown>
                <div style={menuItemStyle}>
                  <ScrollLink
                    to="about"
                    smooth={true}
                    duration={300}
                    offset={-30}
                    className="text-white"
                  >
                    <Typography variant="small" component="div">
                      <Link className="link text-white">About</Link>
                    </Typography>
                  </ScrollLink>
                </div>
              </Hidden>
              <Hidden smDown>
                <div style={menuItemStyle}>
                  {/* <Typography variant="h6" component="div">
                    <Link className="text-white link" to={""}>
                      0725109389
                    </Link>
                  </Typography> */}
                  <ScrollLink
                    onClick={toggleDrawer}
                    to="contact"
                    smooth={true}
                    duration={300}
                    offset={-30}
                    // className="link"
                  >
                    <Typography variant="small" component="div">
                      <Link className="link text-white">Contact</Link>
                    </Typography>
                  </ScrollLink>
                </div>
              </Hidden>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Hidden mdUp>
        <Drawer anchor="bottom" open={openDrawer} onClose={toggleDrawer}>
          <List>
            <ListItemButton>
              <ScrollLink
                onClick={toggleDrawer}
                to="about"
                smooth={true}
                duration={300}
                offset={-30}
                // className="link"
              >
                About
              </ScrollLink>
            </ListItemButton>
            <ListItemButton onClick={toggleDrawer}>
              {/* <ListItemText>0725109389</ListItemText> */}
              <ScrollLink
                onClick={toggleDrawer}
                to="contact"
                smooth={true}
                duration={300}
                offset={-30}
                // className="link"
              >
                Contact
              </ScrollLink>
            </ListItemButton>
          </List>
        </Drawer>
      </Hidden>
      <Outlet />
      <Footer />
    </ThemeProvider>
  );
};

export default Navbar;
