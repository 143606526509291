import { Toolbar } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
import Axios from "axios";
import useAuth from "../hooks/useAuth";
import { baseURL } from "../../methods/baseUrl";
import { capitalized } from "../../methods/eventHandlers";
export const AddStudent = ({ getAllStudents }) => {
  // State variables
  const { auth } = useAuth();
  const school = auth?.school_id;
  const sch_category = auth?.sch_category;
  const [streams, setStreams] = useState([]);
  const [sYears, setSYears] = useState([]);
  const [klasses, setKlasses] = useState([]);
  const [st_name, setStName] = useState();
  const [k_name, setKName] = useState();
  const [year_no, setYearNo] = useState();
  // Used for the Add Student fields
  const adm_no = useRef();
  const s_name = useRef();

  // Get klasses
  const getKlasses = useCallback(async () => {
    try {
      await Axios.get(baseURL + `/klasses/${sch_category}`).then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setKlasses(response.data);
        }
      });
    } catch (error) {
      showErrorMsg(error);
    }
  }, [sch_category]);

  // Get streams
  const getStreams = async (klassId, schl) => {
    try {
      await Axios.get(baseURL + `/streams/${klassId}/${schl}`).then(
        (response) => {
          if (response.data.msg) {
            showErrorMsg(response.data.msg);
          } else {
            if (response.data.length === 0) {
              setStreams([]);
            } else {
              setStreams(response.data);
            }
          }
        }
      );
    } catch (err) {
      showErrorMsg(err.message);
    }
  };

  // Set selectable years
  const setYears = function () {
    const now = new Date();
    const yearList = [];
    for (let i = now.getFullYear() - 5; i <= now.getFullYear(); i++) {
      yearList.push(i.toString());
    }
    setSYears(yearList);
  };

  const addStudent = async () => {
    /*
     * Capture student details
     * Validate and send to thedatabse
     */
    try {
      const adm = adm_no.current.value;
      const name = capitalized(s_name.current.value);
      const myKlass = k_name;
      const stream = st_name;
      const year = year_no;
      const school_id = school;

      const body = { name, myKlass, stream, adm, year, school_id };

      await Axios.post(baseURL + "/add/student/", body)
        .then((response) => {
          if (response.data.success) {
            adm_no.current.value = "";
            s_name.current.value = "";
            showSuccessMsg(response.data.success);
            getAllStudents();
          } else {
            if (
              response.data.msg.includes(
                "duplicate key value violates unique constraint"
              )
            ) {
              showErrorMsg(`A student with ADM ${adm} already exists`);
            } else {
              showErrorMsg(response.data.msg);
            }
          }
        })
        .catch((error) => {
          showErrorMsg(error);
        });
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  useEffect(() => {
    getKlasses();
    setYears();
  }, [getKlasses]);

  // The JSX
  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          addStudent();
        }}
      >
        <div className="modal" id="add">
          <Toolbar />
          <div className="modal-dialog" id="mySelect">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title ">NEW STUDENT</h5>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <label>Adm No</label>
                <input
                  required
                  ref={adm_no}
                  placeholder="Admission Number"
                  className="form-control mb-1"
                  type="number"
                />
                <label>Name</label>
                <input
                  required
                  ref={s_name}
                  placeholder="Student's name"
                  className="form-control mb-1"
                  type="text"
                />
                <div className="row">
                  <label className="col-4">
                    Class
                    <select
                      required
                      className="form-control"
                      onChange={(e) => {
                        getStreams(e.target.value, school);
                        setKName(() => e.target.value);
                        setStName(null);
                      }}
                    >
                      <option defaultValue="" hidden>
                        --Select Class--
                      </option>
                      {klasses.map((klass) => {
                        if (klass.name === "All Forms") {
                          return null;
                        }
                        if (klass.name === "F1&2") {
                          return null;
                        }
                        if (klass.name === "F3&4") {
                          return null;
                        }
                        return (
                          <option key={klass.id} value={klass.id}>
                            {klass.name}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                  <label className="col-4">
                    Stream
                    <select
                      required
                      className="form-control"
                      onChange={(e) => {
                        setStName(e.target.value);
                      }}
                    >
                      <option defaultValue="" hidden>
                        --Select Stream--
                      </option>
                      {streams?.map((stream) => (
                        <option key={stream.id} value={stream.id}>
                          {stream.name}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label className="col-4">
                    Admission Year
                    <select
                      required
                      className="form-control"
                      onChange={(e) => {
                        setYearNo(e.target.value);
                      }}
                    >
                      <option defaultValue="" hidden>
                        --Year--
                      </option>
                      {sYears.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger mr-auto"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <input
                  // disabled={streams.length < 1}
                  type="submit"
                  value="Save"
                  id="submit"
                  className="text-white btn btn-outline-success ml-auto"
                />
                {/* <button type="button" id="submit" className="text-white btn btn-outline-success ml-auto" data-dismiss="modal">Save</button> */}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
