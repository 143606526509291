import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import Axios from "axios";
import { TailSpin } from "react-loader-spinner";
import { baseURL } from "../methods/baseUrl";
import IssueTeacher from "./crud/IssueTeacher";

import { showErrorMsg, showSuccessMsg } from "../methods/alertMsgs";
import { Button } from "@mui/material";
import useDocumentTitle from "./hooks/useDocumentTitle";
import {
  BiCheckCircle,
  BiXCircle,
  BiPencil,
  BiDownload,
  BiPlus,
} from "react-icons/bi";

const Teachers = ({
  allTeachers,
  availableBooks,
  setAvailableBooks,
  setAllTeachers,
}) => {
  const { auth } = useAuth();
  const school = auth?.school_id;
  const mySchool = auth?.school_id;
  const sch_category = auth?.sch_category;
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewSearchedTeachers, setViewSearchedTeachers] = useState(false);
  const [addTeacher, setAddTeacher] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [editTeacherData, setEditTeacherData] = useState(null); //Holds the students edit data
  const editMyTeacher = (teacher) => {
    setEditTeacherData(teacher);
  };
  const getTeachers = useCallback(async () => {
    // alert("getTeachers called from Teachers.js");
    /**
     * Extract all teachers details present in a school
     * This function is invoked only incase the page is
     * refreshed, teacher details are searched.teacher detdails edited,
     * teacher deleted or when a new teacher is added to the database
     */
    try {
      await Axios.get(baseURL + `/teachers/${school}`).then((response) => {
        if (response?.data?.results) {
          setTeachers(response.data.results);
          setAllTeachers(response.data.results);
          setLoading(false);
        } else {
          showErrorMsg(response.data.msg);
        }
      });
    } catch (error) {
      showErrorMsg(error);
    }
  }, [school, setAllTeachers]);

  // Adding a new teacher
  const t_name = useRef();
  const t_code = useRef();
  const t_phone = useRef();

  const NewTeacher = async (e) => {
    e.preventDefault();
    try {
      const name = t_name.current.value;
      const code = t_code.current.value;
      const phone = t_phone.current.value;

      const body = { name, code, phone, school };

      // Send a call to the backend
      await Axios.post(baseURL + "/teachers/add", body).then((response) => {
        if (response.data.success) {
          showSuccessMsg(response.data.success);
          getTeachers();
          // setAllTeachersCount(parseInt(allTeachersCount) + 1)
        } else {
          if (
            response.data.msg.includes(
              "duplicate key value violates unique constraint"
            )
          ) {
            showErrorMsg(`A teacher with code ${code} already exists`);
          } else {
            showErrorMsg(response.data.msg);
          }
        }
      });
    } catch (error) {
      showErrorMsg(error);
    }
  };

  const keyRef = useRef(); //For capturing the typed character for search

  // Search a teacher
  const searchTeacher = async () => {
    try {
      const key = keyRef.current.value; //event.target.value;

      if (key) {
        await Axios.get(
          baseURL + `/teachers/search/${school}/${encodeURIComponent(key)}`
        ).then((response) => {
          if (response.data.msg) {
            showErrorMsg(response.data.msg);
          } else {
            if (response.data.length > 0) {
              setTeachers(response.data);
              setViewSearchedTeachers(true);
            } else {
              showErrorMsg(`No results matching ${key}`);
            }
          }
        });
      }
    } catch (error) {
      showErrorMsg(error);
    }
  };

  const toggleAddTeacher = () => {
    setAddTeacher(!addTeacher);
  };

  const getSubjects = useCallback(async () => {
    /**
     * Generate only the subjects with no_of_books > 0
     * Called when in need to issue a book
     * where the subject is first selected before
     * the books NOTYET ISSUED are populated
     */
    await Axios.get(
      baseURL + `/subjects/with/books/more/${mySchool}/${sch_category}`
    )
      .then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setSubjects(response.data);
        }
      })
      .catch((error) => {
        showErrorMsg(error.message);
      });
  }, [mySchool, sch_category]);

  const teacherDownload = async () => {
    try {
      await Axios.get(baseURL + `/teachers/download/${school}`, {
        responseType: "blob",
      }).then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          const downloadUrl = URL.createObjectURL(response.data);
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", `${auth?.school_name}.xlsx`);
          // link.download = "borrowed_books.xlsx";
          link.click();
          URL.revokeObjectURL(downloadUrl);
        }
      });
    } catch (error) {
      // console.error("Error downloading the file:", error.message);
      showErrorMsg("Error downloading the file:", error.message);
    }
  };

  const editTeacher = async (teacher) => {
    try {
      const name = editTeacherData.name;
      const phone = editTeacherData.phone;
      const code = editTeacherData.code;

      const body = { name, phone, code };

      await fetch(baseURL + `/teachers/edit/${teacher}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.success) {
            setEditTeacherData(null);
            showSuccessMsg(data.success);
            getTeachers();
          } else {
            showErrorMsg(data.msg);
          }
        })
        .catch((error) => {
          showErrorMsg(error);
        });
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  const getAvailableBooks = useCallback(async () => {
    try {
      const response = await Axios.get(
        baseURL + `/available/books/${mySchool}`
      );

      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setAvailableBooks(response.data);
      }
    } catch (error) {
      showErrorMsg(error);
    }
  }, [mySchool, setAvailableBooks]);

  useDocumentTitle("Teachers");

  useEffect(() => {
    if (availableBooks.length === 0) {
      console.log("No books, regenerating");
      getAvailableBooks();
    }
  }, [availableBooks, getAvailableBooks]);

  useEffect(() => {
    getSubjects();
    // document.title = "LLR | Teachers";
    if (allTeachers !== null) {
      setTeachers(allTeachers);
      setLoading(false);
    } else {
      //Handles a refresh
      getTeachers();
    }
  }, [allTeachers, getSubjects, getTeachers]);

  // The html part
  return (
    <div className="container">
      <div className="text-center text-success">
        <h4 className="d-blocktext-center text-success mb-2 mt-4 mb-2 ">
          TEACHERS{" - "}
          <small className="text-success mb-0">{allTeachers?.length}</small>
        </h4>
        <hr />
        <div className="d-block mt-0">
          <Button variant="text" className="float-left" onClick={goBack}>
            Back
          </Button>
          {viewSearchedTeachers && (
            <Link
              style={{
                fontSize: "15px",
                textDecoration: "none",
              }}
              className="text-center text-danger link"
              onClick={() => {
                setViewSearchedTeachers(false);
              }}
              title="View all teachers"
            >
              View All
            </Link>
          )}
          {!addTeacher ? (
            <small className="float-right">
              <Button
                title="Download Teachers"
                variant="text"
                className="link"
                onClick={teacherDownload}
              >
                <BiDownload size={20} className="success" />
              </Button>
              <Button variant="text" onClick={() => toggleAddTeacher()}>
                <BiPlus size={20} /> Teacher
              </Button>
            </small>
          ) : (
            <Button
              className="float-right"
              variant="text"
              onClick={() => setAddTeacher(false)}
            >
              CLOSE
            </Button>
          )}
          {/*  */}
        </div>
      </div>

      {addTeacher && (
        <div className="card">
          {/* Add Teacher Form */}
          <form id="newTeacherForm" onSubmit={NewTeacher}>
            <input
              ref={t_code}
              required
              id="code"
              className="form-control"
              placeholder="ID/Code"
              type="number"
              min="1"
              // max="30000"
            />
            <input
              ref={t_name}
              required
              id="name"
              className="form-control"
              placeholder="Name"
              type="text"
            />
            <input
              ref={t_phone}
              required
              defaultValue="+254"
              id="phone"
              className="form-control"
              placeholder="Contact"
              type="phone"
            />
            <button id="submit" className="btn btn-success" type="submit">
              Add
            </button>
          </form>
        </div>
      )}
      <br />
      <br />
      {/* Search Teacher Form */}
      <form
        id="searchTeacherForm"
        className=""
        onSubmit={(e) => {
          e.preventDefault();
          searchTeacher();
        }}
      >
        <input
          name="search"
          ref={keyRef}
          placeholder="Enter Teacher's Code/Name"
          className="form-control"
        />
        <br />
        <button id="submit" type="submit" className="btn btn-success">
          Search
        </button>
      </form>
      {/* Display search results if any else nothing */}
      {viewSearchedTeachers > 0 && (
        <h6 className="text-danger mt-1">Results: {teachers.length}</h6>
      )}
      {/* View Teachers Form */}
      {loading && (
        <TailSpin
          height="60"
          width="60"
          color="rgb(183, 71, 221)" //"#4fa94d"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            top: "50%",
            left: "50%",
            position: "absolute",
            transform: "translate(-50%, -50%)",
          }}
          wrapperClass=""
          visible={true}
        />
      )}
      {allTeachers?.length < 1 ? (
        <h5 className="text-success text-center loaders">
          No Teacher Data Available
        </h5>
      ) : (
        <table className="layout">
          <thead>
            <tr>
              <th>#</th>
              <th>Code</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Books</th>
              <th>Issue</th>
              <th>Action</th>
            </tr>
          </thead>
          {viewSearchedTeachers ? (
            <tbody>
              {teachers?.map((teacher, index) => (
                <tr key={teacher.id}>
                  <td>{index + 1}</td>
                  {editTeacherData && editTeacherData.id === teacher.id ? (
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        value={editTeacherData.code}
                        onChange={(e) =>
                          setEditTeacherData({
                            ...editTeacherData,
                            code: e.target.value,
                          })
                        }
                      />
                    </td>
                  ) : (
                    <td>{teacher.code}</td>
                  )}
                  {editTeacherData && editTeacherData.id === teacher.id ? (
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        value={editTeacherData.name}
                        onChange={(e) =>
                          setEditTeacherData({
                            ...editTeacherData,
                            name: e.target.value,
                          })
                        }
                      />
                    </td>
                  ) : (
                    <td>{teacher.name}</td>
                  )}

                  {editTeacherData && editTeacherData.id === teacher.id ? (
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        value={editTeacherData.phone}
                        onChange={(e) =>
                          setEditTeacherData({
                            ...editTeacherData,
                            phone: e.target.value,
                          })
                        }
                      />
                    </td>
                  ) : (
                    <td>{teacher.phone}</td>
                  )}
                  <td>
                    <Link
                      title="View details of all borrowed books"
                      className="link"
                      to={{
                        pathname: `borrowed/${teacher.id}`,
                      }}
                      state={{ teacher: teacher }}
                    >
                      {teacher.books}
                    </Link>
                  </td>

                  <td>
                    <IssueTeacher
                      getAvailableBooks={getAvailableBooks}
                      availableBooks={availableBooks}
                      setAvailableBooks={setAvailableBooks}
                      teacher={teacher}
                      subjects={subjects}
                      getTeachers={getTeachers}
                    />
                    <Link
                      title={`Issue books to ${teacher.name}`}
                      data-toggle="modal"
                      data-target={`#issueModal${teacher.code}`}
                      className="link"
                    >
                      Issue
                    </Link>
                  </td>
                  {/* Added */}
                  <td>
                    {editTeacherData && editTeacherData.id === teacher.id ? (
                      <small>
                        <Link
                          className="link btn btn-primry btn-sm"
                          onClick={() => {
                            editTeacher(editTeacherData.id);
                          }}
                        >
                          <BiCheckCircle /> Save
                        </Link>
                        <Link
                          className="link btn btn-primry btn-sm"
                          onClick={() => setEditTeacherData(null)}
                        >
                          <BiXCircle /> Cancel
                        </Link>
                      </small>
                    ) : (
                      <Link
                        className=" link "
                        title={`Update ${teacher.name}`}
                        onClick={() => {
                          editMyTeacher(teacher);
                        }}
                      >
                        <BiPencil />
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              {allTeachers?.map((teacher, index) => (
                <tr key={teacher.id}>
                  <td>{index + 1}</td>

                  {editTeacherData && editTeacherData.id === teacher.id ? (
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        value={editTeacherData.code}
                        onChange={(e) =>
                          setEditTeacherData({
                            ...editTeacherData,
                            code: e.target.value,
                          })
                        }
                      />
                    </td>
                  ) : (
                    <td>{teacher.code}</td>
                  )}
                  {editTeacherData && editTeacherData.id === teacher.id ? (
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        value={editTeacherData.name}
                        onChange={(e) =>
                          setEditTeacherData({
                            ...editTeacherData,
                            name: e.target.value,
                          })
                        }
                      />
                    </td>
                  ) : (
                    <td>{teacher.name}</td>
                  )}

                  {editTeacherData && editTeacherData.id === teacher.id ? (
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        value={editTeacherData.phone}
                        onChange={(e) =>
                          setEditTeacherData({
                            ...editTeacherData,
                            phone: e.target.value,
                          })
                        }
                      />
                    </td>
                  ) : (
                    <td>{teacher.phone}</td>
                  )}
                  <td title="View details of all borrowed books">
                    <Link
                      className="link"
                      to={{
                        pathname: `borrowed/${teacher.id}`,
                      }}
                      state={{ teacher: teacher }}
                    >
                      {teacher.books}
                    </Link>
                  </td>

                  <td>
                    <IssueTeacher
                      teacher={teacher}
                      subjects={subjects}
                      getTeachers={getTeachers}
                      getAvailableBooks={getAvailableBooks}
                      availableBooks={availableBooks}
                      setAvailableBooks={setAvailableBooks}
                    />
                    <Link
                      title={`Issue books to ${teacher.name}`}
                      data-toggle="modal"
                      data-target={`#issueModal${teacher.code}`}
                      className="link"
                    >
                      Issue
                    </Link>
                  </td>
                  {/* Added */}
                  <td>
                    {editTeacherData && editTeacherData.id === teacher.id ? (
                      <small>
                        <Link
                          className="link btn btn-primry btn-sm"
                          onClick={() => {
                            editTeacher(editTeacherData.id);
                          }}
                        >
                          <BiCheckCircle /> Save
                        </Link>
                        <Link
                          className="link btn btn-primry btn-sm"
                          onClick={() => setEditTeacherData(null)}
                        >
                          <BiXCircle /> Cancel
                        </Link>
                      </small>
                    ) : (
                      <Link
                        className=" link "
                        title={`Update ${teacher.name}`}
                        onClick={() => {
                          editMyTeacher(teacher);
                        }}
                      >
                        <BiPencil />
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      )}
    </div>
  );
};
export default Teachers;
