import "./index.css";
import React, { useState } from "react";
import Teachers from "./components/Teachers.js";
import Home from "./components/Home";
import Layout from "./components/partials/Navbar";
import Unsubscribed from "./components/Unsubscribed";
import UnsubscribedNav from "./components/partials/NavbarUnsubscribed";
import Books from "./components/Books";
import AppInfo from "./components/adminPages/AppInfo";
import ContactInfo from "./components/adminPages/ContactInfo";
import Klasses from "./components/adminPages/Klasses";
import Subjects from "./components/adminPages/Subjects";
import Feedback from "./components/adminPages/Feedback";
import About from "./components/adminPages/About";
import Metadata from "./components/adminPages/Metadata";
import Users from "./components/adminPages/Users";
import AdminDashboard from "./components/adminPages/AdminDashboard";
import Graduate from "./components/Graduate";
import Students from "./components/Students";
import "bootstrap/dist/css/bootstrap.min.css";
import Schools from "./components/adminPages/Schools";
import Mpesa from "./components/mpesa/Mpesa.js";

import Borrowed from "./components/Borrowed";
import TBorrowed from "./components/TBorrowed";
import Landing from "./components/Landing";
import Nav from "./components/partials/Nav";

import Invoice from "./components/partials/Invoice";
import Pricing from "./components/partials/Pricing";
import Register from "./components/auth/Register";
import { Routes, Route } from "react-router-dom";
import RequireAuth from "./components/RequireAuth";
import Graduates from "./components/Graduates";

import BookDetails from "./components/BookDetails";
import PasswordReset from "./components/auth/ResetFirebasePassword";

import UpdatePassword from "./components/crud/UpdatePassword";
import StudentUploads from "./components/uploads/StudentUploads";
import BookUploads from "./components/uploads/BookUploads";
import IssueUploads from "./components/uploads/IssueUploads";
import SchoolsDetails from "./components/adminPages/SchoolsDetails";
import ChangePassword from "./components/auth/ChangeFirebasePassword";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { firebaseConfig } from "./methods/firebaseConfig";

firebase.initializeApp(firebaseConfig);

function App() {
  const [allStudentsCount, setAllStudentsCount] = useState(null);
  const [allBooksCount, setAllBooksCount] = useState(null);
  const [allStudentIssuesCount, setAllStudentIssuesCount] = useState(null);
  const [allTeacherIssuesCount, setAllTeacherIssuesCount] = useState(null);
  const [theSubject, setTheSubject] = useState([]);
  const [allStudents, setAllStudents] = useState([]);
  const [allTeachers, setAllTeachers] = useState(null);
  const [theSchool, setTheSchool] = useState([]); //Used to set a school for editing

  const [allBooks, setAllBooks] = useState([]);
  const [availableBooks, setAvailableBooks] = useState([]);
  return (
    <div className="App">
      <Routes id="main">
        <Route element={<RequireAuth />}>
          <Route path="admin" element={<AdminDashboard />}>
            {" "}
            <Route path="register" element={<Register />} />
            <Route path="appinfo" element={<AppInfo />} />{" "}
            <Route path="contactinfo" element={<ContactInfo />} />
            <Route path="about" element={<About />} />
            <Route path="feedback" element={<Feedback />} />{" "}
            <Route path="klasses" element={<Klasses />} />{" "}
            <Route path="subjects" element={<Subjects />} />
            <Route path="prices" element={<Pricing />} />
            <Route path="users" element={<Users />} />{" "}
            <Route path="meta" element={<Metadata />} />{" "}
            <Route path="school/details/:id" element={<SchoolsDetails />} />
            <Route
              path="schools"
              element={
                <Schools theSchool={theSchool} setTheSchool={setTheSchool} />
              }
            />
          </Route>
          <Route element={<Layout />}>
            <Route path="/change-password" element={<ChangePassword />} />
            <Route
              path="/home"
              element={
                <Home
                  allBooks={allBooks}
                  setAllBooks={setAllBooks}
                  availableBooks={availableBooks}
                  setAvailableBooks={setAvailableBooks}
                  allTeachers={allTeachers}
                  setAllTeachers={setAllTeachers}
                  allStudents={allStudents}
                  setAllStudents={setAllStudents}
                  allBooksCount={allBooksCount}
                  allStudentsCount={allStudentsCount}
                  setAllStudentsCount={setAllStudentsCount}
                  setAllBooksCount={setAllBooksCount}
                  allTeacherIssuesCount={allTeacherIssuesCount}
                  setAllTeacherIssuesCount={setAllTeacherIssuesCount}
                  allStudentIssuesCount={allStudentIssuesCount}
                  setAllStudentIssuesCount={setAllStudentIssuesCount}
                />
              }
            />
            <Route
              path="books"
              element={
                <Books
                  availableBooks={availableBooks}
                  setAvailableBooks={setAvailableBooks}
                  allBooks={allBooks}
                  setAllBooks={setAllBooks}
                  allBooksCount={allBooksCount}
                  theSubject={theSubject}
                  setTheSubject={setTheSubject}
                  setAllBooksCount={setAllBooksCount}
                />
              }
            />
            <Route
              path="students/graduates"
              element={
                <Graduates
                  allBooks={allBooks}
                  availableBooks={availableBooks}
                  setAvailableBooks={setAvailableBooks}
                />
              }
            />
            <Route path="students/graduate" element={<Graduate />} />

            <Route
              path="book/bookId/:id"
              element={<BookDetails setAllBooks={setAllBooks} />}
            />
            <Route
              path="students/uploads"
              element={<StudentUploads setAllStudents={setAllStudents} />}
            />
            <Route
              path="books/uploads"
              element={<BookUploads setAllBooks={setAllBooks} />}
            />
            <Route
              path="students/issues/uploads"
              element={<IssueUploads setAllStudents={setAllStudents} />}
            />
            <Route path="/pricing1" element={<Pricing />} />
            <Route path="/invoice1" element={<Invoice />} />
            {/* <Route path="issue/bookId/:id" element={<IssueDetails />} />
            <Route path="issue/bookId/borrowers/:id" element={<ManyIssueDetails />} /> */}

            <Route
              path="students/borrowed/:id"
              element={
                <Borrowed
                  allBooks={allBooks}
                  availableBooks={availableBooks}
                  setAvailableBooks={setAvailableBooks}
                  setAllBooks={setAllBooks}
                  allStudents={allStudents}
                  setAllStudents={setAllStudents}
                  allStudentIssuesCount={allStudentIssuesCount}
                  setAllStudentIssuesCount={setAllStudentIssuesCount}
                />
              }
            />
            <Route
              path="students"
              element={
                <Students
                  availableBooks={availableBooks}
                  setAvailableBooks={setAvailableBooks}
                  allBooks={allBooks}
                  setAllBooks={setAllBooks}
                  allStudents={allStudents}
                  setAllStudents={setAllStudents}
                  allStudentIssuesCount={allStudentIssuesCount}
                  setAllStudentIssuesCount={setAllStudentIssuesCount}
                  setAllStudentsCount={setAllStudentsCount}
                  allStudentsCount={allStudentsCount}
                />
              }
            />
            <Route
              path="teachers"
              element={
                <Teachers
                  availableBooks={availableBooks}
                  setAvailableBooks={setAvailableBooks}
                  allTeachers={allTeachers}
                  setAllTeachers={setAllTeachers}
                  allBooks={allBooks}
                  setAllBooks={setAllBooks}
                  allTeacherIssuesCount={allTeacherIssuesCount}
                  setAllTeacherIssuesCount={setAllTeacherIssuesCount}
                />
              }
            />
            <Route
              path="teachers/borrowed/:id"
              element={
                <TBorrowed
                  availableBooks={availableBooks}
                  setAvailableBooks={setAvailableBooks}
                  allTeachers={allTeachers}
                  setAllTeachers={setAllTeachers}
                  allBooks={allBooks}
                  setAllBooks={setAllBooks}
                  allTeacherIssuesCount={allTeacherIssuesCount}
                  setAllTeacherIssuesCount={setAllTeacherIssuesCount}
                />
              }
            />
            <Route path="update/password" element={<UpdatePassword />} />
          </Route>
          <Route element={<UnsubscribedNav />}>
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/invoice" element={<Invoice />} />
            <Route path="/unsubscribed" element={<Unsubscribed />} />
          </Route>
        </Route>
        <Route path="reset-password" element={<PasswordReset />} />

        <Route element={<Nav />}>
          <Route index element={<Landing />} />
          <Route path="donate" element={<Mpesa />} />
        </Route>
      </Routes>
    </div>
  );
}
export default App;
