import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import { baseURL } from "../../methods/baseUrl";
import { Button, LinearProgress } from "@mui/material";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
import useAuth from "../hooks/useAuth";
import { BiPencil, BiXCircle, BiCheckCircle, BiTrash } from "react-icons/bi";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { Typography } from "@mui/material";

const ContactInfo = () => {
  const [contactInfo, setContactInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [contactInfoData, setContactInfoData] = useState(null);
  const { auth } = useAuth();

  const getContactInfo = async () => {
    try {
      const response = await Axios.get(baseURL + "/contactinfo");
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setLoading(false);
        setContactInfo(response.data);
      }
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  const editAppInfo = async (id, values) => {
    try {
      const { phone, email, whatsapp, twitter, linkedin, telegram, github } =
        values;
      const response = await Axios.put(baseURL + `/update/contactinfo/${id}`, {
        phone,
        email,
        whatsapp,
        twitter,
        linkedin,
        telegram,
        github,
      });
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setContactInfoData(null);
        getContactInfo();
        showSuccessMsg(response.data.success);
      }
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  const setMyAppInfoData = (data) => {
    setContactInfoData(data);
  };

  const deleteContactInfo = async (id) => {
    try {
      await Axios.delete(baseURL + `/delete/contactinfo/${id}`).then(
        (response) => {
          if (response.data.msg) {
            showErrorMsg(response.data.msg);
          } else {
            getContactInfo();
            showSuccessMsg(response.data.success);
          }
        }
      );
    } catch (error) {
      showErrorMsg(error.message);
    }
  };

  useEffect(() => {
    document.title = "LLR | Contact Info";
    getContactInfo();
  }, []);

  return (
    <div className="container bg-white p-4">
      {auth?.is_superuser ? (
        <>
          <h4
            style={{ fontFamily: "NovaOval" }}
            className="text-success text-center m-3"
          >
            CONTACT INFORMATION
            {/* <Button
              className="float-left"
              variant="text"
              onClick={() => navigate(-1)}
            >
              Back
            </Button> */}
          </h4>
          <hr />
          {loading ? (
            <LinearProgress className="link" />
          ) : (
            <Formik
              enableReinitialize
              initialValues={{
                phone: contactInfoData ? contactInfoData.phone : "",
                email: contactInfoData ? contactInfoData.email : "",
                twitter: contactInfoData ? contactInfoData.twitter : "",
                whatsapp: contactInfoData ? contactInfoData.whatsapp : "",
                telegram: contactInfoData ? contactInfoData.telegram : "",
                linkedin: contactInfoData ? contactInfoData.linkedin : "",
                github: contactInfoData ? contactInfoData.github : "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                editAppInfo(contactInfoData.id, values)
                  .then(() => setSubmitting(false))
                  .catch(() => setSubmitting(false));
              }}
            >
              {({ values, isSubmitting }) => (
                <Form>
                  {contactInfo.map((info) => (
                    <div key={info.id}>
                      {!contactInfoData ||
                      (contactInfoData && contactInfoData.id !== info.id) ? (
                        <>
                          <h4 style={{ fontFamily: "Play" }}>
                            {info.title}
                            <Link
                              className="link float-right"
                              onClick={() => {
                                setMyAppInfoData(info);
                              }}
                            >
                              <BiPencil size={18} />
                            </Link>
                          </h4>
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: info.phone,
                            }}
                            variant="h6"
                            fontFamily="Play"
                          ></Typography>
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: info.email,
                            }}
                            variant="h6"
                            fontFamily="Play"
                          ></Typography>
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: info.whatsapp,
                            }}
                            variant="h6"
                            fontFamily="Play"
                          ></Typography>
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: info.twitter,
                            }}
                            variant="h6"
                            fontFamily="Play"
                          ></Typography>
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: info.linkedin,
                            }}
                            variant="h6"
                            fontFamily="Play"
                          ></Typography>
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: info.telegram,
                            }}
                            variant="h6"
                            fontFamily="Play"
                          ></Typography>
                          <Typography
                            dangerouslySetInnerHTML={{
                              __html: info.github,
                            }}
                            variant="h6"
                            fontFamily="Exo"
                          ></Typography>
                        </>
                      ) : (
                        <>
                          <h4 style={{ fontFamily: "Chakra" }}>
                            <small>
                              <Link
                                className="link float-right"
                                onClick={() => setContactInfoData(null)}
                              >
                                <BiXCircle className="text-danger" size={24} />
                              </Link>
                              <Button
                                variant="text"
                                type="submit"
                                className=" mr-3 float-right"
                                disabled={isSubmitting}
                              >
                                <BiCheckCircle
                                  className="text-success"
                                  size={24}
                                />
                              </Button>
                              <br />
                              <br />
                              <Field
                                component={TextField}
                                className="mt-3"
                                name="phone"
                                label="Phone"
                                fullWidth
                              />
                              <Field
                                component={TextField}
                                className="mt-3"
                                name="email"
                                label="Email"
                                fullWidth
                              />
                              <Field
                                component={TextField}
                                className="mt-3"
                                name="whatsapp"
                                label="Whatsapp"
                                fullWidth
                              />
                              <Field
                                component={TextField}
                                className="mt-3"
                                name="twitter"
                                label="Twitter"
                                fullWidth
                              />
                              <Field
                                component={TextField}
                                className="mt-3"
                                name="linkedin"
                                label="Linkedin"
                                fullWidth
                              />
                              <Field
                                component={TextField}
                                className="mt-3"
                                name="telegram"
                                label="Telegram"
                                fullWidth
                              />
                              <Field
                                className="mt-3"
                                component={TextField}
                                name="github"
                                label="Github"
                                // multiline
                                // rows={2}
                                fullWidth
                              />
                            </small>
                          </h4>
                        </>
                      )}{" "}
                      <hr />
                    </div>
                  ))}
                </Form>
              )}
            </Formik>
          )}
        </>
      ) : (
        <h3 className="text-center text-success m-4 p-4">
          Unauthorised Access
        </h3>
      )}
    </div>
  );
};

export default ContactInfo;
