import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { showErrorMsg, showSuccessMsg } from "../methods/alertMsgs";
import Axios from "axios";
import { TailSpin } from "react-loader-spinner";
import Toolbar from "@mui/material/Toolbar";
import useAuth from "./hooks/useAuth";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { baseURL } from "../methods/baseUrl";
import useDocumentTitle from "./hooks/useDocumentTitle";
import IssueBook from "./crud/IssueBook";
import { capitalized } from "../methods/eventHandlers";

import { BiUpload, BiPlus, BiDownload } from "react-icons/bi";

const Books = ({ allBooks, setAllBooks, theSubject, setTheSubject }) => {
  const [subjects, setSubjects] = useState([]);
  const [allSubjects, setAllSubjects] = useState([]);

  const { auth } = useAuth();
  const sch_category = auth?.sch_category;
  const mySchool = auth?.school_id;
  const [bookFound, setBookFound] = useState("Search Results will appear here");
  const searchBook = useRef();
  const [books, setBooks] = useState([]);
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [theBook, setTheBook] = useState([]);
  const [klasses, setKlasses] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [showSubject, setShowSubject] = useState(false);
  const [loaderView, setLoaderView] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const subjectRef = useRef();
  const categoryRef = useRef();
  const regRef = useRef();
  const bookNameRef = useRef();
  const detailRef = useRef();
  const priceRef = useRef();
  const authorRef = useRef();
  const editionRef = useRef();
  const bClassRef = useRef();
  const schoolId = mySchool;
  const noOfBooks = 1;
  const misplaced = false;

  // Get book categories
  const getCategories = async () => {
    /**
     * Category is either govt or sch
     * Called when adding books to the library
     */
    try {
      await Axios.get(baseURL + "/categories").then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setCategories(response.data);
        }
      });
    } catch (error) {
      showErrorMsg(error);
    }
  };

  // Get klasses
  const getKlasses = useCallback(async () => {
    try {
      await Axios.get(baseURL + `/klasses/${sch_category}`).then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setKlasses(response.data);
        }
      });
    } catch (error) {
      showErrorMsg(error);
    }
  }, [sch_category]);

  const addBook = async function () {
    /**
     * Defined in book.js
     * Adds a book to the library
     * Resets allBooksCount by 1, on success
     */
    try {
      const category = categoryRef.current.value;
      const regNo = regRef.current.value;
      const bookName = capitalized(bookNameRef.current.value);
      const bookDetail = detailRef.current.value;
      const bookPrice = priceRef.current.value;
      const author = capitalized(priceRef.current.value);
      const edition = editionRef.current.value;
      const bClass = bClassRef.current.value;
      const subjectId = subjectRef.current.value;

      const body = {
        category,
        regNo,
        bookName,
        noOfBooks,
        bookDetail,
        bookPrice,
        author,
        edition,
        misplaced,
        bClass,
        schoolId,
        subjectId,
      };

      await Axios.post(baseURL + "/add/book", body).then((response) => {
        if (response.data.success) {
          // setAllBooksCount(parseInt(allBooksCount) + 1);
          showSuccessMsg(response.data.success);
          getAllBooks();
          // setBooks(allBooks.filter((book) => book.sub_id == subjectId));
          getBooksPerSubject(subjectId);
        } else {
          if (
            response.data.msg.includes(
              "duplicate key value violates unique constraint"
            )
          ) {
            showErrorMsg(`Book Reg No ${regNo} already exists`);
          } else {
            showErrorMsg(response.data.msg);
          }
        }
      });
    } catch (error) {
      showErrorMsg(error);
    }
  };

  const getSubjects = useCallback(async () => {
    /**
     * Generate only the subjects with atleast a book
     */
    await Axios.get(
      baseURL + `/subjects/with/books/${mySchool}/${sch_category}`
    ).then((response) => {
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setSubjects(response.data);
        setLoading(false);
      }
    });
  }, [mySchool, sch_category]);

  const getAllSubjects = async () => {
    /**
     * Generate all subject
     * Used when adding a new book
     * The generted subjects belong to a
     *  category as determined by logged in user
     */
    if (allSubjects.length < 1) {
      //If there's nothing stored in state then search db
      await Axios.get(baseURL + `/subjects/${sch_category}`).then(
        (response) => {
          if (response.data.msg) {
            showErrorMsg(response.data.msg);
          } else {
            setAllSubjects(response.data);
          }
        }
      );
    }
  };

  const getAllBooks = useCallback(async () => {
    // alert("Setting allBooks in Books.js");

    /**
     * Generate all the books in a school, borrowed and not borrowed
     * Here, it is called to help regenerate the count of books upon refresh
     */
    await Axios.get(baseURL + `/books/${mySchool}`).then((response) => {
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setAllBooks(response.data);
        // setAllBooksCount(response.data.length);
        // setLoading(false);
      }
    });
  }, [mySchool, setAllBooks]);

  //getBooksPerSubject
  const getBooksPerSubject = async (subject_id) => {
    try {
      setLoaderView(true);
      await Axios.get(baseURL + `/books/${mySchool}/${subject_id}`).then(
        (response) => {
          if (response.data.msg) {
            showErrorMsg(response.data.msg);
          } else {
            setBooks(response.data);
          }
        }
      );
    } catch (error) {
      showErrorMsg(error.message);
    } finally {
      setLoaderView(false);
    }
  };
  // Search Books
  const searchBooks = async () => {
    try {
      const key = searchBook.current.value;
      setSearchKey(key);

      if (key) {
        await Axios.get(
          baseURL + `/books/search/${encodeURIComponent(key)}/${mySchool}`
        ).then((response) => {
          if (response.data.msg) {
            showErrorMsg(response.data.msg);
          } else {
            if (response.data.length < 1) {
              setBookFound("No book(s) found for " + key);
              setBooks(0);
            } else {
              setBookFound(null);
              setBooks(response.data);
            }
          }
        });
      } else {
        setBooks(0);
        setBookFound("Search Results will appear here");
      }
    } catch (error) {
      showErrorMsg(error);
    }
  };

  const downloadBooks = async () => {
    try {
      await Axios.get(baseURL + `/books/download/${mySchool}/${mySchool}`, {
        responseType: "blob",
      }).then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          const downloadUrl = URL.createObjectURL(response.data);
          // alert(downloadUrl);
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", "books.xlsx");
          // link.download = "borrowed_books.xlsx";
          link.click();
          URL.revokeObjectURL(downloadUrl);
        }
      });
    } catch (error) {
      console.error("Error downloading the file:", error.message);
      showErrorMsg(error.message);
    }
  };

  // This effect runs when the selectedOption changes
  useEffect(() => {
    getKlasses();
  }, [getKlasses]);

  useEffect(() => {
    // If there is data, the form is valid
    setIsValid(selectedOption.length > 0 ? true : false);
  }, [selectedOption]);
  useDocumentTitle("Books");
  // Runs everytime the page loads
  useEffect(() => {
    try {
      getSubjects();
      if (allBooks.length < 1) {
        /**
         * Uses .length since t is defined as an array in App.js
         * This is a condition arrived at incase of a refresh
         * */
        getAllBooks();
      }
      // if (theSubject) {
      //     //Retain data incase out of page
      //     getSubjectBooks(theSubject.id);
      // }
      setBookFound(bookFound);
    } catch (error) {
      showErrorMsg(error.message);
    }
  }, [bookFound, getSubjects, allBooks, getAllBooks]);

  // JSX
  return (
    <div className="container">
      <div className="text-center text-success m-3">
        <h3 style={{ marginBottom: "1px" }}>BOOKS - {allBooks.length}</h3>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="text"
            className="float-left"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
          <div>
            <Button
              title={"Add book"}
              className="float-right"
              data-toggle="modal"
              data-target="#add"
              variant="text"
              onClick={() => {
                getCategories();
                getAllSubjects();
              }}
            >
              <BiPlus size={20} />
              Book
            </Button>
            <Link
              to={{
                pathname: `uploads`,
              }}
            >
              <Button
                title={"Upload books from an excel file"}
                className="float-right"
                variant="text"
              >
                <BiUpload size={20} />
              </Button>
            </Link>
            <Link>
              <Button
                onClick={() => downloadBooks()}
                title={"Download Books"}
                className="float-right"
                variant="text"
              >
                <BiDownload size={20} />
              </Button>
            </Link>
          </div>
        </div>
      </div>

      {loading ? (
        <TailSpin
          height="60"
          width="60"
          color="rgb(183, 71, 221)" //"#4fa94d"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            top: "50%",
            left: "50%",
            position: "absolute",
            transform: "translate(-50%, -50%)",
          }}
          wrapperClass=""
          visible={true}
        />
      ) : (
        <div>
          <form
            id="searchStudentForm" //Should be searchBookForm
            className="d-flex"
            onSubmit={(e) => {
              e.preventDefault();
              searchBooks();
              setShowSearch(true);
              setShowSubject(false);
            }}
          >
            <input
              ref={searchBook}
              className="col-9 form-control"
              type="text"
              placeholder="Book Name/Reg No"
            />
            <button id="submit" className="col-3 btn btn-success">
              Search
            </button>
          </form>
          <br />
          <div className="d-flex row form-control m-auto">
            {subjects.map((subject) => (
              <div key={subject.id} className="col-auto p-1">
                <Link
                  className="btn btn-success"
                  // to={{ pathname: `/subject/${subject.id}` }}
                  onClick={() => {
                    setTheSubject(subject);
                    getBooksPerSubject(subject.id);
                    // callSetBooks();
                    // setBooks(
                    //   allBooks.filter((book) => book.sub_id === subject.id)
                    // );
                    //Used instead of calling a whole function
                    setShowSubject(true);

                    setShowSearch(false);
                  }}
                  id="submit"
                >
                  {capitalized(subject.name)}
                </Link>
              </div>
            ))}
          </div>
          <div>
            {books?.length > 0 ? (
              <div>
                <div className="m-2">
                  {showSearch && (
                    <div>
                      {" "}
                      <h6 className="text-center">
                        Search Results for{" "}
                        <small className="text-danger">
                          <b>{searchKey}</b>
                        </small>{" "}
                        - <b>{books.length}</b>
                      </h6>
                    </div>
                  )}
                  {showSubject && (
                    <h6 className="text-center">
                      <h6 className="text-center">
                        {capitalized(theSubject.name)} - {books.length}
                      </h6>
                    </h6>
                  )}
                </div>
                {loaderView ? (
                  <TailSpin
                    height="50"
                    width="70"
                    // height="30"
                    // width="50"
                    color="rgb(183, 71, 221)" //"#4fa94d"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{
                      top: "50%",
                      left: "50%",
                      position: "relative",
                    }}
                    wrapperClass=""
                    visible={true}
                  />
                ) : (
                  <table className="layout">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Reg No</th>
                        <th>Class</th>
                        {
                          !showSubject && (
                            <th>Subject</th>
                          ) /**Do not show this column if its a subject clicked */
                        }
                        <th>Category</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {books?.map((book, index) => (
                        <tr key={book.id}>
                          <td>{index + 1}</td>
                          <td id="borrowed" title="See book details">
                            <Link
                              className="link"
                              to={`/book/bookId/${book.id}`}
                            >
                              {capitalized(book.book_name)}
                            </Link>
                          </td>
                          {/* // )} */}
                          <td>{book.reg_no}</td>
                          <td>{book.klass_id}</td>
                          {!showSubject && <td>{capitalized(book.subject)}</td>}
                          <td>{capitalized(book.name)}</td>
                          {book.no_of_books < 15 ? (
                            <td title="View issue details">
                              <Link
                                to={`/book/bookId/${book.id}`}
                                className="link text-success "
                              >
                                <RemoveRedEyeIcon />
                              </Link>
                              {/* <IssueBook book={book} /> */}
                            </td>
                          ) : (
                            <td id="borrowed">
                              <Link
                                className=" link"
                                data-toggle="modal"
                                data-target={`#id${book.id}`}
                                title="Issue out the book"
                                variant="text"
                                onClick={() => {
                                  //   // getKlasses();
                                  setTheBook(book);
                                }}
                              >
                                Issue
                              </Link>{" "}
                              <IssueBook
                                book={book}
                                getBooksPerSubject={getBooksPerSubject}
                              />{" "}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                <br />
              </div>
            ) : (
              <h6 className="text-center text-deactivated text-success m-3">
                {" "}
                {bookFound}
              </h6>
            )}
          </div>
        </div>
      )}

      {/* Add a book */}
      <div className="modal" id="add">
        <Toolbar />
        <div className="modal-dialog">
          <div className="modal-content w-80">
            <div className="modal-header">
              <h4 className="modal-title text-success text-center">Add Book</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div className="modal-body">
              <div className="row">
                <label className="col-8">
                  Book Name
                  <input
                    ref={bookNameRef}
                    placeholder="Book Name"
                    className="form-control mb-1"
                    type="text"
                  />
                </label>
                <label className="col-4">
                  Subject
                  <select ref={subjectRef} className="form-control">
                    <option hidden defaultValue>
                      --Select Subject--
                    </option>
                    {allSubjects.map((subject) => (
                      <option key={subject.id} value={subject.id}>
                        {subject.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="row">
                <label className="col-8">
                  Reg No
                  <input
                    ref={regRef}
                    placeholder="Book Reg No"
                    className="form-control mb-1"
                    type="text"
                  />
                </label>
                <label className="col-4">
                  Class
                  <select ref={bClassRef} className="form-control">
                    <option hidden defaultValue>
                      --
                    </option>
                    {klasses.map((klass) => (
                      <option key={klass.id} value={klass.id}>
                        {klass.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <label className="w-50">
                Book Category
                <select ref={categoryRef} className="form-control">
                  <option hidden defaultValue>
                    --Select Category--
                  </option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </label>
              <label className="w-50">
                Author
                <input
                  ref={authorRef}
                  type={"text"}
                  className="form-control"
                  placeholder="Author Name"
                />
              </label>

              <label className="w-100">
                Book Details
                <input
                  ref={detailRef}
                  type={"text"}
                  className="form-control"
                  placeholder="Book's Details"
                />
              </label>
              <div className="row ">
                <label className="col-6">
                  Book Price
                  <input
                    ref={priceRef}
                    type={"text"}
                    className="form-control"
                    defaultValue={0}
                  />
                </label>
                <label className="col-6">
                  Edition
                  <input
                    ref={editionRef}
                    type={"text"}
                    className="form-control"
                    defaultValue="N/A"
                  />
                </label>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger mr-auto"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={addBook}
                id="submit"
                className="text-white btn btn-outline-success ml-auto"
                data-dismiss="modal"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Books;
