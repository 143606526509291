import React, { useState } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import useAuth from "../../components/hooks/useAuth";
import swal from "sweetalert";
import { baseURL } from "../../methods/baseUrl";
import axios from "axios";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
import Footer from "../partials/Footer";
import logo from "../partials/logo/logo-white.gif";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";

import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({});

const UnsubscribedNav = () => {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const mySchool = auth?.school_name;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [activeItem, setActiveItem] = useState("");

  // Logout Function
  const logOut = async () => {
    try {
      const confirmedLogOut = await swal({
        title: "Confirm to log out",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (confirmedLogOut) {
        const auth = getAuth();
        try {
          await signOut(auth);

          setAuth(null);
          window.location.href = "/";

          showSuccessMsg("Successfully logged out");
        } catch (error) {
          showErrorMsg(error.message);
        }
      }
    } catch (error) {
      showErrorMsg(error.message);
    }
  };
  
  // const logOut = async () => {
  //   const confirmedLogOut = await swal({
  //     title: "Confirm to log out",
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true,
  //   });

  //   if (confirmedLogOut) {
  //     try {
  //       const response = await axios.delete(baseURL + "/logout", {
  //         withCredentials: true,
  //       });
  //       if (response.data.msg) {
  //         showErrorMsg(response.data.msg);
  //       } else {
  //         setAuth(null);
  //         window.location.href = "/";
  //         // navigate("/");
  //       }
  //     } catch (error) {
  //       showErrorMsg(error.message);
  //     }
  //   }
  // };

  const navbarStyle = {
    backgroundColor: "rgb(173, 83, 202)",
    position: "sticky",
    top: 0,
    zIndex: 1000,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 16px",
  };

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  return (
    <ThemeProvider theme={theme}>
      <nav style={navbarStyle} className="navbar navbar-expand-lg navbar-light">
        {auth.is_superuser ? (
          <>
            <img
              src={logo}
              style={{ width: "70px", height: "50px" }}
              alt="logo"
            />
          </>
        ) : (
          <Link className="ml-3 navbar-brand text-white" to={"/unsubscribed"}>
            {mySchool}
          </Link>
        )}
        {isMobileScreen ? (
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={() => setIsDrawerOpen(true)}
            sx={{ marginLeft: "auto" }}
          >
            <MenuIcon className="text-white" />
          </IconButton>
        ) : (
          <List className="navbar-nav">
            <ListItemButton
              key="pricing"
              component={Link}
              to="/pricing"
              selected={activeItem === "pricing"}
              onClick={() => handleItemClick("pricing")}
            >
              <ListItemText className="text-white" primary="Pricing" />
            </ListItemButton>

            <ListItemButton onClick={logOut}>
              <ListItemText className="text-white" primary="Logout" />
            </ListItemButton>
          </List>
        )}
      </nav>
      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <List>
          <ListItemButton className="bg-grey">
            <ListItemText className={"link text-success"} primary="LLR MENU" />
          </ListItemButton>
          <hr />

          <ListItemButton
            key="pricing"
            component={Link}
            to="/pricing"
            selected={activeItem === "pricing"}
            onClick={() => {
              setIsDrawerOpen(false);
              handleItemClick("pricing");
            }}
          >
            <ListItemText primary="Pricing" className="menu-item" />
          </ListItemButton>

          <ListItemButton onClick={logOut}>
            <ListItemText primary="Logout" className="menu-item" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            onClick={() => setIsDrawerOpen(false)}
          >
            <ListItemText primary="Close" className="menu-item" />
          </ListItemButton>
        </List>
      </Drawer>

      <Outlet />
      <Footer />
    </ThemeProvider>
  );
};

export default UnsubscribedNav;
