import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import Axios from "axios";
import { showErrorMsg, showSuccessMsg } from "../methods/alertMsgs";
import { TailSpin } from "react-loader-spinner";
import IssueStudent from "./crud/IssueStudent";
import { baseURL } from "../methods/baseUrl";
import useDocumentTitle from "./hooks/useDocumentTitle";
import useAuth from "./hooks/useAuth";
import swal from "sweetalert";
import { capitalized } from "../methods/eventHandlers";
import { toUpper } from "../methods/eventHandlers";
import { BiTrash, BiDownload } from "react-icons/bi";

const Borrowed = ({
  allBooks,
  availableBooks,
  setAvailableBooks,
  setAllStudents,
  allStudentIssuesCount,
  setAllStudentIssuesCount,
}) => {
  const { auth } = useAuth();
  const [student, setStudent] = useState();
  const navigate = useNavigate();
  const theId = useParams();
  const stdId = theId.id; //Used after refresh to getStudent() and getIssues()
  const [issueCount, setIssueCount] = useState(student?.books);
  const [selectedIssues, setSelectedIssues] = useState([]);
  const [issues, setIssues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const sch_category = auth?.sch_category;
  const mySchool = auth?.school_id;
  const [issueHistory, setIssueHistory] = useState([]);
  const [history, setHistory] = useState(null);
  const [returning, setReturning] = useState(false);

  const handleChange = (e) => {
    const issueId = parseInt(e.target.value);
    const clickedIssue = issues.find((issue) => issue.b_id === issueId);

    if (e.target.checked) {
      setHistory([
        clickedIssue.id,
        clickedIssue.issue_date,
        clickedIssue.return_date,
        clickedIssue.b_name,
        clickedIssue.b_reg,
        clickedIssue.s_id,
        clickedIssue.klass_name,
        clickedIssue.user,
      ]);
      setSelectedIssues([...selectedIssues, issueId]);
    } else {
      setSelectedIssues(selectedIssues.filter((id) => id !== issueId));
    }
  };

  const getIssueHistory = useCallback(async () => {
    try {
      setLoading(true);
      await Axios.get(baseURL + `/issue/history/${stdId}`).then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setIssueHistory(response.data);
        }
      });
    } catch (error) {
      showErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  }, [stdId]);

  const getAllStudents = useCallback(async () => {
    // alert("Getting all students from Students.js");

    /**
     * Get all the details of all students in a school
     * This is to be stored and used everytime an issue is required
     */
    try {
      await Axios.get(baseURL + "/students/" + mySchool).then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setAllStudents(response.data);
          // setAllStudentsCount(response.data.length);
        }
      });
    } catch (error) {
      showErrorMsg(error);
    }
  }, [mySchool, setAllStudents]);

  const goBack = () => {
    /**
     * Goes back one stop onClick
     */
    navigate(-1);
  };

  // Get the books issued to a student_id
  // const getIssues = async (id) => {
  //   try {
  //     setLoading(true);
  //     /**
  //      * Get all books borrowed by a student of the specified id
  //      */
  //     await Axios.get(baseURL + `/student/borrowed/${id}`).then((response) => {
  //       if (response.data.msg) {
  //         alert(response.data.msg);
  //         showErrorMsg(response.data.msg);
  //       } else {
  //         setIssues(response.data);
  //         setIssueCount(response.data.length);
  //         // setLoading(false);
  //       }
  //     });
  //   } catch (error) {
  //     showErrorMsg(error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const getIssues = async (id) => {
    try {
      setLoading(true);
      /**
       * Get all books borrowed by a student of the specified id
       */
      const response = await Axios.get(baseURL + `/student/borrowed/${id}`);
      if (response.data.msg) {
        showErrorMsg(response.data.msg); // Consider improving the error message handling
      } else {
        setIssues(response.data);
        setIssueCount(response.data.length);
      }
    } catch (error) {
      showErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  };

  const returnBooks = async (selectedIssues) => {
    try {
      setReturning(true);
      let deletedCount = 0;
      let filteredIssues = [...issues];

      for (let i = 0; i < selectedIssues.length; i++) {
        const book_id = selectedIssues[i];
        const receiver = auth.username;
        const due_date = history[2];
        const return_date = new Date();
        const issue_date = history[1];
        const klass = history[6];
        const book_name = history[3];
        const book_reg = history[4];
        const issuer = history[7];
        const student_id = history[5];

        const body = {
          issuer,
          receiver,
          klass,
          issue_date,
          return_date,
          due_date,
          student_id,
          book_reg,
          book_name,
        };

        await Axios.post(baseURL + `/return/student/${book_id}`, body)
          .then((response) => {
            if (response.data.success) {
              deletedCount++;
              filteredIssues = filteredIssues.filter(
                (item) => item.b_id !== book_id
              );
              showSuccessMsg(response.data.success);
              getIssueHistory();
              getAvailableBooks();
            } else {
              showErrorMsg(response.data.msg);
            }
          })
          .catch((error) => {
            showErrorMsg(error);
          });
      }

      setIssues(filteredIssues);
      setAllStudentIssuesCount(parseInt(allStudentIssuesCount) - deletedCount);
      setIssueCount(filteredIssues.length);
      setSelectedIssues([]);
    } catch (error) {
      showErrorMsg(error.message);
    } finally {
      setReturning(false);
    }
  };

  const getSubjects = useCallback(async () => {
    /**
     * Generate only the subjects with no_of_books > 0
     * Called when in need to issue a book
     * where the subject is first selected before
     * the books that HAVE NOT BEEN ISSUED are populated
     */
    await Axios.get(
      baseURL + `/subjects/with/books/more/${mySchool}/${sch_category}`
    )
      .then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setSubjects(response.data);
        }
      })
      .catch((error) => {
        showErrorMsg(error.message);
      });
  }, [mySchool, sch_category]);

  // const getStudent = useCallback(
  //   async (id) => {
  //     setLoading(true);
  //     await Axios.get(baseURL + `/student/${id}`)
  //       .then((response) => {
  //         if (response.data.msg) {
  //           showErrorMsg(response.data.msg);
  //         } else {
  //           setStudent(response.data);
  //         }
  //       })
  //       .catch((error) => {
  //         showErrorMsg(error);
  //       })
  //       .finally(setLoading(false));
  //   },
  //   [setStudent]
  // );

  // const deleteStudent = (id) => {
  //   swal({
  //     title: "Press Ok to delete",
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true,
  //   }).then(async (confirmDelete) => {
  //     if (confirmDelete) {
  //       try {
  //         await Axios.delete(baseURL + `/delete/student/${id}`)
  //           .then((response) => {
  //             if (response.data.success) {
  //               getAllStudents();
  //               navigate("/students", { replace: true });

  //               showSuccessMsg(response.data.success);
  //             } else {
  //               showErrorMsg(response.data.msg);
  //             }
  //           })
  //           .catch((error) => {
  //             showErrorMsg(error.message);
  //           });
  //       } catch (error) {
  //         showErrorMsg(error.message);
  //       }
  //     }
  //   });
  // };

  const getStudent = useCallback(
    async (id) => {
      setLoading(true);
      try {
        const response = await Axios.get(baseURL + `/student/${id}`);
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setStudent(response.data);
        }
      } catch (error) {
        showErrorMsg(error.message);
      } finally {
        setLoading(false);
      }
    },
    [setStudent]
  );

  const deleteStudent = async (id) => {
    const confirmDelete = await swal({
      title: "Press Ok to delete",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (confirmDelete) {
      try {
        const response = await Axios.delete(baseURL + `/delete/student/${id}`);
        if (response.data.success) {
          getAllStudents();
          navigate("/students", { replace: true });
          showSuccessMsg(response.data.success);
        } else {
          showErrorMsg(response.data.msg);
        }
      } catch (error) {
        showErrorMsg(error.message);
      }
    }
  };

  const handleDownload = async () => {
    try {
      await Axios.get(baseURL + `/student/download/borrowed/${stdId}`, {
        responseType: "blob",
      }).then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          const downloadUrl = URL.createObjectURL(response.data);
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", `ADM - ${student.student_id}.xlsx`);
          link.click();
          URL.revokeObjectURL(downloadUrl);
        }
      });
    } catch (error) {
      console.error("Error downloading the file:", error.message);
    }
  };

  const getAvailableBooks = useCallback(async () => {
    try {
      const response = await Axios.get(
        baseURL + `/available/books/${mySchool}`
      );

      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setAvailableBooks(response.data);
      }
    } catch (error) {
      showErrorMsg(error);
    }
  }, [mySchool, setAvailableBooks]);

  const downloadIssueHistory = async () => {
    try {
      await Axios.get(baseURL + `/student/download/history/${stdId}`, {
        responseType: "blob",
      }).then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          const downloadUrl = URL.createObjectURL(response.data);
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", `ADM - ${student.student_id}.xlsx`);
          link.click();
          URL.revokeObjectURL(downloadUrl);
        }
      });
    } catch (error) {
      console.error("Error downloading the file:", error.message);
    }
  };

  useDocumentTitle("Borrowed");

  useEffect(() => {
    if (availableBooks.length === 0) {
      console.log("No books, regenerating");
      getAvailableBooks();
    }
  }, [availableBooks.length, getAvailableBooks]);

  useEffect(() => {
    getSubjects();
    getStudent(stdId);
    getIssues(stdId);
    getIssueHistory();
  }, [allBooks, getIssueHistory, getStudent, getSubjects, stdId]);

  return (
    <div className="container">
      <div className="mt-3">
        <div className=" text-success ">
          <h6 className="text-center text-success ">
            {student?.student_id} - {toUpper(student?.name)}
            <Button variant="text" className="float-left" onClick={goBack}>
              Back
            </Button>
            <small className="float-right text-left">
              {/* {issues.length > 0  && ( */}
              <Button
                disabled={issues.length < 1 && issueHistory.length < 1}
                title={"Download books issued"}
                className={`mr-4 link `}
                onClick={handleDownload}
              >
                <BiDownload className={"text-primary"} size={20} />
              </Button>
              {/* )} */}
              <IssueStudent
                availableBooks={availableBooks}
                setAvailableBooks={setAvailableBooks}
                student={student}
                allBooks={allBooks}
                stdId={stdId}
                subjects={subjects}
                getIssues={getIssues}
                //This is a function to be called with parameter stdId
                className="float-left"
              />
              <Link
                // onClick={() => getSubjects()}
                className="link"
                data-toggle="modal"
                data-target={`#id${student?.s_id}`}
                title="Issue books to the student"
              >
                {" "}
                Issue{" "}
              </Link>
              {issues.length === 0 && (
                <Link
                  disabled={issues.length > 0}
                  onClick={() => deleteStudent(stdId)}
                  className="link ml-4"
                  title="Delete student"
                >
                  <BiTrash size={20} className="text-danger" />
                </Link>
              )}
            </small>{" "}
            | {student?.k_name}{" "}
            {student?.st_name && <small>{capitalized(student?.st_name)}</small>}{" "}
            | Books {issueCount}
            <br />
          </h6>
          <hr />
        </div>
      </div>
      {loading && (
        <TailSpin
          height="60"
          width="60"
          color="rgb(183, 71, 221)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{
            top: "50%",
            left: "50%",
            position: "absolute",
            transform: "translate(-50%, -50%)",
          }}
          wrapperClass=""
          visible={true}
        />
      )}
      <div>
        {issues?.length > 0 ? (
          <table className="layout">
            <thead>
              <tr>
                <th>Book Name</th>
                <th>Book Reg</th>
                <th>Class</th>
                <th>Issue Date</th>
                <th>Due Date</th>
                <th>Issuer</th>
                <th>
                  <input
                    type="checkbox"
                    checked={selectedIssues.length === issues.length}
                    onChange={(e) =>
                      setSelectedIssues(
                        e.target.checked
                          ? issues.map((issue) => issue.b_id)
                          : []
                      )
                    }
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {issues?.map((issue) => (
                <tr key={issue.b_id}>
                  <td id="borrowed" title="Click to see book details">
                    <Link className="link" to={`/book/bookId/${issue.b_id}`}>
                      {capitalized(issue.b_name)}
                    </Link>
                  </td>
                  <td>{issue.b_reg}</td>
                  <td>{issue.klass_name}</td>
                  <td>{new Date(issue.issue_date).toLocaleString()}</td>
                  <td>{new Date(issue.return_date).toLocaleDateString()}</td>
                  <td>{issue.user}</td>
                  <td>
                    <label>
                      <input
                        type="checkbox"
                        value={issue.b_id}
                        checked={selectedIssues.includes(issue.b_id)}
                        onChange={handleChange}
                      />
                    </label>
                  </td>
                </tr>
              ))}
            </tbody>
            <br />
          </table>
        ) : (
          <h6 className="text-success text-center">
            <i>No Active Records</i>
          </h6>
        )}
        <Button
          className={"float-right text-capitalize"}
          variant="contained"
          onClick={() => {
            returnBooks(selectedIssues);
          }}
          disabled={selectedIssues.length < 1 || returning}
        >
          {returning ? (
            <>
              <TailSpin
                height="20"
                width="20"
                color="rgb(183, 71, 221)"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{
                  top: "50%",
                  left: "50%",
                  position: "absolute",
                  transform: "translate(-50%, -50%)",
                }}
                wrapperClass=""
                visible={true}
              />
              Please Wait..
            </>
          ) : (
            "Return Selected"
          )}
          {/* Return Selected */}
        </Button>
        <br />
        {issueHistory?.length > 0 && (
          <div>
            <hr />
            <h5 className="m-2 text-center text-success">
              Borrowing History
              {/* <Link
                title={"Download Issue History"}
                className={"mr-4 link"}
                onClick={downloadIssueHistory}
              >
                {"  "}
                [ <BiDownload className="text-success link" size={18} /> ]
              </Link> */}
            </h5>
            <table className="layout">
              <thead>
                <tr>
                  <th>Book Name</th>
                  <th>Book Reg</th>
                  <th>Class</th>
                  <th>Issue Date</th>
                  <th>Return Date</th>
                  <th>Issuer</th>
                  <th>Receiver</th>
                </tr>
              </thead>
              <tbody>
                {issueHistory.map((issue) => (
                  <tr key={issue.id}>
                    <td id="borrowed" title="Click to see book details">
                      <Link
                        className="link"
                        to={`/book/bookId/${issue.book_id}`}
                      >
                        {capitalized(issue.book_name)}
                      </Link>
                    </td>
                    <td>{issue.book_reg}</td>
                    <td>{issue.klass}</td>
                    <td>{new Date(issue.issue_date).toLocaleString()}</td>
                    <td>{new Date(issue.return_date).toLocaleString()}</td>
                    <td>{issue.issuer || "admin"}</td>
                    <td>{issue.receiver}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};
export default Borrowed;
