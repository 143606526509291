import React, { useState, useCallback } from "react";
import Axios from "axios";
import { baseURL } from "../../methods/baseUrl";
import useAuth from "../hooks/useAuth";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import useDocumentTitle from "../hooks/useDocumentTitle";

function BookUploads({ setAllBooks }) {
  const { auth } = useAuth();
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState();
  const mySchool = auth?.school_id;

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const getAllBooks = useCallback(async () => {
    // alert("Getting all students from Students.js");

    /**
     * Get all the details of all students in a school
     * This is to be stored and used everytime an issue is required
     */
    try {
      await Axios.get(baseURL + "/books/" + mySchool).then((response) => {
        // console.log(response.data);
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setAllBooks(response.data);
          // setAllStudentsCount(response.data.length);
        }
      });
    } catch (error) {
      showErrorMsg(error);
    }
  }, [mySchool, setAllBooks]);

  const bookUpload = async () => {
    const formData = new FormData();

    formData.append("file", selectedFile);
    formData.append("misplaced", false);
    formData.append("school_id", auth?.school_id);
    formData.append("no_of_books", 1);

    await Axios.post(baseURL + "/upload/books", formData).then((response) => {
      getAllBooks();

      if (response.data.msg) {
        // console.log(response.data.msg);
        // showErrorMsg(response.data.msg);
        const errorMessages = response.data.msg.split(",");
        setError(
          errorMessages.map((message) => (
            <h6 key={message}>{message.trim()}</h6>
          ))
        );
      } else {
        showSuccessMsg(response.data.success);
      }
    });
  };

  const handleDownload = () => {
    const url = "upload_books_template.xlsx";
    const fileName = "upload_books_template";
    saveAs(url, fileName + ".xlsx");
  };

  useDocumentTitle("Book Uploads");

  return (
    <div className="m-auto container m-4 ">
      <h5 className="text-center text-success m-4 p-4">IMPORT/UPLOAD BOOKS</h5>
      <div>
        <p>
          Download{" "}
          <Link className="link" onClick={handleDownload}>
            this file
          </Link>{" "}
          , fill it and upload back.
        </p>
        <h6 className="text-danger">DO NOT CHANGE THE COLUMN HEADERS</h6>
      </div>
      <hr />
      <Button variant={"outlined"}>
        <input type="file" onChange={handleFileSelect} />
      </Button>
      <Button
        className="float-right"
        variant="contained"
        onClick={bookUpload}
        disabled={!selectedFile}
      >
        Upload
      </Button>
      <h6>
        <hr />
        {error}
      </h6>
    </div>
  );
}

export default BookUploads;
