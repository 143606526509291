import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { firebaseConfig } from "./firebaseConfig";
firebase.initializeApp(firebaseConfig);

export const firebaseRegisterUser = async (email, password) => {
  try {
    const userCredential = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    const uid = userCredential.user.uid;
    return { status: "success", uid };
  } catch (err) {
    if (err.code === "auth/network-request-failed") {
      return "Poor/No Internet Connection";
    } else if (err.code === "auth/invalid-email") {
      return "Email provided is invalid";
    } else if (err.code === "auth/email-already-in-use") {
      return "Email is already in use";
    } else if (err.code === "auth/weak-password") {
      return "Password is too weak";
    } else {
      return err.message; // Default error message for unhandled codes
    }
  }
};

// export const firebaseEmailExists = async (email) => {
//   try {
//     const emailCheck = await firebase.auth().fetchSignInMethodsForEmail(email);
//     if (emailCheck.length === 0) {
//       console.log("Not Reg", emailCheck);

//       return "Email notyet registered";
//     } else {
//       console.log("Reg");
//       return "Email is registered";
//     }
//   } catch (error) {
//     return error.message;
//   }
// };

export const checkUserAuthState = (auth, authStateChange) => {
  return new Promise((resolve, reject) => {
    const unsubscribe = authStateChange(auth, (user) => {
      if (user) {
        resolve(user);
      } else {
        reject(new Error("User not authenticated"));
      }
      unsubscribe();
    });
  });
};
