import React, { useState, useEffect, useCallback } from "react";
import { showErrorMsg, showSuccessMsg } from "../methods/alertMsgs";
import Axios from "axios";import axios from "axios";
import { Button } from "@mui/material";
import { useNavigate, useParams, Link } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import { baseURL } from "../methods/baseUrl";
import { BiCheckCircle, BiXCircle, BiPencil } from "react-icons/bi";
import swal from "sweetalert";
import { capitalized } from "../methods/eventHandlers";
import { toUpper } from "../methods/eventHandlers";
import IssueBook from "./crud/IssueBook";
import { TailSpin } from "react-loader-spinner";

const BookDetails = ({ setAllBooks }) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [details, setDetails] = useState();
  const book_id = useParams();
  const sch_category = auth?.sch_category;
  const bookId = book_id.id;
  const mySchool = auth?.school_id;
  const [categories, setCategories] = useState([]);
  const [editBookData, setEditBookData] = useState(null); //Holds thes tudents edit data
  const [loading, setLoading] = useState(true);
  const [returning, setReturning] = useState(false);
  const [borrowers, setBorrowers] = useState([]);
  const [history, setHistory] = useState([]);
  const [teacherHistory, setTeacherHistory] = useState([]);
  const editMyBook = (book) => {
    setEditBookData(book);
  };

  const handleMisplaced = () => {
    setEditBookData((editBookData) => ({
      ...editBookData,
      misplaced: !editBookData.misplaced,
    }));
  };

  const returnStudentBook = async (details) => {
    /**
     * If the book under view is borrowed by
     * a student then this function is called
     */
    try {
      setReturning(true);
      const receiver = auth.username;
      const return_date = new Date();
      const issue_date = details.issue_date;
      const due_date = details.return_date;
      const klass = details.book_klass;
      const book_name = details.book_name;
      const book_reg = details.reg_no;
      const issuer = details.issuer;
      const student_id = details.s_id;

      const body = {
        issuer,
        return_date,
        issue_date,
        due_date,
        receiver,
        klass,
        book_name,
        book_reg,
        student_id,
        bookId,
      };
      await Axios.post(baseURL + `/return/student/${bookId}`, body)
        .then((response) => {
          if (response.data.success) {
            showSuccessMsg(response.data.success);
            getBook();
            // getBorrowers();
          } else {
            showErrorMsg(response.data.msg);
          }
        })
        .catch((error) => {
          showErrorMsg(error);
        });
    } catch (err) {
      showErrorMsg(err.message);
    } finally {
      setReturning(false);
    }
  };

  const returnTeacherBook = async (details) => {
    /**
     * If the book under view is
     * borrowed by a teacher then this function is called
     */
    try {
      setReturning(true);
      const receiver = auth.username;
      const return_date = new Date();
      const issue_date = details.issue_date;
      const klass = details.book_klass;
      const book_name = details.book_name;
      const book_reg = details.reg_no;
      const issuer = details.issuer;
      const id = details.t_id;
      // const issue = details.issue_id;

      const body = {
        issuer,
        return_date,
        issue_date,
        receiver,
        klass,
        book_name,
        book_reg,
        id,
        bookId,
      };
      await Axios.post(baseURL + `/return/teacher/${bookId}`, body)
        .then((data) => {
          if (data.data.success) {
            showSuccessMsg(data.data.success);
            getBook();
          } else {
            showErrorMsg(data.data.msg);
          }
        })
        .catch((error) => {
          showErrorMsg(error);
        });
    } catch (error) {
      showErrorMsg(error);
    } finally {
      setReturning(false);
    }
  };

  const editBook = async (id) => {
    try {
      const book_name = editBookData.book_name;
      const reg_no = editBookData.reg_no;
      const author = editBookData.author;
      const book_price = editBookData.book_price;
      const book_detail = editBookData.book_detail;
      const misplaced = editBookData.misplaced;
      const edition = editBookData.edition;
      const klass_id = editBookData.klass_id;
      const subject_id = editBookData.subject_id;
      const category_id = editBookData.category_id;

      const body = {
        book_name,
        reg_no,
        author,
        book_price,
        book_detail,
        misplaced,
        edition,
        klass_id,
        subject_id,
        category_id,
      };

      await Axios.put(baseURL + `/update/book/` + id, body).then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          showSuccessMsg(response.data.success);
          getBook();
          getAllBooks();
          // getStudentsPerKlass(editBookData.klass_id, school);
          setEditBookData(null);
        }
      });
    } catch (error) {
      showErrorMsg(error);
      // Handle the error
    }
  };

  const [subjects, setSubjects] = useState([]);
  const [klasses, setKlasses] = useState([]);

  // Get book categories
  const getCategories = async () => {
    /**
     * Category is either govt or sch
     * Called when adding books to the library
     */
    try {
      await Axios.get(baseURL + "/categories").then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setCategories(response.data);
        }
      });
    } catch (error) {
      showErrorMsg(error);
    }
  };

  // Get book borrowing history
  const getHistory = useCallback(async () => {
    try {
      await Axios.get(baseURL + `/book/history/${bookId}`).then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setHistory(response.data);
        }
      });
    } catch (err) {
      showErrorMsg(err.message);
    }
  }, [bookId]);

  // Get teacher book borrowing history
  const getTeacherHistory = useCallback(async () => {
    try {
      await Axios.get(baseURL + `/book/teacher/history/${bookId}`).then(
        (response) => {
          if (response.data.msg) {
            showErrorMsg(response.data.msg);
          } else {
            setTeacherHistory(response.data);
          }
        }
      );
    } catch (err) {
      showErrorMsg(err.message);
    }
  }, [bookId]);

  // Get Borrowers
  const getBorrowers = useCallback(async () => {
    try {
      await Axios.get(baseURL + `/issues/borrowers/${bookId}`).then(
        (response) => {
          console.log(response);
          if (response.data.msg) {
            showErrorMsg(response.data.msg);
          } else {
            setBorrowers(response.data);
          }
        }
      );
    } catch (err) {
      showErrorMsg(err.message);
    }
  }, [bookId]);

  const getAllBooks = useCallback(async () => {
    /**
     * Generate all the books in a school, borrowed and not borrowed
     * Here, it is called to help regenerate the count of books upon refresh
     */
    await Axios.get(baseURL + `/books/${mySchool}`).then((response) => {
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setAllBooks(response.data);
        // setAllBooksCount(response.data.length);
        // setLoading(false);
      }
    });
  }, [mySchool, setAllBooks]);

  const getSubjects = useCallback(async () => {
    /**
     * Generate all the subjects in a particular category of school
     */
    await Axios.get(baseURL + `/subjects/${sch_category}`).then((response) => {
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setSubjects(response.data);
      }
    });
  }, [sch_category]);

  const deleteBook = (id) => {
    swal({
      title: "Press Ok to delete",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (confirmDelete) => {
      if (confirmDelete) {
        try {
          /**
           * Delete the book with the selected ID
           */
          await Axios.delete(baseURL + `/delete/book/${id}`).then(
            (response) => {
              if (response.data.msg) {
                showErrorMsg(response.data.msg);
              } else {
                showSuccessMsg(response.data.success);
                getAllBooks();
                navigate("/books");
              }
            }
          );
        } catch (err) {
          showErrorMsg(err.message);
        }
      }
    });
  };

  const getBook = useCallback(async () => {
    /**
     * Gets the details of the book on page load
     */
    try {
      await axios.get(baseURL + `/book/details/${bookId}`)
        .then((response) => {console.log(response.data);
          if (response.data.msg) {
            showErrorMsg(response.data.msg);
          } else {
            setDetails(response.data);
            getBorrowers();
            getHistory();
            getTeacherHistory();
          }
        })
        .catch((error) => {
          showErrorMsg(error);
        });
    } catch (error) {
      showErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  }, [bookId, getBorrowers, getHistory, getTeacherHistory]);

  // Get klasses
  const getKlasses = useCallback(async () => {
    try {
      await Axios.get(baseURL + `/klasses/${sch_category}`).then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setKlasses(response.data);
        }
      });
    } catch (error) {
      showErrorMsg(error);
    }
  }, [sch_category]);

  useEffect(() => {
    getSubjects();
    getKlasses();
    getCategories();
  }, [getKlasses, getSubjects]);

  useEffect(() => {
    document.title = details?.reg_no;
    getBook();
  }, [details?.reg_no, getBook]);

  // TheJSX
  return (
    <div className="container">
      <>
        {!loading ? (
          <h6 className="text-center text-success mt-4 mb-2">
            {toUpper(details.book_name)} - {toUpper(details.reg_no)}
          </h6>
        ) : (
          <h3 className=" mt-4 mb-2 text-center">Loading...</h3>
        )}
        {!editBookData && (
          <h6 className="text-center">
            Status:{" "}
            {details?.no_of_books === 15 ? (
              <strong className="text-success">Available</strong>
            ) : (
              <strong className="text-danger">Borrowed</strong>
            )}
          </h6>
        )}
        <h6>
          <Button
            variant="text"
            className="float-left"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>

          {!editBookData ? (
            <>
              {details?.no_of_books === 15 && (
                <Button
                  className=" float-right text-capitalize"
                  onClick={() => {
                    deleteBook(details.id);
                  }}
                >
                  Delete
                </Button>
              )}

              <Button
                className=" float-right text-capitalize"
                title="Edit Book Details"
                variant="text"
                onClick={() => {
                  editMyBook(details);
                }}
              >
                <BiPencil /> Edit
              </Button>
              {details?.no_of_books > 0 && details?.s_id && (
                <>
                  <Button
                    className=" link float-right text-capitalize"
                    data-toggle="modal"
                    data-target={`#id${details.id}`}
                    title="Share book"
                    variant="text"
                  >
                    Share
                  </Button>{" "}
                  <IssueBook book={details} getBook={getBook} />
                </>
              )}
              {details?.no_of_books === 15 && (
                <>
                  <Button
                    className=" link float-right text-capitalize"
                    data-toggle="modal"
                    data-target={`#id${details.id}`}
                    title="Issue out the book"
                    variant="text"
                  >
                    Issue
                  </Button>{" "}
                  <IssueBook book={details} getBook={getBook} />
                </>
              )}
            </>
          ) : (
            <small>
              <Button
                variant="text"
                className="text-danger link btn btn-primry btn-sm float-right text-capitalize"
                onClick={() => setEditBookData(null)}
              >
                <BiXCircle /> Cancel
              </Button>

              <Button
                variant="text"
                className="link btn btn-primry btn-sm float-right text-capitalize"
                onClick={() => {
                  editBook(editBookData.id);
                  // setClickedClass(editBookData.klass_id);
                }}
              >
                <BiCheckCircle /> Save
              </Button>
            </small>
          )}
        </h6>
      </>

      <div className="text-center "></div>
      <hr className="mb-4 mt-1" />
      <br />
      <table className="layout mt-3">
        <thead>
          <tr>
            <th>
              <strong>FIELD</strong>
            </th>
            <th>
              <strong>DETAILS</strong>
            </th>
          </tr>
        </thead>
        {loading ? (
          <TailSpin
            height="60"
            width="60"
            color="rgb(183, 71, 221)" //"#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{
              top: "50%",
              left: "50%",
              position: "absolute",
              transform: "translate(-50%, -50%)",
            }}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <tbody>
            {editBookData && editBookData.id === details.id && (
              <tr>
                <td>Name</td>

                <td>
                  <input
                    value={editBookData?.book_name || ""}
                    className="form-control"
                    onChange={(e) =>
                      setEditBookData({
                        ...editBookData,
                        book_name: e.target.value,
                      })
                    }
                    type="text"
                  />
                </td>
              </tr>
            )}
            {editBookData && editBookData.id === details.id && (
              <tr>
                <td>Class</td>

                <td>
                  <select
                    className="form-control"
                    value={editBookData?.klass_id || ""}
                    onChange={(e) => {
                      setEditBookData({
                        ...editBookData,
                        klass_id: parseInt(e.target.value),
                      });
                    }}
                  >
                    <option value={""} hidden>
                      ---
                    </option>

                    {klasses.map((klass) => (
                      <option
                        selected={klass.name === editBookData.book_klass}
                        value={klass.id}
                        key={klass.id}
                      >
                        {klass.name}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            )}
            {editBookData && editBookData.id === details.id && (
              <tr>
                <td>Reg No.</td>

                <td>
                  <input
                    className="form-control"
                    value={editBookData?.reg_no || ""}
                    onChange={(e) =>
                      setEditBookData({
                        ...editBookData,
                        reg_no: e.target.value,
                      })
                    }
                    type="text"
                  />
                </td>
              </tr>
            )}
            <tr>
              <td>Subject</td>
              {editBookData && editBookData.id === details.id ? (
                <td>
                  <select
                    className="form-control"
                    value={editBookData?.subject_id || ""}
                    onChange={(e) =>
                      setEditBookData({
                        ...editBookData,
                        subject_id: parseInt(e.target.value),
                      })
                    }
                  >
                    <option value={""} hidden>
                      ---
                    </option>
                    {subjects.map((subject) => (
                      <option
                        selected={subject.name === editBookData.subject}
                        value={subject.id}
                        key={subject.id}
                      >
                        {subject.name}
                      </option>
                    ))}
                  </select>
                </td>
              ) : (
                <td>
                  {capitalized(details.subject)} {" - "}
                  {details.book_klass}
                </td>
              )}
            </tr>

            <tr>
              <td>Category</td>
              {editBookData && editBookData.id === details.id ? (
                <td>
                  <select
                    className="form-control"
                    value={editBookData?.category_id || ""}
                    onChange={(e) =>
                      setEditBookData({
                        ...editBookData,
                        category_id: parseInt(e.target.value),
                      })
                    }
                  >
                    <option hidden>---</option>
                    {categories.map((cat) => (
                      <option
                        selected={cat.name === editBookData.category}
                        key={cat.id}
                        value={cat.id}
                      >
                        {cat.name}
                      </option>
                    ))}
                  </select>
                </td>
              ) : (
                <td>{details.category}</td>
              )}
            </tr>
            <tr>
              <td>Condition</td>
              {editBookData && editBookData.id === details.id ? (
                <td>
                  <input
                    onChange={(e) =>
                      setEditBookData({
                        ...editBookData,
                        book_detail: e.target.value,
                      })
                    }
                    value={editBookData?.book_detail || ""}
                    className="form-control"
                    type="text"
                  />
                </td>
              ) : (
                <td>{details.book_detail}</td>
              )}
            </tr>
            <tr>
              <td>Price</td>
              {editBookData && editBookData.id === details.id ? (
                <td>
                  <input
                    className="form-control"
                    type="number"
                    value={editBookData?.book_price || ""}
                    onChange={(e) =>
                      setEditBookData({
                        ...editBookData,
                        book_price: e.target.value,
                      })
                    }
                  />
                </td>
              ) : (
                <td>{details.book_price}</td>
              )}
            </tr>
            <tr>
              {editBookData && editBookData.id === details.id ? (
                <>
                  <td>Misplaced</td>

                  <td>
                    <input
                      type="checkbox"
                      id="myCheckbox"
                      // className="form-check-input"
                      checked={editBookData?.misplaced}
                      onChange={handleMisplaced}
                      style={{ transform: "scale(1.3)" }}
                    />
                  </td>
                </>
              ) : (
                <>
                  <td>Misplaced</td>

                  <td>
                    <input
                      type="checkbox"
                      disabled
                      id="myCheckbox"
                      checked={details?.misplaced}
                      style={{ transform: "scale(1.3)" }}
                    />
                  </td>
                </>
              )}
            </tr>

            <tr>
              {editBookData && editBookData.id === details.id && (
                <>
                  <td>Edition</td>

                  <td>
                    <input
                      onChange={(e) =>
                        setEditBookData({
                          ...editBookData,
                          edition: e.target.value,
                        })
                      }
                      className="form-control"
                      value={editBookData?.edition || ""}
                      type="text"
                    />
                  </td>
                </>
              )}
            </tr>
            <tr>
              {editBookData && editBookData.id === details.id && (
                <>
                  <td>Author</td>

                  <td>
                    <input
                      onChange={(e) =>
                        setEditBookData({
                          ...editBookData,
                          author: e.target.value,
                        })
                      }
                      className="form-control"
                      value={editBookData?.author || ""}
                      type="text"
                    />
                  </td>
                </>
              )}
            </tr>
          </tbody>
        )}
      </table>
      <br />
      {details?.no_of_books < 15 && (
        <>
          <h6 className="text-center text-success">
            BORROWERS [{borrowers.length}]
          </h6>

          <table className="layout">
            <thead>
              <tr>
                <th>ID/Code</th>
                <th>Name</th>
                <th>Class</th>
                <th>Date</th>
                <th>Issuer</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {borrowers?.map((borrower) => (
                <tr key={borrower.id}>
                  <td>{borrower.student_id || borrower.t_code}</td>
                  <td>{borrower.name}</td>{" "}
                  <td>
                    {borrower.k_name} {borrower.st_name || "Teacher"}
                  </td>
                  <td>{new Date(borrower.issue_date).toLocaleDateString()}</td>
                  <td>{borrower.issuer}</td>
                  {borrower?.t_code && (
                    <td>
                      <Link
                        onClick={() => returnTeacherBook(borrower)}
                        disabled={returning || loading}
                      >
                        Return
                      </Link>
                    </td>
                  )}
                  {borrower?.student_id && (
                    <td>
                      <Link
                        onClick={() => returnStudentBook(borrower)}
                        disabled={returning || loading}
                      >
                        Return
                      </Link>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      <br />
      {(history.length > 0 || teacherHistory.length > 0) && (
        <>
          <h6 className="text-center text-success">BORROWING HISTORY</h6>
          <table className="layout">
            <thead>
              <tr>
                <th>ID/Code</th>
                <th>Name</th>
                <th>Class</th>
                <th>Issuer</th>
                <th>IssueDate</th>
                <th>ReturnDate</th>
                <th>Receiver</th>
              </tr>
            </thead>
            <tbody>
              {history?.map((borrower) => (
                <tr key={borrower.id}>
                  <td>{borrower.student_id || borrower.t_code}</td>
                  <td>{borrower.name}</td>{" "}
                  <td>
                    {borrower.k_name} {borrower.st_name || "Teacher"}
                  </td>
                  <td>{new Date(borrower.issue_date).toLocaleDateString()}</td>
                  <td>{borrower.issuer}</td>
                  <td>
                    {new Date(borrower.return_date).toLocaleDateString()}
                  </td>{" "}
                  <td>{borrower.receiver}</td>
                </tr>
              ))}
              {teacherHistory?.map((teacher) => (
                <tr key={teacher.id}>
                  <td>{teacher.t_code}</td>
                  <td>{teacher.name}</td> <td>Teacher</td>
                  <td>{new Date(teacher.issue_date).toLocaleDateString()}</td>
                  <td>{teacher.issuer}</td>
                  <td>
                    {new Date(teacher.return_date).toLocaleDateString()}
                  </td>{" "}
                  <td>{teacher.receiver}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      <hr />
    </div>
  );
};
export default BookDetails;
