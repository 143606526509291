import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { TextField, Button, Box, Typography } from "@mui/material";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showMsg, setShowMsg] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChangePassword = async () => {
    setLoading(true);

    try {
      if (newPassword !== confirmPassword) {
        setNewPassword("");
        setConfirmPassword("");
        setShowMsg("Passwords do not match");
        return;
        // throw new Error("Passwords do not match");
      }

      if (newPassword.length < 6) {
        setShowMsg("Password must be 6 or more characters.");
        return;
        // throw new Error("Password must be at least 6 characters long");
      }

      const user = firebase.auth().currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        oldPassword
      );

      await user.reauthenticateWithCredential(credential);
      await user.updatePassword(newPassword);

      setNewPassword("");
      setOldPassword("");
      setConfirmPassword("");
      setShowMsg("Success. Password changed.");
    } catch (error) {
      console.log(error.code);
      if (error.code === "auth/invalid-login-credentials") {
        setOldPassword("");
        setShowMsg("Old Password Incorrect");
      } else if (error.code === "auth/network-request-failed") {
        setShowMsg("Poor/No Internet Connecton");
      } else if (error.code === "auth/too-many-requests") {
        setShowMsg("Please Wait before Retrying");
      } else {
        setShowMsg("An error is encountered");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "LLR || Change Password";

    if (showMsg) {
      const timeout = setTimeout(() => {
        setShowMsg(null);
      }, 10000);

      return () => clearTimeout(timeout);
    }
  }, [showMsg]);

  return (
    <div
      style={{
        fontFamily: "Chakra",
        background: "#fff",
        padding: "5px",
        borderRadius: "10px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        width: "100%", // Take up full width by default
        maxWidth: "400px", // But limit maximum width to 400px
        margin: "0 auto", // Center the element
        height: "auto", // Set the height to adjust based on content
      }}
      className="container mt-4 align-items-center justify-content-center"
    >
      <Box p={4} height="auto">
        <Typography className="text-center text-success" variant="h5">
          CHANGE PASSWORD
        </Typography>

        <br />

        {showMsg && (
          <div
            className={`alert ${
              showMsg.includes("Success") ? "alert-success" : "alert-danger"
            } mt-3 text-center`}
            role="alert"
          >
            {showMsg}
          </div>
        )}

        <TextField
          fullWidth
          type="password"
          label="Old Password"
          placeholder="Enter Current Password"
          variant="standard"
          margin="normal"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          required
          InputLabelProps={{ style: { fontWeight: "bold" }, shrink: true }}
        />
        <TextField
          fullWidth
          type="password"
          label="New Password"
          variant="standard"
          margin="normal"
          placeholder="Enter New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
          InputLabelProps={{ style: { fontWeight: "bold" }, shrink: true }}
        />
        <TextField
          fullWidth
          type="password"
          label="Confirm Password"
          variant="standard"
          margin="normal"
          placeholder="Re-enter New Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
          InputLabelProps={{ style: { fontWeight: "bold" }, shrink: true }}
        />
        <Button
          id={"submit"}
          // id={`${loading && "submit"}`}
          disabled={!newPassword || !oldPassword || !confirmPassword || loading}
          variant="contained"
          onClick={handleChangePassword}
          className={`btn btn-primary form-control mt-4 mb-4 text-white`}
        >
          {loading ? "Please Wait..." : "Change Password"}
        </Button>
        <hr />
        <Button
          className="mt-3 float-left"
          variant="text"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </Box>
    </div>
  );
};

export default ChangePassword;
