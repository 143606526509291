// src/Metadata.js
import React from "react";

const Metadata = ({ title, description }) => (
  <head>
    <meta charSet="utf-8" />
    <link rel="icon" href="%PUBLIC_URL%/intro-logo.gif" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <meta name="description" content={description} />
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css"
      integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
      crossOrigin="anonymous"
    />
    <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
    <title>{title}</title>
  </head>
);

export default Metadata;
