import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AddStream from "../crud/AddStream";
import EditSchool from "../crud/EditSchool";
import useAuth from "../hooks/useAuth";
import { baseURL } from "../../methods/baseUrl";
import Axios from "axios";
import { showErrorMsg, showSuccessMsg } from "../../methods/alertMsgs";
import AddSchool from "../crud/AddSchool";
import { daysToExpiry } from "../../methods/eventHandlers";
import SchoolsDetails from "./SchoolsDetails";

const Schools = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [schools, setSchools] = useState([]);
  const [loadingSchools, setLoadingSchools] = useState(false);

  // Retrieves Schools
  const getSchools = async () => {
    try {
      await Axios.get(baseURL + "/schools").then((response) => {
        if (response.data.msg) {
          showErrorMsg(response.data.msg);
        } else {
          setSchools(response.data);
          setTimeout(() => setLoadingSchools(false), 400);
        }
      });
    } catch (err) {
      showErrorMsg(err.message);
    }
  };

  const goToSchool = async (schoolId) => {
    /**
     * Change the superuser's school upon click
     */
    await Axios.put(baseURL + `/sudo/${schoolId}`).then((response) => {
      if (response.data.msg) {
        showErrorMsg(response.data.msg);
      } else {
        setAuth(response.data.results);
        showSuccessMsg(response.data.success);
        window.location.href = "/admin/users";
        // navigate("/home", { replace: true });
      }
    });
  };

  // Runs when the page loads
  useEffect(() => {
    document.title = "LLR | Schools"
    try {
      getSchools();
    } catch (error) {
      showErrorMsg(error.message);
    }
  }, []);

  // JSX
  return (
    <div className="">
      <hr className="text-success text-center h-100" />
      <div>
        <h4 className="text-success text-center link mt-3 display-flex">
          SCHOOLS {" - "} <>{schools.length}</>
          <div>
            <Link className="link" title={"Add a Class"}>
              <AddSchool />
            </Link>
          </div>
        </h4>

        <div>
          {loadingSchools ? (
            <h5 className="text-center text-success m-4 p-4">Please Wait...</h5>
          ) : (
            <table className="layout">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Invoice Date</th>
                  <th>Exp. Days</th>
                  <th>Installation</th>
                  <th>Subscription</th>
                  <th>S.Issues</th>
                  <th>T.Issues</th>
                  <th>Students</th>
                  <th>Teachers</th>
                  <th>Books</th>
                  <th>Stream</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {schools.map((school, index) => (
                  <tr key={school.id}>
                    <td title={`Change to ${school.name}`}>
                      <Link
                        onClick={() => {
                          setTimeout(() => goToSchool(school.id), 10);
                        }}
                        className="link"
                      >
                        {school.id}
                      </Link>
                    </td>
                    <td title="View Details">
                      <Link
                        className="link"
                        to={`/admin/school/details/${school.id}`}
                      >
                        {school.name}
                      </Link>
                    </td>
                    <td>{new Date(school.invoice_date).toDateString()}</td>

                    {daysToExpiry(
                      new Date(school.invoice_date),
                      new Date().toDateString()
                    ) < 0 ? (
                      <td className="text-danger">Expired</td>
                    ) : (
                      <td>
                        {daysToExpiry(
                          new Date(school.invoice_date),
                          new Date().toDateString()
                        )}
                      </td>
                    )}
                    {school.installed ? (
                      <td className="text-success">True</td>
                    ) : (
                      <td className="text-danger">False</td>
                    )}
                    {school.subscribed ? (
                      <td className="text-success">True</td>
                    ) : (
                      <td>False</td>
                    )}
                    <td>{school.s_issues}</td>
                    <td>{school.t_issues}</td>
                    <td>{school.student_count}</td>
                    <td>{school.teachers_count}</td>
                    <td>{school.book_count}</td>

                    <td className="link" title={"Add a Stream"}>
                      <AddStream school={school} />{" "}
                    </td>
                    <td title={`Edit ${school.name} details`}>
                      <EditSchool school={school} getSchools={getSchools} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <hr className="text-success text-center h-100" />
      </div>
    </div>
  );
};
export default Schools;
